import { useState } from 'react'
import { useAuth } from './../../components/auth/AuthContext'
import { useChats } from './../../components/auth/ChatContext'
import { db } from './../../firebase'

import { useNavigate, useParams } from 'react-router-dom'
import { getAge, getBirthday } from './../../components/utilities'

import { doc, collection, serverTimestamp, addDoc } from 'firebase/firestore'
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore'

import ProfilePic from './../../components/ui/ProfilePic'
import Button from '../../components/ui/Button'
import LoadingSpinner from './../../components/ui/icons/LoadingSpinner'
import RightArrow from './../../components/ui/icons/RightArrow'
import LeftArrow from './../../components/ui/icons/LeftArrow'
import LoadingSpinnerBlue from './../../components/ui/icons/LoadingSpinnerBlue'
import CloseIcon from '../../components/ui/icons/CloseIcon'

const ContactProfile = () => {
  // get current user
  const { user, userData, role, age } = useAuth()

  // use chats
  const { chats } = useChats()

  // use navigate
  const navigate = useNavigate()

  // access contact id
  const params = useParams()

  // loading chat
  const [loadingChat, setLoadingChat] = useState(false)

  // get contact
  const [contact, loading, error] = useDocumentDataOnce(doc(db, 'users', params.id))

  // chat with
  // go to chat with user, or start one
  const chatWith = async () => {
    setLoadingChat(true)
    // this is a single, non-group chat
    // first, check for an existing chat between these two users
    if (chats && chats.length > 0) {
      let existingChat = chats.find(chat => {
        return chat.type === 'single' && chat.members[params.id] && chat.members[user.uid]
      })
      if (existingChat) {
        return navigate(`/chats/${existingChat.id}`)
      }
    }

    // create single chat members object
    let members = {
      [user.uid]: {
        name: `${userData.firstName} ${userData.lastName}`,
        profilePic: userData?.profilePic || '',
        colour: userData.colour,
        memberSince: serverTimestamp(),
      },
      [params.id]: {
        name: `${contact.firstName} ${contact.lastName}`,
        profilePic: contact?.profilePic || '',
        colour: contact.colour,
        memberSince: serverTimestamp(),
      },
    }
    if (contact.role === 'admin' || contact.role === 'mentor') {
      members[params.id].role = contact.role
    }
    if (role === 'admin' || role === 'mentor') {
      members[user.uid].role = role
    }
    const chatDocRef = await addDoc(collection(db, 'chats'), {
      type: 'single',
      members: members,
      lastMessage: {
        message: `New chat started by ${userData.firstName} ${userData.lastName}`,
        timestamp: serverTimestamp(),
        readBy: [user.uid],
      },
      createdOn: serverTimestamp(),
    })
    await addDoc(collection(db, 'chats', chatDocRef.id, 'messages'), {
      type: 'system',
      from: '',
      sentOn: serverTimestamp(),
      content: `New chat started by ${userData.firstName} ${userData.lastName}`,
    })
    setLoadingChat(false)
    return navigate(`/chats/${chatDocRef.id}`)
  }

  return (
    <>
      <div className='lhm-body'>
        {loading ? (
          <div className='loading-spinner-blue-container'>
            <LoadingSpinnerBlue />
          </div>
        ) : error || (!loading && !contact) ? (
          <div className='loading-spinner-blue-container'>
            <CloseIcon />
          </div>
        ) : (
          <>
            <div className='profile-top'>
              <div className={`profile-background ${contact.colour}`}></div>
              <div className='jigsaw-overlay'></div>
              <div className='profile-content'>
                <button className='svg-button not-me' onClick={() => navigate(-1)}>
                  <LeftArrow colour={'white'} />
                </button>
                <h1>
                  {contact.firstName} {contact.lastName}
                </h1>
                {contact.role === 'youth' && <h2>{`${getAge(contact.DOB.seconds * 1000)} years old`}</h2>}
                {contact?.pronouns && <p>{contact.pronouns}</p>}
                <p>
                  <strong>Birthday: </strong>
                  {getBirthday(contact.DOB.seconds * 1000)}
                </p>
                <ProfilePic firstName={contact.firstName} image={contact?.profilePic} colour={contact.colour} size={160} />
              </div>
            </div>
            <div className='container-20px profile-bottom'>
              {contact?.bio ? <p>{contact.bio}</p> : <p>{`${contact.firstName}`} hasn't written a bio yet, have you written yours? ✍ 🙌</p>}
              {((getAge(age) > 11 && getAge(contact.DOB.seconds * 1000) > 11) || role === 'admin') && (
                <Button
                  action={() => chatWith()}
                  colour={loadingChat ? 'loading blue' : 'blue'}
                  text={`Chat with ${contact.firstName}`}
                  fixed={true}
                  icon={loadingChat ? <LoadingSpinner /> : <RightArrow colour={'#fff'} />}
                />
              )}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default ContactProfile
