import { Link, useLocation } from 'react-router-dom'
import HomeIcon from './icons/HomeIcon'
import ProfileIcon from './icons/ProfileIcon'
import SupportIcon from './icons/SupportIcon'
import ChatIcon from './icons/ChatIcon'
import AdminIcon from './icons/AdminIcon'

const NavigationLink = ({ to, iconType, text }) => {
  // work out current page
  let location = useLocation()
  let active = false
  if (location.pathname === `${to}` || location.pathname.startsWith(`${to}`)) {
    active = true
  }
  if (location.pathname !== '/' && to === '/') {
    active = false
  }

  const components = {
    home: HomeIcon,
    profile: ProfileIcon,
    support: SupportIcon,
    chat: ChatIcon,
    admin: AdminIcon,
  }
  const Icon = components[iconType]

  return (
    <Link to={to} className={active ? 'current-link' : undefined}>
      {iconType && <Icon active={active} />}
      {text}
    </Link>
  )
}

export default NavigationLink
