import { useState, useEffect } from 'react'
import { useAuth } from './../../components/auth/AuthContext'
import { useNavigate } from 'react-router-dom'
import { handleNotifications } from './../../components/auth/handleNotifications'
import { FirebaseMessaging } from '@capacitor-firebase/messaging'

import HeadingBar from './../../components/ui/HeadingBar'
import ToggleSwitch from '../../components/ui/ToggleSwitch'

import LeftArrow from './../../components/ui/icons/LeftArrow'

const Notifications = () => {
  // auth context
  const { user, userData } = useAuth()
  // toggle state
  const [toggleState, setToggleState] = useState(false)
  // enable user input
  const [ready, setReady] = useState(false)
  // setup nav
  const navigate = useNavigate()

  // on change event for toggle
  const toggleNotifications = async () => {
    if (ready) {
      setToggleState(!toggleState)
    }
  }

  useEffect(() => {
    const handleUserToggle = async () => {
      if (ready && user) {
        if (toggleState) {
          await handleNotifications(user, 'forceEnable')
        } else {
          await handleNotifications(user, 'forceDisable')
        }
      }
    }
    handleUserToggle()
  }, [toggleState, ready, user])

  useEffect(() => {
    // check initial state of notifications
    const checkNotificationStatus = async () => {
      // check current permission status
      const checkPermissions = await FirebaseMessaging.checkPermissions()
      const permissionResult = checkPermissions.receive // 'prompt' | 'prompt-with-rationale' | 'granted' | 'denied'
      setToggleState(permissionResult === 'granted' ? true : false)
      setReady(true)
    }
    checkNotificationStatus()
  }, [])

  return (
    <>
      <div className='lhm-body change-password'>
        <HeadingBar heading={`Notifications`} iconLeft={<LeftArrow />} iconLeftClick={() => navigate(`/profile/settings`)} colour={userData.colour} />
        <div className='container-20px'>
          <p className='start-p'>This app sends notifications to your device about new messages and upcoming events.</p>
          <ToggleSwitch toggleState={toggleState} onChange={toggleNotifications} text='Allow Notifications' />
        </div>
      </div>
    </>
  )
}

export default Notifications
