const LoadingSpinnerBlue = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    style={{
      shapeRendering: 'auto',
    }}
    width={28}
    height={28}
    viewBox='0 0 100 100'
    preserveAspectRatio='xMidYMid'
  >
    <circle cx={50} cy={50} fill='none' stroke='#007faf' strokeWidth={14} r={38} strokeDasharray='179.0707812546182 61.690260418206066'>
      <animateTransform
        attributeName='transform'
        type='rotate'
        repeatCount='indefinite'
        dur='1s'
        values='0 50 50;360 50 50'
        keyTimes='0;1'
      />
    </circle>
  </svg>
)

export default LoadingSpinnerBlue
