import { useState, useEffect, useRef } from 'react'
import { useNavigate, Outlet, useOutletContext } from 'react-router-dom'
import { useAuth } from './../../components/auth/AuthContext'
import { getAge } from './../../components/utilities'

import { db } from './../../firebase'
import { doc, updateDoc, addDoc, collection, serverTimestamp, arrayUnion } from 'firebase/firestore'

import HeadingBar from './../../components/ui/HeadingBar'
import MenuItem from './../../components/ui/MenuItem'
import ConfirmationPopup from './../../components/ui/ConfirmationPopup'

import LeftArrow from './../../components/ui/icons/LeftArrow'
import BlockIcon from './../../components/ui/icons/BlockIcon'
import CloseIcon from './../../components/ui/icons/CloseIcon'
import SupportIcon from './../../components/ui/icons/SupportIcon'
import UserListItem from './../../components/ui/UserListItem'
import EditIcon from './../../components/ui/icons/EditIcon'
import PauseNotifications from './PauseNotifications'

const ChatInfo = () => {
  // get user
  const { user, age, refreshUserData } = useAuth()
  // get chatData from parent
  const { currentChat } = useOutletContext()
  // navigate
  const navigate = useNavigate()
  // naming convention
  const [namingConvention, setNamingConvention] = useState()
  const [members, setMembers] = useState({})
  const [me, setMe] = useState({})
  useEffect(() => {
    let unsub = false
    if (currentChat && !unsub) {
      let members = []
      let me = {}
      for (const memberId in currentChat.members) {
        if (memberId !== user.uid) {
          members.push({
            id: memberId,
            name: currentChat.members[memberId].name,
            colour: currentChat.members[memberId].colour,
            profilePic: currentChat.members[memberId]?.profilePic,
          })
        }
        if (memberId === user.uid) {
          me = {
            id: user.uid,
            name: currentChat.members[memberId].name,
            colour: currentChat.members[memberId].colour,
            profilePic: currentChat.members[memberId]?.profilePic,
          }
        }
      }
      setMe(me)
      setMembers(members)
      setNamingConvention(`${currentChat.type === 'community' ? 'Community' : currentChat.type === 'group' ? 'Group' : 'Chat'}`)
    }
    return () => {
      unsub = true
    }
  }, [currentChat])

  // state for confirmation popup
  const [confirm, setConfirm] = useState(false)

  // close confirmation popup
  const closeAction = () => {
    setConfirm(false)
  }

  // function to block contact
  const blockContact = async () => {
    // show loading in notification box
    setConfirm(current => {
      return { ...current, loading: true }
    })
    // create a leave chat message
    let departureMessage = `${currentChat.members[user.uid].name} has left the chat`
    // create new members object with other user marked as blocked, this is added to 'pastMembers'
    let allMembers = { ...currentChat.members }
    allMembers[user.uid].blockedOtherUser = true
    // send depature message
    await addDoc(collection(db, 'chats', currentChat.id, 'messages'), {
      type: 'system',
      from: '',
      sentOn: serverTimestamp(),
      content: departureMessage,
    })
    // add to current user's block list
    let otherMember = members[0].id
    await updateDoc(doc(db, 'users', user.uid), {
      hasBlocked: arrayUnion(otherMember),
    })
    // send report
    let newReport = {
      byUser: user.uid,
      byUserName: `${me.name}`,
      byUserAge: getAge(age),
      aboutChat: currentChat.id,
      chatName: currentChat.name,
      chatType: currentChat.type,
      message: `${me.name} has blocked ${members[0].name}`,
      resolved: false,
      timestamp: serverTimestamp(),
    }
    await addDoc(collection(db, 'adminReports'), newReport)
    // refresh user data
    refreshUserData()
    // remove from chat
    await updateDoc(doc(db, 'chats', currentChat.id), {
      members: {},
      pastMembers: allMembers,
      lastMessage: {
        message: departureMessage,
        timestamp: serverTimestamp(),
        readBy: [user.uid],
      },
    })
    return
  }

  // function to leave a chat
  const leaveChat = async () => {
    // show loading in notification box
    setConfirm(current => {
      return { ...current, loading: true }
    })
    // create a message announcing departure from group
    let departureMessage = `${currentChat.members[user.uid].name} has left the chat`
    // create new members object with current user removed
    let allMembers = { ...currentChat.members }
    delete allMembers[user.uid]
    // send depature message
    await addDoc(collection(db, 'chats', currentChat.id, 'messages'), {
      type: 'system',
      from: '',
      sentOn: serverTimestamp(),
      content: departureMessage,
    })
    // remove from chat
    await updateDoc(doc(db, 'chats', currentChat.id), {
      members: allMembers,
      [`pastMembers.${user.uid}`]: me,
      lastMessage: {
        message: departureMessage,
        timestamp: serverTimestamp(),
        readBy: [user.uid],
      },
    })
    setConfirm(false)
    navigate('/chats')
  }

  return (
    <>
      <div className='lhm-body single-chat-container'>
        <HeadingBar heading={`${namingConvention} info`} iconLeft={<LeftArrow />} iconLeftClick={() => navigate(`/chats/${currentChat.id}`)} colour={currentChat && currentChat.colour} />
        {currentChat && currentChat.subtitle === '(Mentor)' && (
          <div className='container-20px' style={{ marginTop: '30px' }}>
            <p className='start-p'>
              This chat is with a Mentor.<br></br>
              <br></br>
              Mentors have been selected especially to help with any challenges you might face.
            </p>
          </div>
        )}
        {currentChat && currentChat.subtitle === '(Youth Leader)' && (
          <div className='container-20px' style={{ marginTop: '30px' }}>
            <p className='start-p'>
              This chat is with a Youth Leader.<br></br>
              <br></br>
              You can speak to them about any problems you might be having in the the community, or if you need some support.
            </p>
          </div>
        )}
        {currentChat && currentChat.type === 'community' ? (
          <div className='container-20px' style={{ marginTop: '30px' }}>
            <p className='start-p'>This is a community chat for all members of Little Hearts Matter {currentChat.subtitle}.</p>
            <div className='menu-container' style={{ marginTop: '30px' }}>
              <MenuItem action={() => navigate(`/chats/${currentChat.id}/report`)} icon={<SupportIcon active={true} />} name={`Report a problem}`} />
            </div>
          </div>
        ) : (
          <>
            <div className='container-20px'>
              <div className={`box-component ${currentChat.colour}`}>
                <h3>Members:</h3>
                {members.length > 0 &&
                  members.map(member => (
                    <UserListItem
                      key={member.id}
                      noPadding={true}
                      size={40}
                      online={false}
                      profilePic={member.profilePic}
                      firstName={member.name}
                      colour={member.colour}
                      clickHandler={() => navigate(`/profile/${member.id}`)}
                    />
                  ))}
                {me && (
                  <UserListItem
                    key={me.id}
                    noPadding={true}
                    size={40}
                    me={true}
                    online={false}
                    profilePic={me.profilePic}
                    firstName={me.name}
                    colour={me.colour}
                    clickHandler={() => navigate('/profile')}
                  />
                )}
              </div>
              <div className='menu-container'>
                {currentChat && currentChat.type === 'group' && (
                  <>
                    <MenuItem action={() => navigate(`/chats/${currentChat.id}/edit-group`)} icon={<EditIcon />} name={`Edit ${namingConvention && namingConvention.toLowerCase()}`} />
                    <MenuItem
                      action={() =>
                        setConfirm({
                          heading: `Leave ${namingConvention && namingConvention.toLowerCase()}?`,
                          message: `Are you sure you want to leave this ${namingConvention && namingConvention.toLowerCase()}?`,
                          actionText: 'Leave',
                          action: leaveChat,
                          loading: false,
                        })
                      }
                      icon={<BlockIcon />}
                      name={`Leave this ${namingConvention && namingConvention.toLowerCase()}`}
                    />
                  </>
                )}
                {((currentChat && currentChat.type === 'group') || currentChat.type === 'single') && currentChat.subtitle !== '(Youth Leader)' && (
                  <MenuItem action={() => navigate(`/chats/${currentChat.id}/report`)} icon={<SupportIcon active={true} />} name={`Report ${namingConvention && namingConvention.toLowerCase()}`} />
                )}
                {currentChat && currentChat.type === 'single' && !currentChat.subtitle && (
                  <MenuItem
                    action={() =>
                      setConfirm({
                        heading: `Block ${currentChat.name}?`,
                        message: `This will end the current chat. You will no longer see each other in your contact list. You can unblock ${currentChat.name} in settings later.`,
                        actionText: 'Block',
                        action: () => blockContact(),
                        loading: false,
                      })
                    }
                    icon={<CloseIcon />}
                    name={`Block ${currentChat.name}`}
                  />
                )}
              </div>
            </div>
          </>
        )}
        {currentChat && user && <PauseNotifications user={user} currentChat={currentChat} />}
        {confirm && (
          <ConfirmationPopup heading={confirm.heading} message={confirm.message} actionText={confirm.actionText} action={confirm.action} loading={confirm.loading} closeAction={closeAction} />
        )}
      </div>
      {currentChat && <Outlet context={{ currentChat }} />}
    </>
  )
}

export default ChatInfo
