import HeadingBar from './../../components/ui/HeadingBar'
import LeftArrow from './../../components/ui/icons/LeftArrow'
import { getShortDate, getTime } from './../../components/utilities'

const OpenEvent = ({ event, closeEvent }) => {
  return (
    <div className={`lhm-body event-body ${event.colour}`}>
      <HeadingBar heading={`Upcoming event`} iconLeft={<LeftArrow />} iconLeftClick={() => closeEvent()} colour={event && event.colour} />
      <div className={`container-20px`}>
        <span className='event-date'>{getShortDate(event.dateTime.seconds * 1000)}</span>
        <span className='event-time'>{getTime(event.dateTime.seconds * 1000)}</span>
        <p className='event-name'>
          <b>{event.name}</b>
        </p>
        <p>{event.description}</p>
      </div>
    </div>
  )
}

export default OpenEvent
