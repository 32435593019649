import { useState } from 'react'
import { useAuth } from './../../components/auth/AuthContext'
import { useNavigate } from 'react-router-dom'
import { signOut } from 'firebase/auth'
import { auth } from './../../firebase'
import { FirebaseAuthentication } from '@capacitor-firebase/authentication'

import AlertPopup from './../../components/ui/AlertPopup'
import NoticeOverlay from './../../components/ui/NoticeOverlay'
import HeadingBar from './../../components/ui/HeadingBar'
import ConfirmationPopup from './../../components/ui/ConfirmationPopup'

import Button from './../../components/ui/Button'
import LoadingSpinner from './../../components/ui/icons/LoadingSpinner'
import RightArrow from './../../components/ui/icons/RightArrow'
import LeftArrow from './../../components/ui/icons/LeftArrow'

const ChangePassword = () => {
  // auth context
  const { userData, changePassword } = useAuth()

  // use navigate
  const navigate = useNavigate()

  // password state
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  // alert
  const [alert, setAlert] = useState()

  // state for confirmation popup
  const [confirm, setConfirm] = useState(false)

  // close confirmation popup
  const closeAction = () => {
    setConfirm(false)
  }

  // loading
  const [loading, setLoading] = useState()

  // form errors
  const [formErrors, setFormErrors] = useState([])

  // sign out function
  const clickSignOut = async () => {
    // 1. Sign out on the native layer
    await FirebaseAuthentication.signOut()
    // 1. Sign out on the web layer
    await signOut(auth)
  }

  // handle input
  const handleInputChange = e => {
    if (formErrors.length > 0) {
      setFormErrors([])
    }
    // maintain an object for our form
    let name = e.target.name
    let value = e.target.value
    if (name === 'password') {
      setPassword(value)
    }
    if (name === 'confirmPassword') {
      setConfirmPassword(value)
    }
  }

  // change password function
  const initChangePassword = async e => {
    e.preventDefault()
    // set loading
    setLoading(true)
    // error if the two inputs don't match
    let errors = []
    if (password !== confirmPassword) {
      errors.push({
        colour: 'red',
        message: `Oops, passwords don't match`,
      })
    }

    if (errors.length > 0) {
      setFormErrors([...errors])
      setLoading(false)
      return
    } else {
      // sign in
      const response = await changePassword(password)
      // alert and send back to settings unless error
      if (response?.message) {
        switch (response.code) {
          case 'auth/weak-password':
            errors.push({
              colour: 'red',
              message: `Password too short: 6 character minimum`,
            })
            break
          case 'auth/requires-recent-login':
            setConfirm({
              heading: `Log in again`,
              message: `You haven't logged in in a while. To change your password, you must log in again.`,
              actionText: 'Sign out',
              action: () => clickSignOut(),
              loading: false,
            })
            break
          default:
            errors.push({
              colour: 'red',
              message: `Sorry, something went wrong`,
            })
        }
        setFormErrors([...errors])
        setLoading(false)
      } else {
        setLoading(false)
        setAlert({
          heading: 'Success',
          message: `Your password has been changed. You are still logged in.`,
          buttonText: 'OK',
          closeAction: () => navigate('/profile/settings'),
        })
      }
    }
  }
  return (
    <>
      <div className='lhm-body change-password'>
        <HeadingBar heading={`Change password`} iconLeft={<LeftArrow />} iconLeftClick={() => navigate(`/profile/settings`)} colour={userData.colour} />
        <div className='container-20px'>
          {formErrors && <NoticeOverlay notices={formErrors} />}

          <p className='start-p'>Enter a new password.</p>
          <form>
            <input placeholder='Choose a password' name='password' type='password' autoComplete='new-password' onChange={handleInputChange} value={password} required />
            <input placeholder='Re-enter password' name='confirmPassword' type='password' autoComplete='new-password' onChange={handleInputChange} value={confirmPassword} required />
            <Button
              action={e => initChangePassword(e)}
              fixed={true}
              colour={loading ? 'loading blue' : 'blue'}
              text={loading ? '' : 'Reset password'}
              icon={loading ? <LoadingSpinner /> : <RightArrow colour='#fff' />}
            />
          </form>
        </div>
      </div>
      {alert && <AlertPopup heading={alert.heading} message={alert.message} buttonText={alert.buttonText} closeAction={alert.closeAction} />}
      {confirm && <ConfirmationPopup heading={confirm.heading} message={confirm.message} actionText={confirm.actionText} action={confirm.action} loading={confirm.loading} closeAction={closeAction} />}
    </>
  )
}

export default ChangePassword
