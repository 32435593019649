import { calculateMessageTime, shouldShowTimestamp } from './../../components/utilities'
import { useAuth } from './../../components/auth/AuthContext'
import ProfilePic from './../../components/ui/ProfilePic'
import CloseIcon from './../../components/ui/icons/CloseIcon'

import { useNavigate } from 'react-router-dom'

const ChatMessage = ({
  message,
  id,
  showName,
  lastMessageTimestamp,
  lastMessageFromSame,
  nextMessageFromSame,
  nextMessageShowTimestamp,
  adminEditMode,
  showMessageOptions,
}) => {
  const { user } = useAuth()
  let showTimestamp = shouldShowTimestamp(message?.sentOn?.seconds || Number(new Date() / 1000), lastMessageTimestamp)
  let showProfile = (message.from && !nextMessageFromSame) || (message.from && nextMessageShowTimestamp)
  let fromMe = message?.from?.uid === user.uid

  // use navigate
  const navigate = useNavigate()

  return (
    <>
      {(!message?.removed || adminEditMode) && (
        <>
          <div
            className={`message-box glow-element ${message?.from?.colour} ${lastMessageFromSame ? 'last-message-from-same' : ''} ${
              nextMessageFromSame ? 'next-message-from-same' : ''
            } ${message.type} ${message?.from?.uid === user.uid ? `from-me` : ``} ${message?.removed && adminEditMode ? 'removed' : ''}`}
            onClick={adminEditMode ? () => showMessageOptions(id) : undefined}
          >
            {adminEditMode && <CloseIcon />}
            {showName && !fromMe && !lastMessageFromSame && <p className='message-name'>{message?.from?.name}</p>}
            {message.type === 'text' || message.type === 'system' ? (
              <>{message.content}</>
            ) : (
              <img src={message.content} alt={`Image from ${message?.from?.name}`} className='image-in-message' />
            )}

            {showProfile && (
              <ProfilePic
                firstName={message.from.name}
                image={message.from.profilePic}
                colour={message.from.colour}
                size={28}
                clickAction={
                  message?.from?.uid === user.uid && !adminEditMode ? undefined : () => navigate(`/profile/${message?.from?.uid}`)
                }
              />
            )}
          </div>
          {showTimestamp && (
            <p className='message-timestamp'>{calculateMessageTime(message?.sentOn?.seconds || Number(new Date() / 1000))}</p>
          )}
        </>
      )}
    </>
  )
}

export default ChatMessage
