import { useState } from 'react'
import { db } from './../../firebase'
import { collection, query, where, orderBy, doc, addDoc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore'
import { useCollection } from 'react-firebase-hooks/firestore'
import { useNavigate } from 'react-router-dom'

import { useAuth } from './../../components/auth/AuthContext'
import { useChats } from './../../components/auth/ChatContext'

import { Link } from 'react-router-dom'
import { calculateExactTime } from './../../components/utilities'

import ConfirmationPopup from './../../components/ui/ConfirmationPopup'
import LoadingSpinnerBlue from './../../components/ui/icons/LoadingSpinnerBlue'
import CloseIcon from './../../components/ui/icons/CloseIcon'
import { useEffect } from 'react'

const AdminReports = () => {
  // get reports
  const [reports, loading, error] = useCollection(
    query(collection(db, 'adminReports'), where('resolved', '==', false), orderBy('timestamp', 'desc'))
  )
  // setup navigate
  const navigate = useNavigate()
  // auth context
  const { user, role, userData } = useAuth()
  // chat context
  const { chats } = useChats()

  // keep track of errors
  useEffect(() => {
    if (error) {
      console.error(error)
    }
  }, [error])

  const resolveReport = async id => {
    // set loading
    setConfirm(current => {
      return { ...current, loading: true }
    })
    // set report as resolved
    await updateDoc(doc(db, 'adminReports', id), {
      resolved: true,
    })
    setConfirm(false)
  }

  // state for confirmation popup
  const [confirm, setConfirm] = useState(false)

  // close confirmation popup
  const closeAction = () => {
    setConfirm(false)
  }

  // go to chat with user, or start one
  const chatWith = async youthUser => {
    // this is a single, non-group chat
    // first, check for an existing chat between these two users
    if (chats && chats.length > 0) {
      let existingChat = chats.find(chat => {
        return chat.type === 'single' && chat.members[youthUser] && chat.members[user.uid]
      })
      if (existingChat) {
        return navigate(`/chats/${existingChat.id}`, { state: { fromAdmin: true } })
      }
    }
    // get user
    const getContact = await getDoc(doc(db, 'users', youthUser))
    let contact = { ...getContact.data(), id: getContact.id }
    // create single chat members object
    let members = {
      [user.uid]: {
        name: `${userData.firstName} ${userData.lastName}`,
        profilePic: userData?.profilePic || '',
        colour: userData.colour,
        memberSince: serverTimestamp(),
      },
      [contact.id]: {
        name: `${contact.firstName} ${contact.lastName}`,
        profilePic: contact?.profilePic || '',
        colour: contact.colour,
        memberSince: serverTimestamp(),
      },
    }
    if (contact.role === 'admin' || contact.role === 'mentor') {
      members[contact.id].role = contact.role
    }
    if (role === 'admin' || role === 'mentor') {
      members[user.uid].role = role
    }
    const chatDocRef = await addDoc(collection(db, 'chats'), {
      type: 'single',
      members: members,
      lastMessage: {
        message: `New chat started by ${userData.firstName} ${userData.lastName}`,
        timestamp: serverTimestamp(),
        readBy: [user.uid],
      },
      createdOn: serverTimestamp(),
    })
    await addDoc(collection(db, 'chats', chatDocRef.id, 'messages'), {
      type: 'system',
      from: '',
      sentOn: serverTimestamp(),
      content: `New chat started by ${userData.firstName} ${userData.lastName}`,
    })
    navigate(`/chats/${chatDocRef.id}`, { state: { fromAdmin: true } })
  }

  return (
    <div className='admin-page-container'>
      {error && (
        <div className='loading-spinner-blue-container'>
          <CloseIcon />
        </div>
      )}
      {loading ? (
        <div className='loading-spinner-blue-container'>
          <LoadingSpinnerBlue />
        </div>
      ) : reports && reports.docs.length > 0 ? (
        <>
          <div className='notifications-container'>
            {reports.docs.map(report => (
              <div className='report box-component red' key={report.id}>
                <h4>
                  {`${report.data().byUserName} (${report.data().byUserAge} years old) `}
                  <span className='notification-timestamp'>{calculateExactTime(report.data()?.timestamp?.seconds)}</span>
                </h4>

                <p className='notification-message'>
                  {report.data().chatType === 'group' ? (
                    <i>
                      On group chat called:{' '}
                      <Link to={`/chats/${report.data().aboutChat}`} state={{ fromAdmin: true }}>{`${report.data().chatName}`}</Link>
                    </i>
                  ) : report.data().chatType === 'community' ? (
                    <i>
                      On{' '}
                      <Link to={`/chats/${report.data().aboutChat}`} state={{ fromAdmin: true }}>
                        {`${report.data().chatName}`} community chat
                      </Link>
                    </i>
                  ) : report.data().chatType === 'single' ? (
                    <i>
                      On chat with{' '}
                      <Link to={`/chats/${report.data().aboutChat}`} state={{ fromAdmin: true }}>{`${report.data().chatName}`}</Link>
                    </i>
                  ) : (
                    ''
                  )}

                  {report.data().message}
                </p>
                <div className='actions-container'>
                  <p className='red-link' onClick={() => chatWith(report.data().byUser)}>
                    Chat with {report.data().byUserName.split(' ')[0]}
                  </p>
                  <p
                    onClick={() =>
                      setConfirm({
                        heading: `Mark as resolved?`,
                        message: `This will remove it from the reports list.`,
                        actionText: 'Resolved',
                        action: () => resolveReport(report.id),
                        loading: false,
                      })
                    }
                  >
                    Mark resolved
                  </p>
                </div>
              </div>
            ))}
          </div>
          {confirm && (
            <ConfirmationPopup
              heading={confirm.heading}
              message={confirm.message}
              actionText={confirm.actionText}
              action={confirm.action}
              loading={confirm.loading}
              closeAction={closeAction}
            />
          )}
        </>
      ) : (
        <p>No new reports</p>
      )}
    </div>
  )
}

export default AdminReports
