const AlertPopup = ({ heading, message, buttonText, closeAction }) => {
  return (
    <div className='lhm-body confirmation-popup-background'>
      <div className='popup-container box-component green alert'>
        <div className='container-20px'>
          <h3>{heading}</h3>
          <p>{message}</p>
        </div>
        <div className='popup-actions alert'>
          <button onClick={() => closeAction()}>{buttonText}</button>
        </div>
      </div>
    </div>
  )
}

export default AlertPopup
