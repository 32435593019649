import { getShortDate, getTime } from './../../components/utilities'
import CloseIcon from './icons/CloseIcon'

const EventTile = ({ event, clickEvent, deleteEvent }) => {
  const handleDeleteEvent = e => {
    e.stopPropagation()
    deleteEvent()
  }
  return (
    <div className={`event-tile ${event.colour}`} onClick={clickEvent ? () => clickEvent() : undefined}>
      {deleteEvent && (
        <button onClick={e => handleDeleteEvent(e)} className='svg-button close-icon'>
          <CloseIcon white={true} />
        </button>
      )}
      <span className='event-date'>{getShortDate(event.dateTime.seconds * 1000)}</span>
      <span className='event-time'>{getTime(event.dateTime.seconds * 1000)}</span>
      <p className='event-name'>
        <b>{event.name}</b>
      </p>
      <p className='event-description'>{event.description}</p>
    </div>
  )
}

export default EventTile
