import { useState } from 'react'
import app from './../../firebase'
import { db } from './../../firebase'
import { doc, getDoc, addDoc, collection, serverTimestamp } from 'firebase/firestore'
import { useParams } from 'react-router-dom'
import { getFunctions } from 'firebase/functions'
import { useHttpsCallable } from 'react-firebase-hooks/functions'
import { useDocumentData } from 'react-firebase-hooks/firestore'

import { useAuth } from './../../components/auth/AuthContext'
import { useChats } from './../../components/auth/ChatContext'
import { getAge, capitaliseFirstLetter } from './../../components/utilities'
import { useNavigate } from 'react-router-dom'

import ConfirmationPopup from './../../components/ui/ConfirmationPopup'
import UserListItem from './../../components/ui/UserListItem'
import Button from './../../components/ui/Button'
import CloseIcon from './../../components/ui/icons/CloseIcon'
import LoadingSpinner from './../../components/ui/icons/LoadingSpinner'
import LoadingSpinnerBlue from './../../components/ui/icons/LoadingSpinnerBlue'
import RightArrow from './../../components/ui/icons/RightArrow'

const ManageSingleUser = () => {
  // use auth
  const { user, userData, role } = useAuth()

  // use chats
  const { chats } = useChats()

  // nagiate
  const navigate = useNavigate()

  // selected role from input
  const [selectedRole, setSelectedRole] = useState('')

  // gain access to uid
  let params = useParams()

  // set up callable functions
  const [executeVerifyUser, executing, error] = useHttpsCallable(getFunctions(app, 'europe-west2'), 'verifyUser')

  // set up callable functions
  const [executeChangeRole, executingChangeRole, errorChangeRole] = useHttpsCallable(getFunctions(app, 'europe-west2'), 'changeRole')

  // set up callable functions
  const [executeDeclineUser, exceutingDeclineUser, errorDeclineUser] = useHttpsCallable(getFunctions(app, 'europe-west2'), 'declineUser')

  // to run cloud function which verifies a user
  const clickApprove = async () => {
    try {
      await executeVerifyUser({
        uid: params.uid,
        role: selectedRole,
      })
    } catch (error) {
      console.error(error)
    }
  }

  // to run cloud function which declines a user
  const declineUser = async () => {
    try {
      await executeDeclineUser({
        uid: params.uid,
      })
      return navigate(`/admin/manage-users`)
    } catch (error) {
      console.error(error)
    }
  }

  // set the option from popup
  const roleSelect = role => {
    setSelectedRole(role)
  }

  // state for confirmation popup
  const [confirm, setConfirm] = useState(false)

  // close confirmation popup
  const closeAction = () => {
    setConfirm(false)
  }

  // change role
  const changeRole = async role => {
    setConfirm(current => {
      return { ...current, loading: true }
    })
    try {
      await executeChangeRole({
        uid: params.uid,
        role: role,
      })
      return navigate(`/admin/manage-users`)
    } catch (error) {
      console.error(error)
    }
  }
  // chat with
  // go to chat with user, or start one
  const chatWith = async () => {
    // this is a single, non-group chat
    // first, check for an existing chat between these two users
    if (chats && chats.length > 0) {
      let existingChat = chats.find(chat => {
        return chat.type === 'single' && chat.members[params.uid] && chat.members[user.uid]
      })
      if (existingChat) {
        return navigate(`/chats/${existingChat.id}`, { state: { fromAdmin: true } })
      }
    }
    // get user
    const getContact = await getDoc(doc(db, 'users', params.uid))
    let contact = { ...getContact.data(), id: getContact.id }
    // create single chat members object
    let members = {
      [user.uid]: {
        name: `${userData.firstName} ${userData.lastName}`,
        profilePic: userData?.profilePic || '',
        colour: userData.colour,
        memberSince: serverTimestamp(),
      },
      [contact.id]: {
        name: `${contact.firstName} ${contact.lastName}`,
        profilePic: contact?.profilePic || '',
        colour: contact.colour,
        memberSince: serverTimestamp(),
      },
    }
    if (contact.role === 'admin' || contact.role === 'mentor') {
      members[contact.id].role = contact.role
    }
    if (role === 'admin' || role === 'mentor') {
      members[user.uid].role = role
    }
    const chatDocRef = await addDoc(collection(db, 'chats'), {
      type: 'single',
      members: members,
      lastMessage: {
        message: `New chat started by ${userData.firstName} ${userData.lastName}`,
        timestamp: serverTimestamp(),
        readBy: [user.uid],
      },
      createdOn: serverTimestamp(),
    })
    await addDoc(collection(db, 'chats', chatDocRef.id, 'messages'), {
      type: 'system',
      from: '',
      sentOn: serverTimestamp(),
      content: `New chat started by ${userData.firstName} ${userData.lastName}`,
    })
    navigate(`/chats/${chatDocRef.id}`, { state: { fromAdmin: true } })
  }
  // get and listen to user data
  const [viewingUser, loading, dataError] = useDocumentData(doc(db, 'users', params.uid))

  return (
    <div className='admin-page-container'>
      {(error || dataError || errorChangeRole) && (
        <div className='loading-spinner-blue-container'>
          <CloseIcon />
        </div>
      )}
      {loading ? (
        <div className='loading-spinner-blue-container'>
          <LoadingSpinnerBlue />
        </div>
      ) : viewingUser && viewingUser.role === 'unverified' ? (
        <div className='verify-user-container'>
          <p>
            <strong>Note:</strong> This is a new user. Please check their details carefully and then verify them. The user will be notified of this action by email.
          </p>
          <div className='box-component yellow'>
            <div className='new-user-data'>
              <p className='label'>Name</p>
              <p className='user-data'>
                {viewingUser.firstName} {viewingUser.lastName}
              </p>
              <p className='label'>Email address provided</p>
              <p className='user-data'>{viewingUser.email}</p>
              <p className='label'>Date of birth</p>
              <p className='user-data'>{new Intl.DateTimeFormat('en-GB').format(new Date(viewingUser.DOB.seconds * 1000))}</p>
              {viewingUser.parentName && (
                <>
                  <p className='label'>Parent</p>
                  <p className='user-data'>
                    {viewingUser.parentName} {viewingUser.parentLastName}
                  </p>
                </>
              )}
            </div>
          </div>
          <div className='checkbox-container green' onClick={() => roleSelect('youth')}>
            <div className={selectedRole === 'youth' ? 'checkbox checked' : 'checkbox'}></div>
            <p>Add as Youth Member</p>
          </div>
          <div className='checkbox-container' onClick={() => roleSelect('mentor')}>
            <div className={selectedRole === 'mentor' ? 'checkbox checked' : 'checkbox'}></div>
            <p>Add as Mentor</p>
          </div>
          <div className='checkbox-container blue-red' onClick={() => roleSelect('admin')}>
            <div className={selectedRole === 'admin' ? 'checkbox checked' : 'checkbox'}></div>
            <p>Add as Admin</p>
          </div>
          <div className='checkbox-container red' onClick={() => roleSelect('decline')}>
            <div className={selectedRole === 'decline' ? 'checkbox checked' : 'checkbox'}></div>
            <p>Decline this user</p>
          </div>
          {selectedRole && (
            <Button
              action={selectedRole === 'decline' ? declineUser : clickApprove}
              fixed={true}
              colour={`${selectedRole !== 'decline' ? (executing ? 'loading blue' : 'blue') : executing ? 'loading red' : 'red'}`}
              text={`${selectedRole !== 'decline' ? 'Verify user' : 'Decline membership'}`}
              icon={executing && <LoadingSpinner />}
            />
          )}
        </div>
      ) : (
        viewingUser &&
        viewingUser.role !== 'unverified' && (
          <div className='admin-user-profile'>
            <UserListItem
              key={viewingUser.id}
              firstName={viewingUser.firstName}
              lastName={viewingUser.lastName}
              belowName={
                <>
                  Open Chat&nbsp;&nbsp;
                  <RightArrow colour={'var(--body-black)'} />
                </>
              }
              colour={viewingUser.colour}
              profilePic={viewingUser.profilePic}
              clickHandler={() => chatWith()}
              size={50}
            />
            <h3>{capitaliseFirstLetter(viewingUser.role)} user</h3>
            <div className={`box-component ${viewingUser.colour}`}>
              <div className='new-user-data'>
                <p className='label'>Email address provided</p>
                <p className='user-data'>{viewingUser.email}</p>
                <p className='label'>Age</p>
                <p className='user-data'>{getAge(viewingUser.DOB.seconds * 1000)} years old</p>
                <p className='label'>Date of birth</p>
                <p className='user-data'>{new Intl.DateTimeFormat('en-GB').format(new Date(viewingUser.DOB.seconds * 1000))}</p>
                {viewingUser.parentName && (
                  <>
                    <p className='label'>Parent</p>
                    <p className='user-data'>
                      {viewingUser.parentName} {viewingUser.parentLastName}
                    </p>
                  </>
                )}
              </div>
            </div>
            <h3>Change role</h3>
            {viewingUser.role !== 'mentor' && (
              <Button
                action={() =>
                  setConfirm({
                    heading: `Make ${viewingUser.firstName} a Mentor?`,
                    message: `This will add them to all communities and cannot be undone.`,
                    actionText: 'Change Role',
                    action: () => changeRole('mentor'),
                    loading: false,
                  })
                }
                colour={'blue'}
                text='Change to Mentor'
              />
            )}
            {viewingUser.role !== 'admin' && (
              <Button
                action={() =>
                  setConfirm({
                    heading: `Make ${viewingUser.firstName} an Admin?`,
                    message: `This will add them to all communities and cannot be undone.`,
                    actionText: 'Change Role',
                    action: () => changeRole('admin'),
                    loading: false,
                  })
                }
                colour={'blue'}
                text='Change to Admin'
              />
            )}
            <Button
              action={() =>
                setConfirm({
                  heading: `Disable ${viewingUser.firstName}'s account?`,
                  message: `This will remove them from all communities and prevent them from logging in.`,
                  actionText: 'Disable user',
                  action: () => changeRole('unverified'),
                  loading: false,
                })
              }
              colour={'red'}
              text='Disable Account'
            />
            {viewingUser.bio && viewingUser?.bio !== '' && (
              <div className={`box-component ${viewingUser.colour}`}>
                <div className='new-user-data'>
                  <p>
                    <b>Pronouns:</b> {viewingUser?.pronouns}
                  </p>
                  <p className='user-data'>Bio: </p>
                  <p className='user-bio'>{viewingUser?.bio}</p>
                </div>
              </div>
            )}
          </div>
        )
      )}
      {confirm && <ConfirmationPopup heading={confirm.heading} message={confirm.message} actionText={confirm.actionText} action={confirm.action} loading={confirm.loading} closeAction={closeAction} />}
    </div>
  )
}

export default ManageSingleUser
