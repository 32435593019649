import { db } from '../../firebase'
import { collection, query, where, orderBy } from 'firebase/firestore'
import { useState, useEffect } from 'react'
import { useCollection } from 'react-firebase-hooks/firestore'
import { useNavigate } from 'react-router-dom'
import { getAge } from './../../components/utilities'

import UserListItem from './../../components/ui/UserListItem'
import SearchBar from './../../components/ui/SearchBar'

import LoadingSpinnerBlue from './../../components/ui/icons/LoadingSpinnerBlue'
import CloseIcon from './../../components/ui/icons/CloseIcon'

const ManageAllUsers = () => {
  // get users awaiting verification
  const [awaitingVerification, setAwaitingVerification] = useState([])
  const [unverifiedUsers, loading, error] = useCollection(query(collection(db, 'users'), where('verified', '==', false)))
  const [allUsers, setAllUsers] = useState([])
  const [verifiedUsers, allUsersLoading, allUsersError] = useCollection(
    query(collection(db, 'users'), where('verified', '==', true), orderBy('firstName'))
  )

  // set up navigation
  let navigate = useNavigate()

  useEffect(() => {
    if (unverifiedUsers) {
      let processUsersData = unverifiedUsers.docs.map(doc => {
        let docData = doc.data()
        return { ...docData, uid: doc.id }
      })
      setAwaitingVerification(processUsersData)
    }
  }, [unverifiedUsers, loading, error])

  useEffect(() => {
    if (!allUsersLoading && verifiedUsers) {
      let processUsersData = verifiedUsers.docs.map(doc => {
        let docData = doc.data()
        return { ...docData, uid: doc.id }
      })
      setAllUsers(processUsersData)
    }
  }, [verifiedUsers, allUsersLoading])

  // open user page
  const openUser = uid => {
    navigate(`${uid}`)
  }

  // search for users by firstName or lastName
  const [searchResults, setSearchResults] = useState([])
  const [isSearch, setIsSearch] = useState(false)
  const searchUsers = searchTerm => {
    if (searchTerm && searchTerm !== '') {
      let newSearchResults = allUsers.filter(user => {
        if (user.firstName.includes(searchTerm) || user.lastName.includes(searchTerm)) {
          return true
        }
        return false
      })
      setSearchResults(newSearchResults)
      setIsSearch(true)
    } else {
      setSearchResults([])
      setIsSearch(false)
    }
  }

  return (
    <div className='admin-page-container'>
      <SearchBar searchFunction={searchUsers} />
      {error && (
        <div className='loading-spinner-blue-container error-icon'>
          <CloseIcon />
          <h3>Something went wrong :(</h3>
        </div>
      )}
      {loading && (
        <div className='loading-spinner-blue-container'>
          <LoadingSpinnerBlue />
        </div>
      )}
      {awaitingVerification.length > 0 && (
        <>
          <h3>Awaiting verification</h3>
          <div className='users-list'>
            {awaitingVerification.map(user => (
              <UserListItem
                key={user.uid}
                firstName={user.firstName}
                lastName={user.lastName}
                belowName={user.email}
                colour={user.colour}
                size={50}
                clickHandler={() => openUser(user.uid)}
              />
            ))}
          </div>
        </>
      )}
      {searchResults.length > 0 && isSearch && (
        <div className='verified-user-list users-list'>
          {searchResults.map(user => (
            <UserListItem
              key={user.uid}
              firstName={user.firstName}
              lastName={user.lastName}
              size={50}
              belowName={`${user.role.charAt(0).toUpperCase() + user.role.slice(1)}, ${
                user.role === 'youth' ? `, ` + getAge(Number(user.DOB.seconds * 1000)) + ` years old` : ``
              }`}
              colour={user.colour}
              profilePic={user.profilePic}
              clickHandler={() => openUser(user.uid)}
            />
          ))}
        </div>
      )}
      {searchResults.length === 0 && isSearch && (
        <div className='container-20px'>
          <p>No users match your search.</p>
        </div>
      )}
      {allUsers.length > 0 && !isSearch && (
        <>
          <h3>Users</h3>
          <div className='verified-user-list users-list'>
            {allUsers.map(user => (
              <UserListItem
                key={user.uid}
                size={50}
                firstName={user.firstName}
                lastName={user.lastName}
                belowName={`${user.role.charAt(0).toUpperCase() + user.role.slice(1)}, ${
                  getAge(Number(user.DOB.seconds * 1000)) + ` years old`
                }`}
                colour={user.colour}
                profilePic={user.profilePic}
                clickHandler={() => openUser(user.uid)}
              />
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default ManageAllUsers
