import { useState, useRef, useEffect } from 'react'

import { db } from './../../firebase'
import { collection, addDoc, serverTimestamp, doc, updateDoc } from 'firebase/firestore'
import { useAuth } from './../../components/auth/AuthContext'

import { getAge, swearjar } from './../../components/utilities'

import { useHttpsCallable } from 'react-firebase-hooks/functions'
import { getFunctions } from 'firebase/functions'
import app from './../../firebase'
import { getStorage, ref, getDownloadURL } from 'firebase/storage'
import Compressor from 'compressorjs'

import CameraIcon from './../../components/ui/icons/CameraIcon'
import SendIcon from '../../components/ui/icons/SendIcon'
import NoticeOverlay from './../../components/ui/NoticeOverlay'
import LoadingSpinnerBlue from '../../components/ui/icons/LoadingSpinnerBlue'
import CloseIcon from '../../components/ui/icons/CloseIcon'

const ChatInput = ({ id, chatType, chatName, setWindowHeight, isTimeout }) => {
  // get current user
  const { user, userData, age } = useAuth()

  // newMessage
  const messageInput = useRef()
  const [newMessage, setNewMessage] = useState('')
  const [sending, setSending] = useState(false)
  // handle input
  const handleMessageInput = () => {
    setChatErrors([])
    let message = messageInput.current.value
    messageInput.current.style.height = '36px'
    messageInput.current.style.height = `${messageInput.current.scrollHeight + 1}px`
    if (messageInput.current.scrollHeight > 172) {
      messageInput.current.style.overflow = 'scroll'
    } else {
      messageInput.current.style.overflow = 'hidden'
    }
    setWindowHeight(messageInput.current.scrollHeight)
    setNewMessage(message)
  }

  // setup errors
  const [chatErrors, setChatErrors] = useState([])

  // close errors function
  const closeNotice = e => {
    let key = Number(e.currentTarget.dataset.key)
    let currentErrors = chatErrors.filter((el, index) => {
      if (index !== key) {
        return el
      }
      return null
    })
    setChatErrors(currentErrors)
  }

  // get the reference to the chat messages
  // single/group or community
  const collectionRef = ['lhmKidz', 'juniors', 'seniors'].includes(id) ? 'communityChats' : 'chats'
  // messages ref
  const chatMessagesRef = collection(db, collectionRef, id, 'messages')
  // top level chat document (for adding last message)
  const chatLastMessageRef = doc(db, collectionRef, id)

  // send profanity notification
  const sendProfanityNotification = async message => {
    const docRef = await addDoc(collection(db, 'adminNotifications'), {
      dismissed: false,
      type: 'Profanity',
      chatId: id,
      message: message,
      byUser: `${userData.firstName} ${userData.lastName}`,
      byUserAge: getAge(age),
      timestamp: serverTimestamp(),
    })
    return docRef
  }

  // handle timeout
  useEffect(() => {
    if (isTimeout) {
      setWindowHeight(-34)
    } else {
      if (imageMessage) {
        setWindowHeight(196)
      } else {
        setWindowHeight(messageInput.current.scrollHeight)
      }
    }
  }, [isTimeout])

  // handle enter key to send, allow shift for new line
  const handleKeyDown = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      sendMessage(e)
    }
  }

  // handle sending photos
  const [imageMessage, setImageMessage] = useState()
  const fileInput = useRef()

  const removeImage = () => {
    setImageMessage()
  }

  useEffect(() => {
    if (!imageMessage) {
      setWindowHeight(messageInput.current.scrollHeight)
    }
  }, [imageMessage])

  // use compressorjs
  const compressImage = file => {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.4,
        convertSize: 3000,
        width: 600,
        success: resolve,
        error: reject,
      })
    })
  }

  // create base64 image
  const getBase64 = file => {
    return new Promise(function (resolve, reject) {
      var reader = new FileReader()
      reader.onload = function () {
        resolve(reader.result.split(',')[1])
      }
      reader.onerror = reject
      reader.readAsDataURL(file)
    })
  }

  const changePic = async e => {
    setChatErrors([])
    if (!e.target.files[0]) {
      return
    }
    let newPic = e.target.files[0]
    // check file
    if (!['image/jpeg', 'image/png'].includes(newPic.type)) {
      setChatErrors([
        {
          colour: 'red',
          message: `This file isn't supported, try a jpeg?`,
        },
      ])
      return
    }
    // compress image
    const compressedImage = await compressImage(newPic)
    const imageUrl = URL.createObjectURL(compressedImage)

    // convert to base64
    const imageBase64 = await getBase64(compressedImage)

    // add file and path to state
    setImageMessage({
      base64: imageBase64,
      image: imageUrl,
      imagePath: `chats/${id}/${user.uid}-${Number(new Date())}.jpg`,
    })
    setNewMessage('')
    setWindowHeight(196)
  }

  // setup storage
  const storage = getStorage()
  // set up callable functions
  const [excecuteSaveImage, executing, imageError] = useHttpsCallable(getFunctions(app, 'europe-west2'), 'saveImage')

  useEffect(() => {
    if (imageError) {
      setChatErrors(existing => [
        ...existing,
        {
          colour: 'yellow',
          message: `Sorry, something has gone wrong sending your image.`,
        },
      ])
      console.error(imageError)
    }
  }, [imageError])

  // send message function
  const sendMessage = async e => {
    e.preventDefault()
    if (sending) {
      return
    }
    let getNewMessage = newMessage
    if (getNewMessage.length > 1800) {
      setChatErrors([
        {
          colour: 'red',
          message: 'Please send a shorter message',
        },
      ])
      return
    }
    if (getNewMessage || imageMessage) {
      setSending(true)
      // send profanity notifcation to admin
      if (swearjar.profane(getNewMessage)) {
        await sendProfanityNotification(getNewMessage)
      }
      if (getAge(age) < 13) {
        // censor profnity if younger than 13
        getNewMessage = swearjar.censor(getNewMessage)
      }
      // create message doc object for database
      let createNewMessage = {
        type: 'text',
        from: user.uid,
        sentOn: serverTimestamp(),
        content: getNewMessage,
      }

      messageInput?.current?.focus()

      let messageImage = null
      if (imageMessage) {
        try {
          const saveImage = await excecuteSaveImage({
            imageBase64: imageMessage.base64,
            imagePath: imageMessage.imagePath,
            userData: { userFirstName: userData.firstName, userLastName: userData.lastName, userAge: getAge(age), userUid: user.uid },
            fromChat: { chatName: chatName, chatType: chatType, chatId: id },
          })

          if (saveImage.data.message) {
            // get url of new file
            messageImage = await getDownloadURL(ref(storage, imageMessage.imagePath))
            // alter the message to be sent
            createNewMessage.type = 'image'
            createNewMessage.content = messageImage
          }
          if (saveImage.data.error === 'image flagged') {
            // put image back to what it was before
            messageImage = null
            setChatErrors([
              {
                colour: 'red',
                message: `Sorry, this image isn't allowed. Try a different image.`,
              },
            ])
            setImageMessage()
            setSending(false)
            return
          }
        } catch (error) {
          console.error(error)
        }
      }

      try {
        await addDoc(chatMessagesRef, createNewMessage)
        await updateDoc(chatLastMessageRef, {
          lastMessage: {
            message: createNewMessage.type === 'text' ? getNewMessage : `${userData.firstName} sent a photo`,
            from: `${userData.firstName} ${userData.lastName}`,
            timestamp: serverTimestamp(),
            readBy: [user.uid],
          },
        })

        if (messageImage) {
          setImageMessage()
        } else {
          messageInput.current.value = ''
          messageInput.current.style.height = '36px'
        }
        setNewMessage('')
        setWindowHeight(messageInput.current.scrollHeight)
        setSending(false)
      } catch (error) {
        setSending(false)
        console.error(error)
      }
    }
  }

  return (
    <>
      {chatErrors && <NoticeOverlay notices={chatErrors} closeFunction={closeNotice} />}
      {!isTimeout && (
        <form className='chat-input-bar'>
          {imageMessage ? (
            <div className='picture-message-box'>
              <div className='picture-message-container'>
                <button className='remove-image svg-button' onClick={() => removeImage()}>
                  <CloseIcon white={true} />
                </button>
                <img src={imageMessage.image} alt='' />
              </div>
            </div>
          ) : (
            <>
              {newMessage.length < 1 && (
                <button className='camera-button svg-button'>
                  <CameraIcon />
                  <input ref={fileInput} onChange={e => changePic(e)} type='file' name='image-message-file' id='image-message-file' accept='.jpg,.jpeg,.png,.gif' />
                </button>
              )}
              <textarea
                className={`chat-text-input ${sending ? 'sending' : ''}`}
                autoComplete='off'
                autoCorrect='on'
                readOnly={sending}
                spellCheck='true'
                aria-multiline='true'
                aria-label='Message'
                onInput={handleMessageInput}
                onKeyDown={handleKeyDown}
                ref={messageInput}
                tabIndex='-1'
              ></textarea>
            </>
          )}
          <button className='send-button svg-button' onClick={e => sendMessage(e)}>
            {sending ? <LoadingSpinnerBlue /> : <SendIcon />}
          </button>
        </form>
      )}
    </>
  )
}

export default ChatInput
