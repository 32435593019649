const HomeIcon = ({ active }) => (
  <svg width={35} height={30} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='m24.17 14.988-1.074-1.09c-1.235-1.088-2.527-2.134-3.761-3.237a33.267 33.267 0 0 1-2.381-2.367.513.513 0 1 1 .755-.697c.75.781 1.54 1.523 2.367 2.222 1.248 1.074 2.57 2.076 3.833 3.121l1.626 1.452c.182.193.352.396.508.61.087.15.132.32.131.494a.683.683 0 0 1-.203.508c-.363.392-.596.116-2.483.465v.755c0 1.045.276 2.149.276 3.136.004.488-.044.975-.146 1.452a1.19 1.19 0 0 1-.61.77c-.133.061-.275.1-.42.116h-2.033a8.994 8.994 0 0 1-.9-.087 1.25 1.25 0 0 1-1.046-.77 7.883 7.883 0 0 1-.087-1.452c-.001-.504-.12-1.002-.349-1.452a.945.945 0 0 0-.74-.537c-.436 0-.654.13-.726.436-.305 1.016-.102 2.512-.45 3.296a.885.885 0 0 1-.784.522h-.814c-.738.08-1.483.08-2.221 0a2.177 2.177 0 0 1-1.22-.624 1.263 1.263 0 0 1-.29-.74 7.991 7.991 0 0 1 0-1.119c0-.813.218-1.916.247-2.904a3.692 3.692 0 0 0 0-.566h-.044c-.563 0-1.126-.038-1.684-.116a1.988 1.988 0 0 1-.915-.436.639.639 0 0 1-.232-.537.653.653 0 0 1 .16-.435c.087-.088.319-.204.42-.29l.465-.437 1.626-1.539 5.024-4.791a.436.436 0 0 1 .625 0 .435.435 0 0 1 0 .639L11.8 13.71l-1.613 1.612h1.627a.755.755 0 0 1 .653.508c.142.47.21.96.203 1.452 0 .973-.174 2.062-.16 2.904.034.294.034.591 0 .886.189.015.378.015.567 0 .523 0 1.074 0 1.452-.073h.174v-1.074a4.66 4.66 0 0 1 .523-2.266 2.164 2.164 0 0 1 2.236-.943 2.557 2.557 0 0 1 1.815 1.002 4.85 4.85 0 0 1 .813 2.482c.055.278.09.56.102.843l1.452.087h.16c0-.276.188-.61.188-.712 0-.944-.145-2.018-.174-3.02-.05-.483-.05-.97 0-1.452a.77.77 0 0 1 .392-.537c.165-.071.335-.13.508-.174l1.452-.247Z'
      fill={active ? 'var(--blue)' : 'var(--grey)'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.447 3.444c.712-.479 2.004-1.713 2.31-1.93C20.772.965 21.856.55 22.98.278A10.295 10.295 0 0 1 26.479.06 9.394 9.394 0 0 1 33.55 5.1a10.672 10.672 0 0 1 .74 8.58 14.594 14.594 0 0 1-2.061 3.805 44.77 44.77 0 0 1-4.197 4.574 24.912 24.912 0 0 1-4.094 3.383 52.458 52.458 0 0 1-6.955 3.66.523.523 0 0 1-.683-.248.51.51 0 0 1 .247-.682 48.824 48.824 0 0 0 6.708-3.746c1.396-.96 2.69-2.06 3.863-3.282a45.132 45.132 0 0 0 3.978-4.515 12.968 12.968 0 0 0 1.815-3.398 9.135 9.135 0 0 0-.755-7.362 7.782 7.782 0 0 0-5.88-4.124 8.712 8.712 0 0 0-2.904.247c-.976.24-1.918.6-2.803 1.075-.32.174-1.742 1.452-2.41 1.945-.267.201-.595.304-.93.29a2.018 2.018 0 0 1-.827-.42c-.523-.392-1.19-1.147-1.452-1.35a9.51 9.51 0 0 0-2.047-1.075 4.675 4.675 0 0 0-2.106-.305c-.813.087-1.64.16-2.512.334a8.262 8.262 0 0 0-1.583.48 9.191 9.191 0 0 0-2.54 1.451 7.885 7.885 0 0 0-1.903 2.294 7.74 7.74 0 0 0-.348 6.07 23.231 23.231 0 0 0 4.98 8.508 26.237 26.237 0 0 0 8.756 6.215.465.465 0 0 1 .247.595.464.464 0 0 1-.596.233 26.916 26.916 0 0 1-4.922-2.658A25.654 25.654 0 0 1 6.02 22.06a24.524 24.524 0 0 1-5.43-8.93 9.205 9.205 0 0 1 .333-7.26 10.164 10.164 0 0 1 2.193-2.614 10.614 10.614 0 0 1 2.904-1.83A10.251 10.251 0 0 1 7.82.86C8.709.68 9.606.55 10.508.468A6.563 6.563 0 0 1 12.83.67c1.038.328 2.018.818 2.904 1.452.392.174 1.292.973 1.713 1.321Z'
      fill={active ? 'var(--red)' : 'var(--grey)'}
    />
  </svg>
)

export default HomeIcon
