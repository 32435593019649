import { useAuth } from './../../components/auth/AuthContext'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { db } from './../../firebase'
import { updateDoc, doc, addDoc, collection, serverTimestamp } from 'firebase/firestore'
import { useHttpsCallable } from 'react-firebase-hooks/functions'
import { getFunctions } from 'firebase/functions'
import app from './../../firebase'
import { getStorage, ref, getDownloadURL } from 'firebase/storage'
import Compressor from 'compressorjs'

import { swearjar, getAge } from './../../components/utilities'

import HeadingBar from './../../components/ui/HeadingBar'
import LeftArrow from './../../components/ui/icons/LeftArrow'
import Button from './../../components/ui/Button'
import CameraIcon from './../../components/ui/icons/CameraIcon'
import LoadingSpinner from './../../components/ui/icons/LoadingSpinner'
import NoticeOverlay from './../../components/ui/NoticeOverlay'

const EditGroup = () => {
  // user data
  const { user, userData, age } = useAuth()
  // get chatData from parent
  const { currentChat } = useOutletContext()

  // group settings
  const [newGroupDetails, setNewGroupDetails] = useState({
    name: currentChat.name,
    colour: currentChat.colour,
    profilePic: currentChat.image,
  })

  // navigate
  const navigate = useNavigate()

  // form errors
  const [formErrors, setFormErrors] = useState([])

  // close notice function, removes from formErrors array
  const closeNotice = e => {
    let key = Number(e.currentTarget.dataset.key)
    let currentErrors = formErrors.filter((el, index) => {
      if (index !== key) {
        return el
      }
      return null
    })
    setFormErrors(currentErrors)
  }

  // handle images
  // use compressorjs
  const compressImage = file => {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.4,
        convertSize: 3000,
        width: 600,
        height: 600,
        resize: 'cover',
        success: resolve,
        error: reject,
      })
    })
  }

  // create base64 image
  const getBase64 = file => {
    return new Promise(function (resolve, reject) {
      var reader = new FileReader()
      reader.onload = function () {
        resolve(reader.result.split(',')[1])
      }
      reader.onerror = reject
      reader.readAsDataURL(file)
    })
  }

  const changePic = async e => {
    setFormErrors([])
    if (!e.target.files[0]) {
      return
    }
    let newPic = e.target.files[0]
    // check file
    if (!['image/jpeg', 'image/png'].includes(newPic.type)) {
      setFormErrors([
        {
          colour: 'red',
          message: `This file isn't supported, try a jpeg?`,
        },
      ])
      return
    }
    // compress image
    const compressedImage = await compressImage(newPic)
    const imageUrl = URL.createObjectURL(compressedImage)

    // convert to base64
    const imageBase64 = await getBase64(compressedImage)

    // add file and path to state
    setNewGroupDetails({
      ...newGroupDetails,
      profilePicBase64: imageBase64,
      profilePic: imageUrl,
      profilePicPath: `groups/${currentChat.id}.jpg`,
    })
  }

  // setup storage
  const storage = getStorage()
  // set up callable functions
  const [excecuteSaveImage, executing, imageError] = useHttpsCallable(getFunctions(app, 'europe-west2'), 'saveImage')

  useEffect(() => {
    if (imageError) {
      setFormErrors(existing => [
        ...existing,
        {
          colour: 'yellow',
          message: `Sorry, something has gone wrong uploading your group profile image.`,
        },
      ])
      console.error(imageError)
    }
  }, [imageError])

  // take the name of group chats
  const handleGroupForm = e => {
    if (formErrors.length > 0) {
      setFormErrors([])
    }
    let name = e.target.name
    let value = e.target.value
    if (value.length > 22) {
      setFormErrors([
        {
          colour: 'red',
          message: 'This is the maximum group name length',
        },
      ])
    } else {
      setNewGroupDetails({ ...newGroupDetails, [name]: value })
    }
  }

  const [saving, setSaving] = useState(false)

  const updateGroup = async () => {
    setSaving(true)
    if (swearjar.profane(newGroupDetails.name) || ['LHM Kidz', 'Juniors', 'Seniors'].includes(newGroupDetails.name)) {
      setFormErrors([
        {
          colour: 'red',
          message: 'This group name is not allowed',
        },
      ])
      setSaving(false)
      return
    }
    // handle profile picture
    let profilePic = newGroupDetails.profilePic || ''
    if (newGroupDetails.profilePicBase64) {
      try {
        const saveImage = await excecuteSaveImage({
          imageBase64: newGroupDetails.profilePicBase64,
          imagePath: newGroupDetails.profilePicPath,
          userData: { userFirstName: userData.firstName, userLastName: userData.lastName, userAge: getAge(age), userUid: user.uid },
          fromChat: false,
        })
        if (saveImage.data.message) {
          // get url of new file
          profilePic = await getDownloadURL(ref(storage, newGroupDetails.profilePicPath))
        }
        if (saveImage.data.error === 'image flagged') {
          // put image back to what it was before
          profilePic = newGroupDetails.profilePic || ''
          setFormErrors([
            {
              colour: 'red',
              message: `Sorry, your new profile picture isn't allowed. Try a different image.`,
            },
          ])
          setNewGroupDetails({
            ...newGroupDetails,
            profilePicBase64: null,
            profilePic: '',
            profilePicPath: '',
          })
        }
      } catch (error) {
        console.error(error)
      }
    }

    let sendMessage = false

    if (currentChat.name !== newGroupDetails.name) {
      sendMessage = `${userData.firstName} ${userData.lastName} renamed this group to "${newGroupDetails.name}"`
    }

    let updatedSettings = {
      name: newGroupDetails.name,
      colour: newGroupDetails?.colour || 'orange-pink',
      profilePic: profilePic,
    }

    if (sendMessage) {
      updatedSettings = {
        ...updatedSettings,
        lastMessage: {
          message: sendMessage,
          timestamp: serverTimestamp(),
          readBy: [user.uid],
        },
      }
    }

    const chatRef = doc(db, 'chats', currentChat.id)

    try {
      await updateDoc(chatRef, updatedSettings)
      if (sendMessage) {
        await addDoc(collection(db, 'chats', currentChat.id, 'messages'), {
          type: 'system',
          from: '',
          sentOn: serverTimestamp(),
          content: sendMessage,
        })
      }
      setFormErrors([
        {
          colour: 'blue',
          message: 'Group updated!',
        },
      ])
      setSaving(false)
    } catch (error) {
      setFormErrors([
        {
          colour: 'red',
          message: 'Sorry, group settings failed to save',
        },
      ])
      console.error(error)
      setSaving(false)
    }
  }

  return (
    <div className='lhm-body single-chat-container'>
      <HeadingBar heading={'Edit group'} iconLeft={<LeftArrow />} iconLeftClick={() => navigate(`/chats/${currentChat.id}/info`)} colour={currentChat && currentChat.colour} />
      {formErrors && <NoticeOverlay notices={formErrors} closeFunction={closeNotice} belowTopBar={true} />}
      <div className='container-20px' style={{ marginTop: '30px' }}>
        <form className='smaller-inputs'>
          <p>
            <strong>Give your group a name</strong>
          </p>
          <input placeholder='New group' type='text' name='name' onChange={handleGroupForm} value={newGroupDetails.name} autoComplete='off' />
          <p>
            <strong>Choose a group colour</strong>
          </p>
          <style>{`\
              .swatch.current::before{\
                background-image:${newGroupDetails.profilePic ? `url("${newGroupDetails.profilePic}")` : 'white'};\
              }\
            `}</style>
          <div className='swatch-container'>
            <div className={`swatch orange-pink ${newGroupDetails.colour === 'orange-pink' && 'current'}`} onClick={() => setNewGroupDetails({ ...newGroupDetails, colour: 'orange-pink' })}></div>
            <div className={`swatch green-blue ${newGroupDetails.colour === 'green-blue' && 'current'}`} onClick={() => setNewGroupDetails({ ...newGroupDetails, colour: 'green-blue' })}></div>
            <div className={`swatch blue-pink ${newGroupDetails.colour === 'blue-pink' && 'current'}`} onClick={() => setNewGroupDetails({ ...newGroupDetails, colour: 'blue-pink' })}></div>
            <div className={`swatch pink-green ${newGroupDetails.colour === 'pink-green' && 'current'}`} onClick={() => setNewGroupDetails({ ...newGroupDetails, colour: 'pink-green' })}></div>
            <div className={`swatch night-blue ${newGroupDetails.colour === 'night-blue' && 'current'}`} onClick={() => setNewGroupDetails({ ...newGroupDetails, colour: 'night-blue' })}></div>
          </div>
          <p>
            <strong>Add a group photo (optional)</strong>
          </p>
          <label htmlFor='profile-pic' className='profile-pic-upload'>
            <CameraIcon />
            <input onChange={e => changePic(e)} type='file' name='profile-pic' id='profile-pic' accept='.jpg,.jpeg,.png,.gif' />
          </label>
        </form>
      </div>
      <div className='container-20px'>
        <div>
          <Button action={() => updateGroup()} fixed={true} colour={saving ? 'loading blue' : 'blue'} text={'Save'} icon={saving && <LoadingSpinner />} />
        </div>
      </div>
    </div>
  )
}

export default EditGroup
