const SendIcon = () => (
  <svg width={23} height={23} viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M22.904 9.957a12.807 12.807 0 0 0-1.092-3.565 9.047 9.047 0 0 0-2.204-3.038 12.46 12.46 0 0 0-1.361-.958 26.788 26.788 0 0 0-2.109-1.179.339.339 0 1 0-.287.613c.374.183 1.044.556 1.696.959.561.342 1.102.716 1.62 1.121a8.299 8.299 0 0 1 1.916 2.798c.484 1.062.807 2.189.959 3.345a9.958 9.958 0 0 1-3.91 8.874 10.322 10.322 0 0 1-9.46 2.08 22.855 22.855 0 0 1-1.916-.671c-.61-.257-1.2-.562-1.763-.91a8.53 8.53 0 0 1-3.556-4.677 9.152 9.152 0 0 1 .087-5.942A12.851 12.851 0 0 1 5.75 2.875a9.718 9.718 0 0 1 6.967-1.754.297.297 0 0 0 .335-.258.298.298 0 0 0-.268-.326A10.33 10.33 0 0 0 5.31 2.262 13.714 13.714 0 0 0 .68 8.49a10.14 10.14 0 0 0-.24 6.603 9.526 9.526 0 0 0 3.978 5.309 12.95 12.95 0 0 0 1.917.958c.657.275 1.329.515 2.012.719a11.413 11.413 0 0 0 10.455-2.348 10.916 10.916 0 0 0 4.102-9.775Z'
      fill='#CB0935'
    />
    <path
      d='M11.375 6.986a1.025 1.025 0 0 0-.632-.163c-.218.051-.42.157-.585.307-.343.336-.663.695-.958 1.073-1.39 1.706-3.402 4.792-4.015 5.645a.307.307 0 0 0 .067.422.297.297 0 0 0 .412-.067c.44-.556 1.571-1.975 2.702-3.345.834-.958 1.668-1.965 2.243-2.54.118-.108.246-.204.383-.287.096.077.21.192.316.287.938.857 1.83 1.762 2.674 2.712a40.473 40.473 0 0 1 3.038 3.575.337.337 0 0 0 .402.259.326.326 0 0 0 .26-.393 41.61 41.61 0 0 0-4.265-5.578 11.012 11.012 0 0 0-2.042-1.907Zm-.613.959c0-.048 0 0 .105 0 .106 0-.115.048-.105.01v-.01Z'
      fill='#007FAF'
    />
  </svg>
)

export default SendIcon
