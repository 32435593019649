const GroupChatIcon = () => (
  <svg
    width={30}
    height={30}
    viewBox='0 0 30 30
  '
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M28.987 12.599c-.108-2.42-1.948-5.832-5.07-8.325A14.822 14.822 0 0 0 14.71.983a14.955 14.955 0 0 0-9.825 3.424 13.709 13.709 0 0 0-4.84 9.196c-.218 3.703.363 5.868 1.657 7.357 1.295 1.488 3.558 2.42 6.631 3.448.109 0 .145.182.206.363a4.307 4.307 0 0 1 0 1.876 5.373 5.373 0 0 1-1.09 2.516.52.52 0 0 0 .436.835c.356.008.712-.012 1.065-.06 1.52-.153 2.99-.632 4.308-1.404.45-.33.857-.716 1.21-1.15.835-1.016 1.548-2.298 1.96-2.83.496 0 1.694 0 2.952-.158a16.278 16.278 0 0 0 2.13-.363 10.103 10.103 0 0 0 5.88-4.307 12.1 12.1 0 0 0 1.597-7.127Zm-2.42 6.63a9.123 9.123 0 0 1-5.263 4.018c-.66.172-1.331.297-2.008.375-1.598.193-2.904-.085-3.304.205-.399.29-1.113 1.61-1.887 2.614-.37.514-.815.971-1.32 1.355a9.438 9.438 0 0 1-3.932 1.21 6.97 6.97 0 0 0 .775-2.843 4.368 4.368 0 0 0-.218-1.924 1.354 1.354 0 0 0-.774-.847c-2.82-1.029-4.84-1.767-6.05-3.219s-1.513-3.242-1.21-6.485a12.354 12.354 0 0 1 4.477-8.216 13.733 13.733 0 0 1 8.954-3.097 13.564 13.564 0 0 1 8.397 2.903c2.843 2.203 4.61 5.203 4.755 7.381a11.083 11.083 0 0 1-1.464 6.57h.072Z'
      fill='#979797'
    />
    <path
      d='M23.809 9.042c-.847-.085-3.219-.255-5.978-.351h-5.118a120.94 120.94 0 0 0-6.05.375.363.363 0 0 0-.278.387.35.35 0 0 0 .375.339c1.21 0 3.46.145 5.953.23h5.106c2.747 0 5.106-.134 5.941-.121a.411.411 0 0 0 0-.823l.049-.036ZM23.422 13.518h-8.228l-2.493.097c-2.82.158-5.166.375-5.771.387a.35.35 0 0 0-.376.34.363.363 0 0 0 .34.386c.616 0 2.988.194 5.843.218h2.493c.835 0 1.67-.06 2.492-.109 2.3-.133 4.429-.314 5.796-.435a.412.412 0 0 0 0-.823l-.096-.06ZM15.4 18.128c-.509 0-1.695-.108-3.062-.12h-1.295c-.435 0-.87 0-1.294.096-1.21.133-2.42.339-2.989.4a.351.351 0 0 0-.363.35.375.375 0 0 0 .351.376c1.162.123 2.329.188 3.497.193.69 0 1.403 0 2.069-.12 1.38-.134 2.565-.34 3.073-.376a.411.411 0 0 0 .424-.4.424.424 0 0 0-.412-.399Z'
      fill='#979797'
    />
  </svg>
)

export default GroupChatIcon
