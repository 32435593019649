import { Outlet } from 'react-router-dom'
import Navigation from './../ui/Navigation'

const MainLayout = () => {
  return (
    <>
      <Outlet />
      <Navigation />
    </>
  )
}

export default MainLayout
