import { useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from './../../components/auth/AuthContext'

import RightArrow from './../../components/ui/icons/RightArrow'
import LoadingSpinner from './../../components/ui/icons/LoadingSpinner'
import Button from './../../components/ui/Button'
import NoticeOverlay from './../../components/ui/NoticeOverlay'

const ForgotPassword = () => {
  // use navigate
  const navigate = useNavigate()
  // auth context
  const { resetPassword } = useAuth()
  // forgot password form state
  const [email, setEmail] = useState('')

  // loading state
  const [loading, setLoading] = useState(false)

  // done state
  const [done, setDone] = useState(false)

  // form errors
  const [formErrors, setFormErrors] = useState([])

  const handleInputChange = e => {
    if (formErrors.length > 0) {
      setFormErrors([])
    }
    // pass in input value
    let value = e.target.value
    setEmail(value)
  }

  // validation ref
  const emailField = useRef()

  const initPasswordReset = async e => {
    e.preventDefault()
    setLoading(true)
    let errors = []
    if (!emailField.current.validity.valid) {
      errors.push({
        colour: 'red',
        message: 'Oops, please check your email address',
      })
    }
    if (errors.length > 0) {
      setFormErrors([...errors])
    } else {
      // sign in
      await resetPassword(email)

      let errors = []
      errors.push({
        colour: 'blue',
        message: 'If this email matches an account, you will receive an email to reset your password',
      })
      setFormErrors([...errors])
      setLoading(false)
      setDone(true)
    }
  }

  return (
    <>
      {formErrors && <NoticeOverlay notices={formErrors} />}

      <h1 className='text-center'>Reset password</h1>
      <p className='start-p'>Enter the email address you made your account with.</p>
      <form>
        <input placeholder='Email' ref={emailField} name='email' type='email' autoComplete='email' onChange={handleInputChange} value={email} required />
        {done ? (
          <Button action={() => navigate('/login')} colour={'blue'} text={'Log in'} icon={<RightArrow colour='#fff' />} />
        ) : (
          <Button action={initPasswordReset} colour={loading ? 'loading blue' : 'blue'} text={loading ? '' : 'Reset password'} icon={loading ? <LoadingSpinner /> : <RightArrow colour='#fff' />} />
        )}
      </form>
    </>
  )
}

export default ForgotPassword
