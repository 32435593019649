const CameraIcon = () => (
  <svg width={27} height={28} viewBox='0 0 27 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M26.534 13.41a4.196 4.196 0 0 0-2.382-3.015c-1.858-.98-2.581-.212-3.338-2.081-.312-.957-.601-1.97-1.424-2.504-2.226-1.346-6.41-.779-8.902-.234-.868.178-2.649.579-2.905 1.425l-.69 2.837c-2.537 0-5.107 1.035-5.686 3.517C.35 16.97.094 24.482 3.5 26.018c5.197 2.392 17.693 1.113 20.03.122 4.295-1.836 3.65-8.145 3.005-12.73Zm-3.517 11.473c-1.97.812-14.354 1.97-18.917-.067-2.76-1.168-2.515-8.346-1.97-11.261.412-2.037 2.649-2.86 4.674-2.949a.356.356 0 0 0 .234.267c.668.19.523-1.113 1.48-3.472 0 0 .111 0 .167-.089.756-.356 8.067-1.546 10.092-.512.902.468.957 1.859 1.703 2.682H18.79a.434.434 0 0 0 0 .857 9.17 9.17 0 0 1 4.851.901 3.227 3.227 0 0 1 1.803 2.293c.456 3.76 1.102 9.925-2.426 11.35Z'
      fill='#007FAF'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.924 11.685c-5.185.145-8.49 6.677-4.64 10.238a7.166 7.166 0 0 0 5.33 1.168.38.38 0 1 0-.089-.757c-1.402.134-3.527-.133-4.529-1.112-2.86-2.893.09-8.012 3.962-8.057a5.272 5.272 0 0 1 4.006 1.503 4.551 4.551 0 0 1 1.457 3.928 4.228 4.228 0 0 1-3.738 3.716.426.426 0 1 0 .1.846 5.23 5.23 0 0 0 4.729-4.295 5.775 5.775 0 0 0-1.602-5.119 6.6 6.6 0 0 0-4.986-2.059Z'
      fill='#CB0935'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.235 15.87a3.226 3.226 0 0 1 3.149.856 2.304 2.304 0 0 1-.735 3.005.426.426 0 1 0 .345.779 3.338 3.338 0 0 0 1.402-4.452 4.228 4.228 0 0 0-4.607-1.568c-2.581.879-3.594 4.93-.979 6.175a3.717 3.717 0 0 0 2.86-.155.379.379 0 0 0-.211-.724c-4.084 1.135-3.194-3.305-1.224-3.917Z'
      fill='#007FAF'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.986 9.582a.378.378 0 0 0 .712.156 6.843 6.843 0 0 0 .167-3.65c.69-.245 2.482.3 3.171-2.07.357-1.224-.289-.778 1.113-.634a3.65 3.65 0 0 0 2.938-1.113 3.005 3.005 0 0 0 3.639 1.013.428.428 0 1 0-.234-.823c-.824.1-1.113.211-1.68-.067-.568-.278-.757-.88-.968-1.258A.668.668 0 0 0 9.73.847a6.22 6.22 0 0 1-1.19.946 1.947 1.947 0 0 1-1.392.167c-.534-.111-1.48-.49-1.535-.5a.768.768 0 0 0-.935.811c.101.447.101.911 0 1.358-.09.334-.267.823-.59.957-.59.267-2.56.312-2.326 1.413a6.465 6.465 0 0 1 .223 3.583Z'
      fill='#CB0935'
    />
  </svg>
)

export default CameraIcon
