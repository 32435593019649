const EditFinish = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={26}
    height={26}
    viewBox='0 0 26 26'
    style={{
      enableBackground: 'new 0 0 26 26',
    }}
    xmlSpace='preserve'
  >
    <path
      d='M21.8 11.2c0-.1 0-.2-.1-.3-.1-.1-.2-.1-.3-.1h-.1s-.1 0-.1.1-.1.1-.1.1v.1l.2 11.7c0 .5 0 .9-.1 1.3-.1.2-.2.3-.3.4-.1.1-.3.1-.5.1H2.1c-.2 0-.3-.1-.4-.3-.1-.1-.2-.3-.3-.5-.2-2.3-.3-4.7-.2-7 0-4.6 0-9.9.2-10.7.1-.4.2-.7.4-1 .1.1.2-.1.4-.1.1-.1.3-.1.4-.1h11.8c.1 0 .2 0 .2-.1.1-.1.1-.1.1-.2s0-.2-.1-.2c-.1-.1-.1-.1-.2-.1L2.6 3.9c-.5 0-.9.2-1.2.5-.5.5-.8 1-.9 1.6-.2.8-.4 6.2-.5 10.9-.1 2.5 0 4.9.2 7.4.1.4.3.8.6 1.1.3.3.7.5 1.1.6H4l6.6-.1c4.6-.1 9.4-.2 9.9-.2.3 0 .6-.1.8-.2.3-.1.5-.3.6-.6.3-.6.4-1.4.3-2.1l-.4-11.6z'
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        fill: 'var(--blue)',
      }}
    />
    <path
      d='m4 12.2 6.1 6.6c.4.7 1.3.7 1.8.1 0 0 1.3-2.1 3.9-5.3s7-9 8.3-11.6c.4-.7-.3-1.2-1.1-.3-8.6 10.7-8.7 10.1-11 12.5-.4.4-1 .4-1.4.1-2.9-1.8-4.7-3.4-4.9-3.6-1.1-.5-2.3.5-1.7 1.5z'
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        fill: 'var(--red)',
      }}
    />
  </svg>
)

export default EditFinish
