import { Link, useLocation } from 'react-router-dom'

const CustomLink = ({ children, to }) => {
  // work out current page
  let location = useLocation()
  let active = false
  if (location.pathname === `/${to}` || location.pathname.startsWith(`/${to}`)) {
    active = true
  }
  if (location.pathname !== '/admin' && to === 'admin') {
    active = false
  }

  return (
    <Link to={to} className={active ? 'active' : undefined}>
      {children}
    </Link>
  )
}

export default CustomLink
