import { useLocation, Outlet, Navigate } from 'react-router-dom'
import { useAuth } from './AuthContext'
import Loading from '../ui/Loading'
import Unverified from '../../routes/onboarding/Unverified'

const NoAuth = () => {
  const { user, role, authLoading } = useAuth()
  let location = useLocation()

  return authLoading ? <Loading /> : user && role ? role === 'unverified' ? <Unverified /> : <Navigate to={location?.state?.from?.pathname || '/'} replace /> : <Outlet />
}

export default NoAuth
