import { useState, useMemo, useEffect, useRef } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useAuth } from './../../components/auth/AuthContext'
import { useChats } from '../../components/auth/ChatContext'

import { db } from './../../firebase'
import { query, collection, orderBy, where } from 'firebase/firestore'
import { useCollection } from 'react-firebase-hooks/firestore'

import { LocalNotifications } from '@capacitor/local-notifications'
import { Capacitor } from '@capacitor/core'

import OpenEvent from './OpenEvent'
import ChatListItem from './../chats/ChatListItem'
import UserListItem from './../../components/ui/UserListItem'
import CloseIcon from './../../components/ui/icons/CloseIcon'
import CalendarIcon from './../../components/ui/icons/CalendarIcon'
import LoadingSpinnerBlue from './../../components/ui/icons/LoadingSpinnerBlue'
import EventTile from './../../components/ui/EventTile'
import ChatIcon from './../../components/ui/icons/ChatIcon'
import RightArrow from './../../components/ui/icons/RightArrow'

const Home = () => {
  // use auth and chat contexts
  const { userData } = useAuth()
  const { chats, userDataLoading, chatsLoading } = useChats()

  // use navigate
  const navigate = useNavigate()

  const [homeChats, setHomeChats] = useState([])

  useEffect(() => {
    if (chats.length > 1) {
      setHomeChats([chats[0], chats[1]])
    } else {
      setHomeChats([...chats])
    }
  }, [chats])

  // get events query
  const eventsQuery = useMemo(() => {
    // get array of communities
    let communities = []
    for (const community in userData?.communities) {
      communities.push(community)
    }

    const currentDate = new Date()

    let eventsQuery = query(collection(db, 'events'), where('community', 'in', communities), where('dateTime', '>=', currentDate), orderBy('dateTime', 'asc'))
    return eventsQuery
  }, [userData])
  // get events
  const [events, eventsLoading, eventsError] = useCollection(eventsQuery)
  const [broadcast, broadcastLoading, broadcastError] = useCollection(query(collection(db, 'broadcasts'), orderBy('timestamp', 'desc')))

  // set local notifcation for each event
  useEffect(() => {
    const setLocalNotifications = async () => {
      if (events) {
        const permissions = await LocalNotifications.checkPermissions()
        if (permissions.display !== 'granted') {
          const permissions = await LocalNotifications.requestPermissions()
          if (permissions.display !== 'granted') return
        }
        // check existing notifications
        const pending = await LocalNotifications.getPending()

        const deviceType = Capacitor.getPlatform()

        // loop through events and shedule notifications that aren't already pending
        events.docs.forEach(async event => {
          const eventDateSeconds = event.data().dateTime.seconds
          if (typeof eventDateSeconds !== 'number' || isNaN(eventDateSeconds)) {
            console.error('Invalid event date seconds:', eventDateSeconds)
          }
          const eventDate = new Date(eventDateSeconds * 1000)
          const notificationDate = new Date(eventDate.getTime() - 30 * 60 * 1000) // Subtract 30 minutes in milliseconds
          const now = new Date()
          const timeToEvent = eventDate - now

          if (timeToEvent > 0) {
            let eventNotification = {
              title: `${event.data().name}`,
              body: `Event starting in 30 minutes, join us in the group chat!`,
              id: event.data().dateTime.seconds,
              schedule: { at: notificationDate },
            }
            if (deviceType === 'android') {
              eventNotification.channelId = 'little_hearts_matter'
              eventNotification.smallIcon = 'puzzle'
              eventNotification.largeIcon = 'puzzle'
              eventNotification.iconColor = '#cb0935'
              eventNotification.schedule.allowWhileIdle = true
            }

            // check if notification is already pending
            const isPending = pending.notifications.find(notification => notification.id === event.data().dateTime.seconds)
            if (!isPending) {
              try {
                const result = await LocalNotifications.schedule({ notifications: [eventNotification] })
              } catch (error) {
                console.error('Error scheduling notification:', error)
              }
            }
          }
        })
      }
    }

    if (Capacitor.isNativePlatform()) {
      setLocalNotifications()
    }
  }, [events])

  //
  useEffect(() => {
    if (eventsError) {
      console.error(eventsError)
    }
    if (broadcastError) {
      console.error(broadcastError)
    }
  }, [eventsError, broadcastError])

  // state for open event
  // get event data from parent
  const [openEvent, setOpenEvent] = useState(false)

  const closeEvent = () => {
    setOpenEvent(false)
  }

  const broadcastMessage = useRef()

  const readMore = e => {
    broadcastMessage.current.classList.toggle('expanded')
    if (broadcastMessage.current.classList.contains('expanded')) {
      e.target.textContent = 'Read less'
    } else {
      e.target.textContent = 'Read more'
    }
  }

  return (
    <>
      <div className='lhm-body home-body-container'>
        {!userDataLoading && userData && (
          <div className='container-20px'>
            <h1 className='welcome-message'>Hello {userData.firstName}</h1>
            {broadcastError && (
              <div className='loading-spinner-blue-container'>
                <CloseIcon />
              </div>
            )}
            {broadcastLoading ? (
              <div className='loading-spinner-blue-container'>
                <LoadingSpinnerBlue />
              </div>
            ) : (
              broadcast &&
              broadcast?.docs?.length > 0 && (
                <div className='box-component blue-red'>
                  <UserListItem
                    firstName={broadcast.docs[0].data().byUserFirstName}
                    lastName={broadcast.docs[0].data().byUserLastName}
                    profilePic={broadcast.docs[0].data()?.byUserProfilePic}
                    colour={broadcast.docs[0].data().byUserColour}
                    belowName={'Youth leader'}
                    size={50}
                  />
                  <p className={`broadcast-message ${broadcast.docs[0].data().message.length > 84 ? 'has-read-more' : ''}`} ref={broadcastMessage}>
                    {broadcast.docs[0].data().message}
                  </p>
                  {broadcast.docs[0].data().message.length > 84 && (
                    <span className='read-more-link' onClick={e => readMore(e)}>
                      Read more
                    </span>
                  )}
                </div>
              )
            )}
          </div>
        )}
        <div className='container-20px home-container'>
          {chatsLoading ? (
            <div className='loading-spinner-blue-container'>
              <LoadingSpinnerBlue />
            </div>
          ) : (
            <>
              <div className='icon-heading'>
                <h2 style={{ color: 'var(--orange)' }}>
                  <ChatIcon colour={'var(--orange'} /> Chats
                </h2>
                <Link to={'chats'} style={{ color: 'var(--orange)' }}>
                  View all <RightArrow colour={'var(--orange)'} />
                </Link>
              </div>
              <div className='box-component yellow'>
                {chatsLoading ? (
                  <div className='loading-spinner-blue-container'>
                    <LoadingSpinnerBlue />
                  </div>
                ) : (
                  <div className='chats-list'>
                    {homeChats.map(chat => (
                      <ChatListItem key={chat?.id} chat={chat} clickHandler={() => navigate(`chats/${chat?.id}`)} />
                    ))}
                  </div>
                )}
              </div>
            </>
          )}
          {eventsError && (
            <div className='loading-spinner-blue-container'>
              <CloseIcon />
            </div>
          )}
          {eventsLoading ? (
            <div className='loading-spinner-blue-container'>
              <LoadingSpinnerBlue />
            </div>
          ) : (
            events && (
              <>
                <div className='icon-heading'>
                  <h2 style={{ color: 'var(--green)' }}>
                    <CalendarIcon colour={'var(--green'} /> Upcoming events
                  </h2>
                </div>
                <div className='event-tile-container'>
                  <div className='event-tile-spacer'></div>
                  {events.docs.map(event => (
                    <EventTile event={event.data()} key={event.id} clickEvent={() => setOpenEvent(event.data())} />
                  ))}
                  <div className='event-tile-spacer'></div>
                </div>
              </>
            )
          )}
        </div>
      </div>
      {openEvent && <OpenEvent event={openEvent} closeEvent={closeEvent} />}
    </>
  )
}

export default Home
