import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDocument } from 'react-firebase-hooks/firestore'
import { doc } from 'firebase/firestore'

import { useAuth } from './../../components/auth/AuthContext'
import { db } from './../../firebase'

import LoadingSpinnerBlue from './../../components/ui/icons/LoadingSpinnerBlue'
import Tick from './../../components/ui/icons/Tick'

const CreateAccountSuccess = () => {
  // main auth context
  const { user, authLoading, refreshToken } = useAuth()
  // track database changes to listen for verification
  const [userData, loading, error] = useDocument(doc(db, 'users', user.uid))
  // setup navigation to redirect if user becomes verified
  let navigate = useNavigate()

  useEffect(() => {
    if (userData?.data()) {
      // async function for checking role claims
      async function checkRole() {
        try {
          const updatedToken = await refreshToken()
          if (updatedToken?.claims?.role !== 'unverified') {
            navigate('/')
          }
        } catch (error) {
          console.error(error)
        }
      }
      // check that we're working with an unverified user
      if (userData.data()?.verified === true) {
        checkRole()
      }
    }
  }, [userData, navigate])

  useEffect(() => {
    if (error) {
      console.error(error)
    }
  }, [error])

  return (
    <>
      <div className='glow-element-logo success-tick'>
        <Tick />
        <Tick />
      </div>
      {authLoading || loading ? (
        <div className='loading-spinner-blue-container'>
          <LoadingSpinnerBlue />
        </div>
      ) : (
        <>
          <h1 className='text-center success-heading'>Success!</h1>
          <p className='text-center'>
            <strong>What happens next?</strong>
            <br></br>
            <br></br>
            Please allow up to a day for us to verify your membership. We’ll email you as soon as your account has been activated.
            <br></br>
            <br></br>
          </p>
        </>
      )}
    </>
  )
}

export default CreateAccountSuccess
