import { useAuth } from './../../components/auth/AuthContext'
import { useChats } from './../../components/auth/ChatContext'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState, useMemo, useRef } from 'react'
import { db } from './../../firebase'
import { collection, query, where, addDoc, setDoc, doc, serverTimestamp } from 'firebase/firestore'
import { useCollectionOnce } from 'react-firebase-hooks/firestore'
import { useHttpsCallable } from 'react-firebase-hooks/functions'
import { getFunctions } from 'firebase/functions'
import app from './../../firebase'
import { getStorage, ref, getDownloadURL } from 'firebase/storage'
import Compressor from 'compressorjs'

import { sortBy, swearjar, getAge } from './../../components/utilities'

import HeadingBar from './../../components/ui/HeadingBar'
import LeftArrow from './../../components/ui/icons/LeftArrow'
import CloseIcon from './../../components/ui/icons/CloseIcon'
import SearchBar from './../../components/ui/SearchBar'
import UserListItem from './../../components/ui/UserListItem'
import GroupChatIcon from '../../components/ui/icons/GroupChatIcon'
import Button from './../../components/ui/Button'
import CameraIcon from './../../components/ui/icons/CameraIcon'
import LoadingSpinner from './../../components/ui/icons/LoadingSpinner'
import LoadingSpinnerBlue from './../../components/ui/icons/LoadingSpinnerBlue'
import NoticeOverlay from './../../components/ui/NoticeOverlay'

const Contacts = () => {
  const { user, role, userData, refreshToken, age } = useAuth()
  // const { onlineStatus, onlineStatusLoading } = useChats() // disabled because not working well
  const navigate = useNavigate()

  // check we know the age and update the token if not
  useEffect(() => {
    if (!age) {
      refreshToken()
    }
    if (age && getAge(age) < 12) {
      navigate('/chats')
    }
  })

  const contactsQuery = useMemo(() => {
    // calculate eligable range
    let youngestRange = new Date(age + 94670856000)
    let now = new Date()
    let youngestMin = new Date(Number(now - 347126186000))
    if (youngestRange > youngestMin) {
      youngestRange = youngestMin
    }
    let oldestRange = new Date(age - 94670856000)
    if (role === 'admin' || role === 'mentor') {
      youngestRange = new Date(4112375918000)
      oldestRange = new Date(-1252372882000)
    }
    // get age appropriate youth members
    let contactsQuery = query(collection(db, 'users'), where('DOB', '>=', oldestRange), where('DOB', '<=', youngestRange), where('role', '==', 'youth'))
    return contactsQuery
  }, [age, role])

  const [contacts, contactsLoading, contactsError] = useCollectionOnce(contactsQuery)
  // get admins and mentors
  let adminsAndMentorsQuery = query(collection(db, 'users'), where('role', 'in', ['admin', 'mentor']))
  const [mentorContacts, mentorContactsLoading, mentorContactsError] = useCollectionOnce(adminsAndMentorsQuery)
  // create state and set it with useEffect
  const [contactsList, setContactsList] = useState([])

  // remove self from list and merge id into data objects
  useEffect(() => {
    if (!contactsLoading && contacts && !mentorContactsLoading && mentorContacts) {
      // merge both queries
      let combinedContacts = [...contacts.docs, ...mentorContacts.docs]
      let filteredContacts = combinedContacts.flatMap(contact => {
        // remove current user from contacts list
        if (contact.id !== user.uid && !contact.data()?.hasBlocked.includes(user.uid) && !userData?.hasBlocked.includes(contact.id)) {
          // get online status if available // disabled because not working well
          // let isOnline = false
          // let hasStatus = onlineStatus.docs.filter(contactStatus => contactStatus.id === contact.id)
          // if (hasStatus.length > 0) {
          //   isOnline = hasStatus[0].data().online
          // }
          let contactData = contact.data()
          return { ...contactData, id: contact.id }
        } else {
          return []
        }
      })
      sortBy(filteredContacts, 'firstName')
      setContactsList(filteredContacts)
    }
  }, [contacts, contactsLoading, mentorContacts, mentorContactsLoading])

  // error logging
  useEffect(() => {
    if (contactsError) {
      console.error(contactsError)
    }
    if (mentorContactsError) {
      console.error(mentorContactsError)
    }
  }, [contactsError, mentorContactsError])

  // search for contacts by firstName or lastName
  const [searchResults, setSearchResults] = useState([])
  const [isSearch, setIsSearch] = useState(false)
  const searchContacts = searchTerm => {
    if (searchTerm && searchTerm !== '') {
      let newSearchResults = contactsList.filter(contact => {
        if (contact.firstName.includes(searchTerm) || contact.lastName.includes(searchTerm)) {
          return true
        }
        return false
      })
      setSearchResults(newSearchResults)
      setIsSearch(true)
    } else {
      setSearchResults([])
      setIsSearch(false)
    }
  }

  // state for group chats
  const [createGroup, setCreateGroup] = useState(false)
  const [createGroupStage, setCreateGroupStage] = useState(1)
  const [newGroupContacts, setNewGroupContacts] = useState([])
  // form for new group
  const [formErrors, setFormErrors] = useState([])
  const [newGroupDetails, setNewGroupDetails] = useState({
    name: '',
    colour: 'orange-pink',
    profilePic: '',
  })

  // close notice function, removes from formErrors array
  const closeNotice = e => {
    let key = Number(e.currentTarget.dataset.key)
    let currentErrors = formErrors.filter((el, index) => {
      if (index !== key) {
        return el
      }
      return null
    })
    setFormErrors(currentErrors)
  }

  // reset create group stage on cancel
  useEffect(() => {
    if (!createGroup) {
      setCreateGroupStage(1)
    }
  }, [createGroup])

  // manage the list of group chats
  const manageGroupList = contact => {
    if (newGroupContacts.includes(contact)) {
      // remove from array with a splice
      let existingContacts = newGroupContacts
      existingContacts.splice(existingContacts.indexOf(contact), 1)
      setNewGroupContacts([...existingContacts])
    } else {
      setNewGroupContacts(existingContacts => [...existingContacts, contact])
    }
  }

  // use compressorjs
  const compressImage = file => {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.4,
        convertSize: 3000,
        width: 600,
        height: 600,
        resize: 'cover',
        success: resolve,
        error: reject,
      })
    })
  }

  // create base64 image
  const getBase64 = file => {
    return new Promise(function (resolve, reject) {
      var reader = new FileReader()
      reader.onload = function () {
        resolve(reader.result.split(',')[1])
      }
      reader.onerror = reject
      reader.readAsDataURL(file)
    })
  }

  const changePic = async e => {
    setFormErrors([])
    if (!e.target.files[0]) {
      return
    }
    let newPic = e.target.files[0]
    // check file
    if (!['image/jpeg', 'image/png'].includes(newPic.type)) {
      setFormErrors([
        {
          colour: 'red',
          message: `This file isn't supported, try a jpeg?`,
        },
      ])
      return
    }
    // compress image
    const compressedImage = await compressImage(newPic)
    const imageUrl = URL.createObjectURL(compressedImage)

    // convert to base64
    const imageBase64 = await getBase64(compressedImage)

    // add file and path to state
    setNewGroupDetails({
      ...newGroupDetails,
      profilePicBase64: imageBase64,
      profilePic: imageUrl,
      profilePicPath: `groups/${newChatRef.current.id}.jpg`,
    })
  }

  // setup storage
  const storage = getStorage()
  // set up callable functions
  const [excecuteSaveImage, executing, imageError] = useHttpsCallable(getFunctions(app, 'europe-west2'), 'saveImage')

  useEffect(() => {
    if (imageError) {
      setFormErrors(existing => [
        ...existing,
        {
          colour: 'yellow',
          message: `Sorry, something has gone wrong uploading your group profile image.`,
        },
      ])
      console.error(imageError)
    }
  }, [imageError])

  // take the name of group chats
  const handleGroupForm = e => {
    if (formErrors.length > 0) {
      setFormErrors([])
    }
    let name = e.target.name
    let value = e.target.value
    if (value.length > 22) {
      setFormErrors([
        {
          colour: 'red',
          message: 'This is the maximum group name length',
        },
      ])
    } else {
      setNewGroupDetails({ ...newGroupDetails, [name]: value })
    }
  }

  const [newChatLoading, setNewChatLoading] = useState(false)

  const newChatRef = useRef(doc(collection(db, 'chats')))

  const startChat = async contact => {
    // if we're making a group
    if (createGroup) {
      // show notification if the group name input is empty
      if (!newGroupDetails.name) {
        setFormErrors([
          {
            colour: 'red',
            message: 'Please name your new group',
          },
        ])
        return
      }
      if (swearjar.profane(newGroupDetails.name) || ['LHM Kidz', 'Juniors', 'Seniors'].includes(newGroupDetails.name)) {
        setFormErrors([
          {
            colour: 'red',
            message: 'This group name is not allowed',
          },
        ])
        return
      }
      // shows spinner on button
      setNewChatLoading(true)
      // create array of reduced 'users' to add to members array
      let reducedContactObject = {}
      newGroupContacts.forEach(contact => {
        reducedContactObject[contact.id] = {
          name: `${contact.firstName} ${contact.lastName}`,
          memberSince: serverTimestamp(),
          colour: contact.colour,
          profilePic: contact?.profilePic || '',
        }
      })
      // add current user to members array
      reducedContactObject[user.uid] = {
        name: `${userData.firstName} ${userData.lastName}`,
        memberSince: serverTimestamp(),
        colour: userData.colour,
        profilePic: userData?.profilePic || '',
      }
      // handle profile picture
      let profilePic = newGroupDetails.profilePic || ''
      if (newGroupDetails.profilePicBase64) {
        try {
          const saveImage = await excecuteSaveImage({
            imageBase64: newGroupDetails.profilePicBase64,
            imagePath: newGroupDetails.profilePicPath,
            userData: { userFirstName: userData.firstName, userLastName: userData.lastName, userAge: getAge(age), userUid: user.uid },
            fromChat: false,
          })
          if (saveImage.data.message) {
            // get url of new file
            profilePic = await getDownloadURL(ref(storage, newGroupDetails.profilePicPath))
          }
          if (saveImage.data.error === 'image flagged') {
            // put image back to what it was before
            profilePic = newGroupDetails.profilePic || ''
            setFormErrors([
              {
                colour: 'red',
                message: `Sorry, your new profile picture isn't allowed. Try a different image.`,
              },
            ])
            setNewGroupDetails({
              ...newGroupDetails,
              profilePicBase64: null,
              profilePic: '',
              profilePicPath: '',
            })
          }
        } catch (error) {
          console.error(error)
        }
      }
      // create chat in database
      const chatDocRef = await setDoc(newChatRef.current, {
        name: newGroupDetails.name,
        type: 'group',
        members: reducedContactObject,
        profilePic: profilePic || '',
        colour: newGroupDetails?.colour || 'orange-pink',
        lastMessage: {
          message: `New group started by ${userData.firstName} ${userData.lastName}`,
          timestamp: serverTimestamp(),
          readBy: [user.uid],
        },
        createdOn: serverTimestamp(),
        ownedBy: user.uid,
      })
      // add first message (a system message)
      await addDoc(collection(db, 'chats', newChatRef.current.id, 'messages'), {
        type: 'system',
        from: '',
        sentOn: serverTimestamp(),
        content: `New group started by ${userData.firstName} ${userData.lastName}`,
      })
      // open the new chat
      navigate(`/chats/${newChatRef.current.id}`)
    } else {
      // this is a single, non-group chat
      // navigate to profile
      navigate(`/profile/${contact.id}`, { state: { fromAdmin: true } })
    }
  }

  return (
    <div className='lhm-body contacts-body' style={{ paddingBottom: createGroup && newGroupContacts.length > 0 ? '150px' : '0' }}>
      <HeadingBar
        heading={createGroup ? 'Create group' : 'New chat'}
        iconLeft={createGroup ? <CloseIcon /> : <LeftArrow />}
        iconLeftClick={createGroup ? () => setCreateGroup(!createGroup) : () => navigate('/chats')}
      />
      {formErrors && <NoticeOverlay notices={formErrors} closeFunction={closeNotice} belowTopBar={true} />}
      {contactsLoading ? (
        <div className='loading-spinner-blue-container'>
          <LoadingSpinnerBlue />
        </div>
      ) : createGroupStage === 1 ? (
        <>
          <div className='container-20px'>
            <SearchBar searchFunction={searchContacts} />
            <div className='create-group' onClick={() => setCreateGroup(!createGroup)}>
              {createGroup ? (
                <strong>Select contacts for your new group</strong>
              ) : (
                <>
                  <GroupChatIcon /> Create group
                </>
              )}
            </div>
          </div>
          {searchResults.length > 0 &&
            isSearch &&
            searchResults.map(contact => (
              <UserListItem
                key={contact.id}
                profilePic={contact?.profilePic}
                firstName={contact.firstName}
                lastName={contact.lastName}
                colour={contact.colour}
                online={contact.online}
                belowName={contact.role === 'admin' ? 'Youth Leader' : contact.role === 'mentor' && 'Mentor'}
                size={50}
                selectable={createGroup}
                selected={newGroupContacts.includes(contact)}
                onSelect={() => manageGroupList(contact)}
                clickHandler={() => startChat(contact)}
              />
            ))}
          {searchResults.length === 0 && isSearch && (
            <div className='container-20px'>
              <p style={{ paddingTop: '20px' }}>No users match your search.</p>
            </div>
          )}
          {contactsList &&
            !isSearch &&
            contactsList.map(contact => (
              <UserListItem
                key={contact.id}
                profilePic={contact?.profilePic}
                firstName={contact.firstName}
                lastName={contact.lastName}
                colour={contact.colour}
                online={contact.online}
                belowName={contact.role === 'admin' ? 'Youth Leader' : contact.role === 'mentor' && 'Mentor'}
                size={50}
                selectable={createGroup}
                selected={newGroupContacts.includes(contact)}
                onSelect={() => manageGroupList(contact)}
                clickHandler={() => startChat(contact)}
              />
            ))}
        </>
      ) : (
        <div className='container-20px' style={{ marginTop: '20px' }}>
          <form className='smaller-inputs'>
            <p>
              <strong>Give your group a name</strong>
            </p>
            <input placeholder='New group' type='text' name='name' onChange={handleGroupForm} value={newGroupDetails.name} autoComplete='off' />
            <p>
              <strong>Choose a group colour</strong>
            </p>
            <style>{`\
              .swatch.current::before{\
                background-image:${newGroupDetails.profilePic ? `url("${newGroupDetails.profilePic}")` : 'white'};\
              }\
            `}</style>
            <div className='swatch-container'>
              <div className={`swatch orange-pink ${newGroupDetails.colour === 'orange-pink' && 'current'}`} onClick={() => setNewGroupDetails({ ...newGroupDetails, colour: 'orange-pink' })}></div>
              <div className={`swatch green-blue ${newGroupDetails.colour === 'green-blue' && 'current'}`} onClick={() => setNewGroupDetails({ ...newGroupDetails, colour: 'green-blue' })}></div>
              <div className={`swatch blue-pink ${newGroupDetails.colour === 'blue-pink' && 'current'}`} onClick={() => setNewGroupDetails({ ...newGroupDetails, colour: 'blue-pink' })}></div>
              <div className={`swatch pink-green ${newGroupDetails.colour === 'pink-green' && 'current'}`} onClick={() => setNewGroupDetails({ ...newGroupDetails, colour: 'pink-green' })}></div>
              <div className={`swatch night-blue ${newGroupDetails.colour === 'night-blue' && 'current'}`} onClick={() => setNewGroupDetails({ ...newGroupDetails, colour: 'night-blue' })}></div>
            </div>
            <p>
              <strong>Add a group photo (optional)</strong>
            </p>
            <label htmlFor='profile-pic' className='profile-pic-upload'>
              <CameraIcon />
              <input onChange={e => changePic(e)} type='file' name='profile-pic' id='profile-pic' accept='.jpg,.jpeg,.png,.gif' />
            </label>
          </form>
        </div>
      )}
      {createGroup && newGroupContacts.length > 0 && (
        <div className='container-20px'>
          <div>
            <Button
              action={createGroupStage === 1 ? () => setCreateGroupStage(2) : () => startChat()}
              fixed={true}
              colour={newChatLoading ? 'loading blue' : 'blue'}
              text={createGroupStage === 1 ? 'Next' : 'Create group'}
              icon={newChatLoading && <LoadingSpinner />}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default Contacts
