import { createContext, useContext, useState, useEffect } from 'react'
import { auth } from './../../firebase'
import { db } from './../../firebase'
import { doc, getDoc, updateDoc, serverTimestamp, addDoc, collection } from 'firebase/firestore'
import { sendPasswordResetEmail, updatePassword, onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth'
import { FirebaseAuthentication } from '@capacitor-firebase/authentication'

import { getAge } from './../utilities'

const AuthContext = createContext()

export function AuthProvider({ children }) {
  // global user
  const [role, setRole] = useState(null)
  const [age, setAge] = useState(null)
  const [userData, setUserData] = useState(null)
  const [userDataLoading, setUserDataLoading] = useState(true)

  // main auth variables
  const [user, setUser] = useState()
  const [authLoading, setAuthLoading] = useState(true)

  useEffect(() => {
    let SDKUser = null
    let nativeUser = null

    const checkAndSetUser = async () => {
      if (SDKUser && nativeUser) {
        // Both auth states have returned a user, proceed to set user, role, and age
        setUser(SDKUser)
        const idTokenResult = await SDKUser.getIdTokenResult(true)
        setRole(idTokenResult.claims?.role)
        setAge(idTokenResult.claims?.age)
        setAuthLoading(false)
      } else if (SDKUser === false) {
        // No user found in SDK, set authLoading to false
        setAuthLoading(false)
      }
    }

    const unsubscribe = onAuthStateChanged(auth, async user => {
      if (user) {
        SDKUser = user
      } else {
        setUser(null)
        setRole(null)
        setAge(null)
        SDKUser = false
      }
      await checkAndSetUser()
    })

    FirebaseAuthentication.addListener('authStateChange', async user => {
      if (user.user) {
        nativeUser = user.user
      } else {
        setUser(null)
        setRole(null)
        setAge(null)
        nativeUser = false
      }
      await checkAndSetUser()
    })

    return unsubscribe
  }, [])

  // refresh userData function
  const getUserFromQuery = async query => {
    setUserDataLoading(true)
    try {
      const updateUserData = await getDoc(query)
      if (updateUserData.exists()) {
        setUserData(updateUserData.data())
        setUserDataLoading(false)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const refreshUserData = () => {
    let query = doc(db, 'users', user.uid)
    getUserFromQuery(query)
  }

  const manuallyRefreshUserData = newData => {
    setUserData({ ...userData, ...newData })
  }

  // add user to new community
  const addToCommunity = async correctCommunity => {
    setUserDataLoading(true)
    // if user is too old, send a notification to admins
    if (correctCommunity === 'too old') {
      // send admin notification
      await addDoc(collection(db, 'adminNotifications'), {
        dismissed: false,
        type: 'Member too old',
        userId: user.uid,
        message: `Active youth member ${userData.firstName} is older than 17`,
        byUser: `${userData.firstName} ${userData.lastName}`,
        byUserAge: getAge(age),
        timestamp: serverTimestamp(),
      })
      await updateDoc(doc(db, 'users', user.uid), {
        overEighteen: true,
      })
      let query = doc(db, 'users', user.uid)
      const updateUserData = await getDoc(query)
      setUserData(updateUserData.data())
      setUserDataLoading(false)
      return
    }

    // add to userData
    await updateDoc(doc(db, 'users', user.uid), {
      [`communities.${correctCommunity}.memberSince`]: serverTimestamp(),
    })
    // send join message
    let newMessage = {
      content: `${userData.firstName} ${userData.lastName} has joined the community – say Hi!`,
      type: 'system',
      from: '',
      sentOn: serverTimestamp(),
    }
    await addDoc(collection(db, 'communityChats', correctCommunity, 'messages'), newMessage)
    // update lastMessage on chat
    await updateDoc(doc(db, 'communityChats', correctCommunity), {
      lastMessage: {
        message: newMessage.content,
        from: ``,
        timestamp: serverTimestamp(),
        readBy: [user.uid],
      },
    })
    let query = doc(db, 'users', user.uid)
    const updateUserData = await getDoc(query)
    setUserData(updateUserData.data())
    setUserDataLoading(false)
  }

  // handle changes to user or role
  useEffect(() => {
    const handleUserChanges = async () => {
      if (user && user.role !== 'unverified') {
        let query = doc(db, 'users', user.uid)
        getUserFromQuery(query)
      } else {
        setUserDataLoading(false)
      }
    }
    handleUserChanges()
  }, [user, role])

  const refreshToken = async () => {
    const idTokenResult = await user.getIdTokenResult(true)
    setRole(idTokenResult.claims?.role)
    setAge(idTokenResult.claims?.age)
    return idTokenResult
  }

  const resetPassword = async email => {
    try {
      const resetResponse = await sendPasswordResetEmail(auth, email)
      return resetResponse
    } catch (error) {
      return error.message
    }
  }

  const changePassword = async password => {
    try {
      const response = await updatePassword(user, password)
      return response
    } catch (error) {
      return error
    }
  }

  // recreating the original react-firebase-hooks with our own version using state
  const [signInLoading, setSignInLoading] = useState(false)
  const [signInError, setSignInError] = useState(null)
  const signIn = async (email, password) => {
    setSignInLoading(true)
    try {
      await FirebaseAuthentication.signInWithEmailAndPassword({
        email: email,
        password: password,
      })

      const webLayerLogin = await signInWithEmailAndPassword(auth, email, password)
      setSignInLoading(false)
      return webLayerLogin
    } catch (error) {
      setSignInLoading(false)
      setSignInError(error.message)
    }
  }

  // handle in-app notifications

  const [toast, setToast] = useState(null)

  const setToastMessage = message => {
    setToast(message)
    setTimeout(() => {
      setToast(null)
    }, 4000)
  }

  const value = {
    user,
    role,
    age,
    userData,
    userDataLoading,
    authLoading,
    addToCommunity,
    refreshToken,
    refreshUserData,
    manuallyRefreshUserData,
    signIn,
    resetPassword,
    changePassword,
    signInLoading,
    signInError,
    setToastMessage,
    toast,
  }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export function useAuth() {
  return useContext(AuthContext)
}
