const ToggleSwitch = ({ toggleState, onChange, text }) => {
  return (
    <div className='toggle-container'>
      <p>{text}</p>
      <label className='switch'>
        <input type='checkbox' checked={toggleState} onChange={onChange} />
        <span className='slider round'></span>
      </label>
    </div>
  )
}

export default ToggleSwitch
