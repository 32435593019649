const BlockIcon = () => (
  <svg width={25} height={25} viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M20.68 17.634a.323.323 0 0 0-.444-.07.312.312 0 0 0-.06.432c.302.557.51 1.16.614 1.784.143.662.2 1.34.171 2.016v.181a2.552 2.552 0 0 1-.362-.151c-.766-.433-1.935-1.21-2.963-1.814a10.612 10.612 0 0 0-1.804-.897 1.683 1.683 0 0 0-.806 0c-.635.151-1.502.655-1.945.746a8.178 8.178 0 0 1-2.016.181 12.342 12.342 0 0 1-2.016-.242.347.347 0 1 0-.16.676c.701.196 1.42.328 2.146.393a9.069 9.069 0 0 0 2.217-.05c.393-.061 1.149-.414 1.774-.575a.806.806 0 0 1 .443-.05c.721.32 1.415.697 2.076 1.128.756.454 1.542.937 2.167 1.27.368.22.765.39 1.179.504a.746.746 0 0 0 .897-.514c.139-.471.166-.968.08-1.451a8.94 8.94 0 0 0-1.189-3.497ZM21.808 6.83a.322.322 0 0 0-.423-.11.302.302 0 0 0-.111.423 12.95 12.95 0 0 1 1.159 3.497c.15.77.19 1.557.12 2.338a5.845 5.845 0 0 1-1.592 3.517.351.351 0 0 0 0 .504.343.343 0 0 0 .484 0 6.379 6.379 0 0 0 2.137-3.316c.15-.561.215-1.142.191-1.723a7.056 7.056 0 0 0-.252-1.683 11.731 11.731 0 0 0-1.713-3.447ZM3.81 17.865c.735.525 1.108.101.493-.493A9.755 9.755 0 0 1 1.4 13.199a6.994 6.994 0 0 1 .897-6.308 9 9 0 0 1 3.457-3.084 12.627 12.627 0 0 1 4.555-1.35c2.529-.279 5.084.169 7.367 1.29.806.302 1.099-.202.585-.555a13.998 13.998 0 0 0-8.063-1.773 13.1 13.1 0 0 0-5.039 1.35 10.007 10.007 0 0 0-3.85 3.487 7.971 7.971 0 0 0-.775 7.246 10.521 10.521 0 0 0 3.275 4.363Z'
      fill='#007FAF'
    />
    <path
      d='M.282 23.56a.363.363 0 0 0 0 .493.353.353 0 0 0 .494 0c1.642-1.703 4.746-4.777 8.193-8.203l3.507-3.517 3.487-3.548c4.03-4.182 6.752-7.054 8.062-8.263a.323.323 0 0 0 0-.434.322.322 0 0 0-.443 0L15.177 8.03a289.444 289.444 0 0 0-3.517 3.517c-1.18 1.199-2.348 2.338-3.467 3.537L.282 23.559Z'
      fill='#CB0935'
    />
  </svg>
)

export default BlockIcon
