import { useEffect, useState } from 'react'
import { useAuth } from './../../components/auth/AuthContext'
import { useNavigate } from 'react-router-dom'

import { db } from './../../firebase'
import { getDoc, updateDoc, doc, arrayRemove } from 'firebase/firestore'

import HeadingBar from './../../components/ui/HeadingBar'
import LeftArrow from './../../components/ui/icons/LeftArrow'
import LoadingSpinnerBlue from './../../components/ui/icons/LoadingSpinnerBlue'

import UserListItem from './../../components/ui/UserListItem'
import ConfirmationPopup from '../../components/ui/ConfirmationPopup'

const BlockedMembers = () => {
  // auth context
  const { userData, user, refreshUserData } = useAuth()
  // use navigate
  const navigate = useNavigate()

  // list of users
  const [blockedUsers, setBlockedUsers] = useState([])

  // list of users
  const [loading, setLoading] = useState(true)

  // state for confirmation popup
  const [confirm, setConfirm] = useState(false)

  // close confirmation popup
  const closeAction = () => {
    setConfirm(false)
  }

  // get user
  useEffect(() => {
    setLoading(true)
    const getUsers = async () => {
      let users = []
      await Promise.all(
        userData.hasBlocked.map(async blockedUser => {
          const user = await getDoc(doc(db, 'users', blockedUser))
          users.push({ uid: user.id, ...user.data() })
        })
      )
      setBlockedUsers([...users])
      setLoading(false)
    }

    if (userData && userData?.hasBlocked?.length > 0) {
      getUsers()
    } else {
      setLoading(false)
    }
  }, [userData])

  // confirm unblock user
  const confirmUnblock = contact => {
    setConfirm({
      heading: `Unblock ${contact.firstName}?`,
      message: `You will both be able to start new chats with each other.`,
      actionText: 'Unblock',
      action: () => unblock(contact.uid),
      loading: false,
    })
  }

  // unblock user
  const unblock = async contact => {
    // set loading
    setConfirm(current => {
      return { ...current, loading: true }
    })
    // remove from current user's block list
    await updateDoc(doc(db, 'users', user.uid), {
      hasBlocked: arrayRemove(contact),
    })
    setConfirm(false)
    refreshUserData()
  }

  return (
    <div className='lhm-body'>
      <HeadingBar heading={`Blocked members`} iconLeft={<LeftArrow />} iconLeftClick={() => navigate(`/profile/settings`)} colour={userData.colour} />
      {loading ? (
        <div className='loading-spinner-blue-container'>
          <LoadingSpinnerBlue />
        </div>
      ) : blockedUsers.length > 0 ? (
        <>
          <div className='container-20px'>
            <p className='start-p'>Tap a user to unblock them.</p>
          </div>
          <div className='menu-container' style={{ marginTop: '30px' }}>
            {blockedUsers.map(user => (
              <UserListItem key={user.uid} firstName={user.firstName} lastName={user.lastName} colour={user.colour} size={50} clickHandler={() => confirmUnblock(user)} />
            ))}
          </div>
          {confirm && (
            <ConfirmationPopup heading={confirm.heading} message={confirm.message} actionText={confirm.actionText} action={confirm.action} loading={confirm.loading} closeAction={closeAction} />
          )}
        </>
      ) : (
        <div className='container-20px'>
          <p className='start-p'>You haven't blocked anyone.</p>
        </div>
      )}
    </div>
  )
}

export default BlockedMembers
