import './../../admin.css'
import { Outlet } from 'react-router-dom'
import Navigation from './../ui/Navigation'
import AdminNavigation from './../../routes/admin/AdminNavigation'

const MainLayout = () => {
  return (
    <>
      <AdminNavigation />
      <div className='lhm-body'>
        <Outlet />
      </div>
      <Navigation />
    </>
  )
}

export default MainLayout
