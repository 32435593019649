import { db } from './../../firebase'
import { FirebaseMessaging } from '@capacitor-firebase/messaging'
import { doc, getDoc, updateDoc, serverTimestamp, setDoc, deleteField } from 'firebase/firestore'
import { Capacitor } from '@capacitor/core'

const devVapidKey = 'BGWhNoEaIXvfBJBJc-BxWrOkJ61c5OjOshlbHBbUStQh49ZDvwY9UoUEdRS59BQglsEDhtcqwAzk4GDvZbydYls'
const prodVapidKey = 'BHn67xztTX6H6wiYZUze_Yyvb2mjMBM2t3ztA5qb2ySLjLpKVr55K1VJB4q0qILupfIDqPLIHcApJnUI8XPeOpI'

const getAndSaveToken = async (user, savedTokens, deviceType) => {
  console.log('get and save token running')
  // LAUNCH CHANGE
  const getToken = await FirebaseMessaging.getToken({ vapidKey: prodVapidKey })
  const token = getToken.token
  console.log(token)
  if (token) {
    // check for match against existing database tokens
    if (savedTokens.exists() && savedTokens.data().hasOwnProperty(deviceType)) {
      let savedTokensData = savedTokens.data()
      // check if token is the same as the saved one
      if (savedTokensData[deviceType] === token) {
        // check age of saved token
        let aMonthAgo = new Date()
        aMonthAgo.setDate(aMonthAgo.getDate() - 58)
        if (Number(aMonthAgo) > savedTokensData[deviceType].timestamp.seconds * 1000) {
          // update the timestamp since it's older than 58 days
          await updateDoc(doc(db, 'notificationsTokens', user.uid), {
            [`${deviceType}.timestamp`]: serverTimestamp(),
          })
        }
        return token
      } else {
        // update the saved token
        await updateDoc(doc(db, 'notificationsTokens', user.uid), {
          [`${deviceType}.token`]: token,
          [`${deviceType}.timestamp`]: serverTimestamp(),
        })
        return token
      }
    } else {
      // create the token
      await setDoc(
        doc(db, 'notificationsTokens', user.uid),
        {
          [`${deviceType}`]: {
            timestamp: serverTimestamp(),
            token: token,
          },
        },
        { merge: true }
      )
      return token
    }
  } else {
    return false
  }
}

const deleteToken = async (user, savedTokens, deviceType) => {
  console.log('running deleteToken')
  // delete token from database
  if (savedTokens.exists() && savedTokens.data().hasOwnProperty(deviceType)) {
    console.log(savedTokens.data())
    console.log('found something to delete')
    // stops the server sending to this token
    await updateDoc(doc(db, 'notificationsTokens', user.uid), {
      [deviceType]: deleteField(),
    })
    // check if there's a token to delete
    const getToken = await FirebaseMessaging.getToken({ vapidKey: prodVapidKey })
    const token = getToken?.token
    // delete the token
    if (token) {
      await FirebaseMessaging.deleteToken()
    }
    return
  }
}

const handleNotifications = async (user, force = '') => {
  if (!user) return false
  // check current permission status
  const checkPermissions = await FirebaseMessaging.checkPermissions()
  const permissionResult = checkPermissions.receive // 'prompt' | 'prompt-with-rationale' | 'granted' | 'denied'

  // get saved tokens from database
  const savedTokens = await getDoc(doc(db, 'notificationsTokens', user.uid))

  // get device type
  const deviceType = Capacitor.getPlatform() // 'web' | 'ios' | 'android'

  // handle denied or forceDisable scenario
  if ((permissionResult === 'denied' && force !== 'forceEnable') || force === 'forceDisable') {
    await deleteToken(user, savedTokens, deviceType)
    return false
  }

  // handle already enabled scenario
  if (permissionResult === 'granted') {
    const token = await getAndSaveToken(user, savedTokens, deviceType)
    if (deviceType === 'android') {
      await FirebaseMessaging.createChannel({
        id: 'little_hearts_matter',
        name: 'Little Hearts Matter',
        description: 'Message and event notifications from Little Hearts Matter',
        importance: 4,
        visibility: 0,
        lights: true,
        vibration: true,
      })
    }
    return { token: token }
  }

  // handle prompt scenario
  if (permissionResult === 'prompt' || permissionResult === 'prompt-with-rationale' || force === 'forceEnable') {
    // request permissions
    const requestPermissions = await FirebaseMessaging.requestPermissions()
    const requestResult = requestPermissions.receive // 'prompt' | 'prompt-with-rationale' | 'granted' | 'denied'
    if (requestResult !== 'granted') return false
    if (requestResult === 'granted') {
      const token = await getAndSaveToken(user, savedTokens, deviceType)
      return { token: token }
    }
  }
}

export { handleNotifications }
