import { Outlet, useLocation } from 'react-router-dom'
import logo from './../../images/logo-square-icon.png'

const OnBoardingLayout = () => {
  let location = useLocation()

  return (
    <div className='onboarding-container'>
      {location.pathname === '/start' && (
        <div className='logo-container'>
          <div className='glow-element-logo'>
            <img src={logo} alt='Little Hearts Matter' />
            <img src={logo} alt='Little Hearts Matter' />
          </div>
          <h1>
            Little Hearts Matter
            <br />
            Youth Hub
          </h1>
        </div>
      )}

      <div className='onboarding-content-container'>
        <div className='onboarding-content-inner'>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default OnBoardingLayout
