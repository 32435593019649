import { useState, useRef, useEffect } from 'react'
import { useAuth } from './../../components/auth/AuthContext'
import app from './../../firebase'
import { getFunctions } from 'firebase/functions'
import { useHttpsCallable } from 'react-firebase-hooks/functions'
import { getAge } from './../../components/utilities'

import Button from './../../components/ui/Button'
import NoticeOverlay from './../../components/ui/NoticeOverlay'
import Terms from './Terms'
import CreateAccountSuccess from './CreateAccountSuccess'

import logo from './../../images/logo-square-icon.png'
import RightArrow from './../../components/ui/icons/RightArrow'
import LoadingSpinner from './../../components/ui/icons/LoadingSpinner'

const CreateAccount = () => {
  const [accountStage, setAccountStage] = useState(1)
  const [initialAge, setInitialAge] = useState()
  // form errors
  const [formErrors, setFormErrors] = useState([])

  const [newAccount, setNewAccount] = useState({
    userName: '',
    userLastName: '',
    DD: '',
    MM: '',
    YYYY: '',
    DOB: '',
    parentName: '',
    parentLastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    agreeTerms: false,
  })

  // close notice function, removes from formErrors array
  const closeNotice = e => {
    let key = Number(e.currentTarget.dataset.key)
    let currentErrors = formErrors.filter((el, index) => {
      if (index !== key) {
        return el
      }
      return null
    })
    setFormErrors(currentErrors)
  }

  // setup for age selection
  const possibleAges = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]

  const startingAge = useRef(null)
  const ageScroller = useRef(null)

  const scrollToAge = e => {
    e.target.scrollIntoView({ behavior: 'smooth', inline: 'center' })
  }

  const numberSpans = possibleAges.map((age, index) => {
    let itemProps = index === Math.floor(possibleAges.length / 2) ? { ref: startingAge } : {}
    return (
      <div onClick={scrollToAge} key={age} {...itemProps} className={initialAge === age ? 'age-container current-age' : 'age-container'}>
        <span className='number'>{age}</span>
      </div>
    )
  })

  // run once age selection setup
  useEffect(() => {
    if (accountStage === 1) {
      startingAge.current.scrollIntoView({ inline: 'center' })
      const setAgeOnScroll = (entries, ageObserver) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setInitialAge(Number(entry.target.textContent))
          }
        })
      }
      const ageObserver = new IntersectionObserver(setAgeOnScroll, {
        rootMargin: '0% -50% 0% -50%',
      })

      let ageScrollerCurrent = Array.from(ageScroller.current.children)

      if (ageScrollerCurrent) {
        ageScrollerCurrent.forEach(age => {
          ageObserver.observe(age)
        })
      }

      return () => {
        if (ageScrollerCurrent) {
          ageScrollerCurrent.forEach(age => {
            ageObserver.unobserve(age)
          })
        }
      }
    }
  }, [accountStage])

  // form refs
  const form1 = useRef(null)
  const form2 = useRef(null)

  function isValidDate(s) {
    // Assumes s is "mm/dd/yyyy"
    // eslint-disable-next-line
    if (!/^\d\d\-\d\d\-\d\d\d\d$/.test(s)) {
      return false
    }
    const parts = s.split('-').map(p => parseInt(p, 10))
    parts[1] -= 1
    const d = new Date(parts[2], parts[1], parts[0])
    return d.getMonth() === parts[1] && d.getDate() === parts[0] && d.getFullYear() === parts[2]
  }

  // next buttons
  const advanceStage = () => {
    // validate state
    let valid = false
    let errors = []
    switch (accountStage) {
      case 2:
        valid = newAccount.userName && newAccount.userLastName && isValidDate(newAccount.DOB)
        if (!valid) {
          if (!newAccount.userName) {
            errors.push({
              colour: 'red',
              message: 'Please add your first name',
            })
          }
          if (!newAccount.userLastName) {
            errors.push({
              colour: 'yellow',
              message: 'Please add your last name',
            })
          }
          if (!isValidDate(newAccount.DOB)) {
            errors.push({
              colour: 'red',
              message: 'Incorrect or invalid date',
            })
          }
        }
        break
      case 3:
        if (initialAge < 15) {
          valid =
            newAccount.password &&
            newAccount.confirmPassword &&
            newAccount.agreeTerms &&
            newAccount.password === newAccount.confirmPassword &&
            newAccount.parentName &&
            newAccount.parentLastName &&
            form2.current.email.validity.valid
        } else if (initialAge > 14) {
          valid = newAccount.password && newAccount.confirmPassword && newAccount.agreeTerms && newAccount.password === newAccount.confirmPassword && form2.current.email.validity.valid
        }
        if (!valid) {
          if (!newAccount.agreeTerms) {
            errors.push({
              colour: 'red',
              message: `Please agree to the app policies`,
            })
          }
          if (!newAccount.password) {
            errors.push({
              colour: 'red',
              message: `Enter a password`,
            })
          }
          if (newAccount.password !== newAccount.confirmPassword) {
            errors.push({
              colour: 'yellow',
              message: `Passwords don't match`,
            })
          }
          if (!form2.current.email.validity.valid) {
            errors.push({
              colour: 'yellow',
              message: `Email address not valid`,
            })
          }
          if (initialAge < 15) {
            if (!newAccount.parentName || !newAccount.parentLastName) {
              errors.push({
                colour: 'red',
                message: `Provide parent's first and last name`,
              })
            }
          }
        }
        break
      default:
        valid = true
    }
    if (valid) {
      setFormErrors([])
      if (accountStage === 3) {
        clickToCreateUser()
      } else {
        setAccountStage(accountStage + 1)
      }
    } else {
      setFormErrors([...errors])
    }
  }

  // handle form input changes
  const handleInputChange = e => {
    if (formErrors.length > 0) {
      setFormErrors([])
    }
    // maintain an object for our form
    let name = e.target.name
    let value = e.target.value
    // capitalise names
    if (['userName', 'userLastName', 'parentName', 'parentLastName'].includes(name)) {
      value = value.charAt(0).toUpperCase() + value.slice(1)
    }
    // move through date inputs automatically
    if (['DD', 'MM', 'YYYY'].includes(name)) {
      if ((name === 'DD') & (value.length === 2)) {
        form1.current.children[3].children.MM.focus()
      }
      if ((name === 'MM') & (value.length === 2)) {
        form1.current.children[3].children.YYYY.focus()
      }
      if ((name === 'YYYY') & (value.length === 4)) {
        form1.current.children[3].children.YYYY.blur()
      }
    }
    setNewAccount({ ...newAccount, [name]: value })
  }

  // create date from date inputs
  useEffect(() => {
    // input date
    let updatedDOB = `${newAccount?.DD}-${newAccount?.MM}-${newAccount?.YYYY}`
    setNewAccount(previousAccount => ({ ...previousAccount, DOB: updatedDOB }))
    // verify age
    if (newAccount.DD.length === 2 && newAccount.MM.length === 2 && newAccount.YYYY.length === 4) {
      let age = getAge(`${newAccount?.YYYY}-${newAccount?.MM}-${newAccount?.DD}`)
      let errors = []
      if (age > 17 || age < 7) {
        errors.push({
          colour: 'yellow',
          message: `LHM community members should be 7-17 years old`,
        })
      }

      setInitialAge(age)

      setFormErrors(prevErrors => [...prevErrors, ...errors])
    }
  }, [newAccount.DD, newAccount.MM, newAccount.YYYY])

  // hidden checkbox click handler
  const checkboxClickHandler = e => {
    e.preventDefault()
    setNewAccount({ ...newAccount, agreeTerms: !newAccount.agreeTerms })
  }

  // setup create account
  const [executeCreateNewUser, executing, functionError] = useHttpsCallable(getFunctions(app, 'europe-west2'), 'createNewUser')
  const { signIn, signInLoading, signInError, user, authLoading } = useAuth()
  const clickToCreateUser = async () => {
    let data = {
      firstName: newAccount.userName.trim(),
      lastName: newAccount.userLastName.trim(),
      email: newAccount.email.trim(),
      DOB: `${newAccount?.YYYY}-${newAccount?.MM}-${newAccount?.DD}`,
      age: getAge(`${newAccount?.YYYY}-${newAccount?.MM}-${newAccount?.DD}`),
      parentName: newAccount?.parentName.trim(),
      parentLastName: newAccount?.parentLastName.trim(),
      dobNumber: Number(new Date(`${newAccount?.YYYY}-${newAccount?.MM}-${newAccount?.DD}`)),
      password: newAccount.password,
    }
    try {
      const response = await executeCreateNewUser(data)
      if (response.data.message === 'done') {
        await signIn(newAccount.email, newAccount.password)
        setAccountStage(4)
      } else {
        let error = response?.data?.error?.errorInfo?.code || null
        let errorMessage = null
        switch (error) {
          case 'auth/email-already-exists':
            errorMessage = {
              colour: 'red',
              message: 'Email has an existing account – ',
              link: {
                path: './login',
                text: 'log in',
              },
            }
            break
          case 'auth/invalid-password':
            errorMessage = {
              colour: 'red',
              message: 'Password too short: 6 character minimum',
            }
            break
          default:
            errorMessage = {
              colour: 'red',
              message: 'Something went wrong',
            }
        }

        setFormErrors([errorMessage])
      }
    } catch (error) {
      console.error(error)
      signInError && console.error(signInError)
      functionError && console.error(signInError)
    }
  }

  // terms and conditions window
  const [showTerms, setShowTerms] = useState(false)

  return (
    <>
      {formErrors && <NoticeOverlay notices={formErrors} closeFunction={closeNotice} />}
      {accountStage === 1 && (
        <>
          <h2>How old are you?</h2>
          <div className='age-selector'>
            <div className='age-selector-logo'>
              <img src={logo} alt='Select your age' />
            </div>
            <div className='age-scroller' ref={ageScroller}>
              <div className='age-spacer'></div>
              {numberSpans}
              <div className='age-spacer'></div>
            </div>
          </div>
          <Button action={advanceStage} colour='blue' text={'Next'} icon={<RightArrow colour='#fff' />} />
        </>
      )}
      {accountStage === 2 && (
        <>
          <h1>{initialAge < 11 ? 'Please ask a parent for help with this section.' : 'Please tell us your name and date of birth.'}</h1>
          <form className='onboarding-form' ref={form1}>
            <h3>{initialAge < 11 ? `Child's details` : 'Your details'}</h3>
            <input placeholder='First name' name='userName' type='text' autoComplete='given-name' onChange={handleInputChange} value={newAccount.userName} required />
            <input placeholder='Last name' name='userLastName' type='text' autoComplete='family-name' onChange={handleInputChange} value={newAccount.userLastName} required />
            <fieldset className='dob-input-group'>
              <legend>Date of birth</legend>
              <input placeholder='DD' name='DD' type='text' autoComplete='bday-day' pattern='[0-9]*' inputMode='numeric' onChange={handleInputChange} value={newAccount.DD} required />
              <input placeholder='MM' name='MM' type='text' autoComplete='bday-month' pattern='[0-9]*' inputMode='numeric' onChange={handleInputChange} value={newAccount.MM} required />
              <input placeholder='YYYY' name='YYYY' type='text' autoComplete='bday-year' pattern='[0-9]*' inputMode='numeric' onChange={handleInputChange} value={newAccount.YYYY} required />
            </fieldset>
          </form>
          <Button action={advanceStage} colour='blue' text={'Next'} icon={<RightArrow colour='#fff' />} />
        </>
      )}
      {accountStage === 3 && (
        <>
          <form className='onboarding-form' ref={form2}>
            {initialAge < 15 ? <h3>{initialAge < 11 ? `Parent's details` : 'Your parent'}</h3> : <h3>Create account</h3>}
            {initialAge < 15 && (
              <>
                <input
                  placeholder={initialAge < 11 ? `First name` : 'Parent first name'}
                  name='parentName'
                  type='text'
                  autoComplete='given-name'
                  onChange={handleInputChange}
                  value={newAccount.parentName}
                  required
                />
                <input
                  placeholder={initialAge < 11 ? `Last name` : 'Parent last name'}
                  name='parentLastName'
                  type='text'
                  autoComplete='family-name'
                  onChange={handleInputChange}
                  value={newAccount.parentLastName}
                  required
                />
              </>
            )}
            {initialAge < 15 && <h3>Account</h3>}
            <input placeholder={initialAge < 11 ? `Parent's email` : 'Your email'} name='email' type='email' autoComplete='email' onChange={handleInputChange} value={newAccount.email} required />
            <input placeholder='Choose a password' name='password' type='password' autoComplete='new-password' onChange={handleInputChange} value={newAccount.password} required />
            <input placeholder='Re-enter password' name='confirmPassword' type='password' autoComplete='new-password' onChange={handleInputChange} value={newAccount.confirmPassword} required />
            <div className='checkbox-container' style={{ marginTop: '-23px' }} onClick={checkboxClickHandler}>
              <div className={newAccount.agreeTerms ? 'checkbox checked' : 'checkbox'}></div>
              <p>
                I agree to the LHM Community <b onClick={() => setShowTerms(true)}>terms, conditions and privacy policies</b>.
              </p>
            </div>
          </form>
          <Button action={advanceStage} colour={executing || signInLoading ? 'loading blue' : 'blue'} text='Finish' icon={executing || signInLoading ? <LoadingSpinner /> : undefined} />
        </>
      )}
      {showTerms && <Terms goBack={() => setShowTerms(false)} />}
      {accountStage === 4 && !signInLoading && !authLoading && user && <CreateAccountSuccess />}
    </>
  )
}

export default CreateAccount
