import React from 'react'

import { BrowserRouter, Routes, Route } from 'react-router-dom'

// auth
import { AuthProvider } from './components/auth/AuthContext'
import { ChatProvider } from './components/auth/ChatContext'
import RequireAuth from './components/auth/RequireAuth'
import NoAuth from './components/auth/NoAuth'
import RequireAdmin from './components/auth/RequireAdmin'

// onboarding routes
import OnBoardingLayout from './components/layouts/OnBoardingLayout'
import CreateAccount from './routes/onboarding/CreateAccount'
import Login from './routes/onboarding/Login'
import ForgotPassword from './routes/onboarding/ForgotPassword'
import Start from './routes/onboarding/Start'

// protected routes
import MainLayout from './components/layouts/MainLayout'
import Chats from './routes/chats/Chats'
import Contacts from './routes/chats/Contacts'
import Chat from './routes/chats/Chat'
import ChatInfo from './routes/chats/ChatInfo'
import Report from './routes/chats/Report'
import EditGroup from './routes/chats/EditGroup'
import Profile from './routes/profile/Profile'
import EditProfile from './routes/profile/EditProfile'
import ContactProfile from './routes/profile/ContactProfile'
import Settings from './routes/profile/Settings'
import BlockedMembers from './routes/profile/BlockedMembers'
import Notifications from './routes/profile/Notifications'
import ChangePassword from './routes/profile/ChangePassword'
import Support from './routes/support/Support'
import Home from './routes/home/Home'

// admin routes

import AdminLayout from './components/layouts/AdminLayout'
import Admin from './routes/admin/Admin'
import ManageAllUsers from './routes/admin/ManageAllUsers'
import ManageSingleUser from './routes/admin/ManageSingleUser'
import AdminNotifications from './routes/admin/AdminNotifications'
import AdminReports from './routes/admin/AdminReports'
import AdminEvents from './routes/admin/AdminEvents'
import AdminEventsCreate from './routes/admin/AdminEventsCreate'
import AllChats from './routes/admin/AllChats'

// 404 not found
import NoMatch from './components/auth/NoMatch'

// smoothscroll polyfill
import smoothscroll from 'smoothscroll-polyfill'

const App = () => {
  smoothscroll.polyfill()
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route element={<NoAuth />}>
            <Route element={<OnBoardingLayout />}>
              <Route path='/login' element={<Login />} />
              <Route path='/start' element={<Start />} />
              <Route path='/reset-password' element={<ForgotPassword />} />
            </Route>
          </Route>
          <Route element={<OnBoardingLayout />}>
            <Route path='/create-account' element={<CreateAccount />} />
          </Route>
          <Route element={<RequireAuth />}>
            <Route element={<ChatProvider />}>
              <Route element={<MainLayout />}>
                <Route exact path='/' element={<Home />} />
                <Route path='/chats' element={<Chats />}>
                  <Route path='new' element={<Contacts />} />
                  <Route path=':id' element={<Chat />}>
                    <Route path='info' element={<ChatInfo />} />
                    <Route path='report' element={<Report />} />
                    <Route path='edit-group' element={<EditGroup />} />
                  </Route>
                </Route>
                <Route path='/profile' element={<Profile />}>
                  <Route path='edit' element={<EditProfile />} />
                  <Route path='settings' element={<Settings />}>
                    <Route path='blocked-members' element={<BlockedMembers />} />
                    <Route path='notifications' element={<Notifications />} />
                    <Route path='change-password' element={<ChangePassword />} />
                  </Route>
                </Route>
                <Route path='/profile/:id' element={<ContactProfile />} />
                <Route path='/support' element={<Support />} />
              </Route>
              <Route element={<RequireAdmin />}>
                <Route element={<AdminLayout />}>
                  <Route exact path='/admin' element={<Admin />} />
                  <Route path='/admin/manage-users' element={<ManageAllUsers />} />
                  <Route path='/admin/manage-users/:uid' element={<ManageSingleUser />} />
                  <Route exact path='/admin/notifications' element={<AdminNotifications />} />
                  <Route exact path='/admin/reports' element={<AdminReports />} />
                  <Route exact path='/admin/events' element={<AdminEvents />} />
                  <Route exact path='/admin/events/create' element={<AdminEventsCreate />} />
                  <Route exact path='/admin/events/create/:id' element={<AdminEventsCreate />} />
                  <Route exact path='/admin/all-chats' element={<AllChats />} />
                </Route>
              </Route>
            </Route>
          </Route>
          <Route path='*' element={<NoMatch />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  )
}

export default App
