const LeftArrow = ({ colour }) => (
  <svg width={30} height={24} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1.597 12.802a32.223 32.223 0 0 0 3.385 3.783c2.905 2.893 6.5 5.938 7.343 6.184.426.146.876.206 1.324.176.33-.033.648-.145.925-.328a3.2 3.2 0 0 0 .808-.762c.253-.295.444-.637.563-1.007a2.822 2.822 0 0 0-.13-1.722 3.959 3.959 0 0 0-.89-1.37c-.843-.82-1.428-1.534-2.225-2.342l-.41-.399c1.172 0 6.231.07 10.19 0a31.751 31.751 0 0 0 4.532-.35 2.61 2.61 0 0 0 2.097-3.163 2.916 2.916 0 0 0-2.73-2.553h-3.196c-3.631.105-8.948.34-11.35.316h-.058c.82-.797 2.108-1.968 2.53-2.39a5.073 5.073 0 0 0 1.815-3.443 2.764 2.764 0 0 0-1.64-2.202 2.342 2.342 0 0 0-2.026 0 13.023 13.023 0 0 0-3.279 2.343C6.833 5.646 4.151 7.94 2.535 9.51A5.376 5.376 0 0 0 1 11.408c.05.516.258 1.003.597 1.394Zm2.26-3.514C5.51 7.813 7.794 5.95 9.715 4.228a13.14 13.14 0 0 1 2.576-1.862 1.804 1.804 0 0 1 1.64-.257c.96.562 1.171 1.171 1.054 1.839a5.06 5.06 0 0 1-1.452 2.178c-.492.48-2.436 2.155-3.162 2.928-.155.15-.286.325-.387.515a.586.586 0 0 0 .152.691c.125.113.28.186.445.211.505.065 1.014.096 1.523.094 3.514.059 11.97-.305 14.195-.223a1.57 1.57 0 0 1 1.44 1.417 1.312 1.312 0 0 1-.972 1.546 30.454 30.454 0 0 1-4.286.34c-4.193.164-9.65.164-10.295.176h-.972a6.522 6.522 0 0 0-.656 0 .598.598 0 0 0-.41.398.562.562 0 0 0 .21.68c.645.503 1.172.948 1.582 1.37.796.784 1.405 1.487 2.26 2.342.283.27.503.597.645.96.148.33.189.7.117 1.054a2.12 2.12 0 0 1-.352.621 2.932 2.932 0 0 1-.55.539c-.18.121-.392.186-.61.187a2.66 2.66 0 0 1-.784-.128c-.796-.235-4.228-3.128-7.027-5.856A31.988 31.988 0 0 1 2.3 12.357c-.281-.387-.48-.656-.457-.832-.047-.398.832-1.183 2.015-2.248v.011Z'
      fill={colour ? colour : '#535353'}
      stroke={colour ? colour : '#535353'}
      strokeWidth={0.2}
    />
  </svg>
)

export default LeftArrow
