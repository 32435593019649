import { useLocation, Outlet, Navigate, useNavigate } from 'react-router-dom'
import { useAuth } from './AuthContext'
import Loading from '../ui/Loading'
import Unverified from '../../routes/onboarding/Unverified'

import { handleNotifications } from './../../components/auth/handleNotifications'

import { useEffect } from 'react'

import { FirebaseMessaging } from '@capacitor-firebase/messaging'
import { Haptics } from '@capacitor/haptics'

const ReqiuireAuth = () => {
  const { user, role, authLoading, userData, userDataLoading, setToastMessage } = useAuth()
  let location = useLocation()

  // use navigate
  const navigate = useNavigate()

  // go to chat
  const goToChat = chatId => {
    navigate(`./chats`)
    setTimeout(() => navigate(`./chats/${chatId}`), 10)
  }

  // go to home
  const goToHome = () => {
    navigate(`./`)
  }

  // listen to service worker messages
  useEffect(() => {
    const listenToNotifications = async () => {
      if (user && userData && role && role !== 'unverified') {
        const result = await handleNotifications(user)
        if (result.token) {
          // listen for notifications – app in foreground behaviour
          await FirebaseMessaging.addListener('notificationReceived', event => {
            console.log('notification received', event)
            let chatId = event.notification.data.chatId
            // if the chat for this notification is not open, show a toast and vibrate
            if (!location.pathname.includes(chatId) && location.pathname !== '/chats') {
              console.log('condition 1 true')
              if (event.notification.title) {
                console.log('condition 2 true')
                setToastMessage({
                  title: event.notification.title,
                  message: event.notification.body,
                  link: event.notification.data.chatId,
                })
              } else if (event.notification.data.title) {
                console.log('condition 3 true')
                setToastMessage({
                  title: event.notification.data.title,
                  message: event.notification.data.body,
                  link: event.notification.data.chatId,
                })
              }
              Haptics.vibrate()
            }
            // if viewing all chats, vibrate
            if (location.pathname === '/chats') {
              Haptics.vibrate()
            }
          })
          await FirebaseMessaging.addListener('notificationActionPerformed', async event => {
            if (event.notification.data.chatId === 'home') return goToHome()
            goToChat(event.notification.data.chatId)
          })
        }
      }
    }

    listenToNotifications()

    return async () => {
      await FirebaseMessaging.removeAllListeners()
    }
  }, [user, userData, role])

  return authLoading || userDataLoading ? <Loading /> : user && userData && role ? role === 'unverified' ? <Unverified /> : <Outlet /> : <Navigate to='/start' state={{ from: location }} replace />
}

export default ReqiuireAuth
