import { useEffect } from 'react'
import { useAuth } from '../../components/auth/AuthContext'
import { db } from '../../firebase'
import { doc } from 'firebase/firestore'
import { useDocument } from 'react-firebase-hooks/firestore'

import logo from './../../images/logo-square-icon.png'

const Unverified = () => {
  // import auth context
  const { refreshToken, authLoading, user } = useAuth()
  // listen for changes to user verification
  const [value, error] = useDocument(doc(db, 'users', user.uid))

  useEffect(() => {
    if (value) {
      if (value.data().verified) {
        refreshToken(user)
      }
    }
    if (error) {
      console.error(error)
    }
  }, [value, error, authLoading, user, refreshToken])

  return (
    <div className='onboarding-container'>
      <div className='logo-container'>
        <div className='glow-element-logo'>
          <img src={logo} alt='Little Hearts Matter' />
          <img src={logo} alt='Little Hearts Matter' />
        </div>
        Little Hearts Matter
        <br />
        Youth Hub
      </div>

      <div className='onboarding-content-container'>
        <div className='onboarding-content-inner'>
          <h1 className='orange-heading text-center'>Come back soon</h1>
          <p className='start-p'>
            <strong>Your account is still being verified.</strong> We'll email you when your login is ready to use.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Unverified
