// required packages
import { getApp, initializeApp } from 'firebase/app'
import { getAuth, initializeAuth, indexedDBLocalPersistence } from 'firebase/auth'
import { initializeFirestore, persistentLocalCache, persistentSingleTabManager } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getDatabase } from 'firebase/database'
import { getAnalytics } from 'firebase/analytics'
import { Capacitor } from '@capacitor/core'

// init Firebase
const firebaseConfigDev = {
  apiKey: 'AIzaSyBWvO4lFsBN6a4vr0LzfkXAYzM4w8rhb-U',
  authDomain: 'little-hearts-matter-dev.firebaseapp.com',
  projectId: 'little-hearts-matter-dev',
  databaseURL: 'https://little-hearts-matter-dev-default-rtdb.europe-west1.firebasedatabase.app/',
  storageBucket: 'little-hearts-matter-dev.appspot.com',
  messagingSenderId: '928448678732',
  appId: '1:928448678732:web:e30940c530620c9f5e9de9',
  measurementId: 'G-CX9KP8GSGV',
}

const firebaseConfig = {
  apiKey: 'AIzaSyBXyxwj1AQa-d-a175q5Xlvy9Ax8kTMVmc',
  authDomain: 'little-hearts-matter.firebaseapp.com',
  databaseURL: 'https://little-hearts-matter-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'little-hearts-matter',
  storageBucket: 'little-hearts-matter.appspot.com',
  messagingSenderId: '928874791947',
  appId: '1:928874791947:web:db4f1805e29fcf3988730e',
  measurementId: 'G-QXXCX98BWF',
}

const getFirebaseAuth = async () => {
  if (Capacitor.isNativePlatform()) {
    return initializeAuth(getApp(), {
      persistence: indexedDBLocalPersistence,
    })
  } else {
    return getAuth()
  }
}

// LAUNCH CHANGE
const app = initializeApp(firebaseConfig)

let awaitAuth = await getFirebaseAuth()

export const auth = awaitAuth
// With persistentLocalCache
// TODO what is the difference bettwen getFirestore and initializeFirestore
const db = initializeFirestore(app, {
  localCache: persistentLocalCache({ tabManager: persistentSingleTabManager({}) }),
})
export const storage = getStorage(app)
export const realtimeDatabase = getDatabase(app)
export const analytics = getAnalytics(app)

export { db }
export default app
