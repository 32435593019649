import { useParams } from 'react-router-dom'
import { useChats } from './../../components/auth/ChatContext'
import { useState, useEffect } from 'react'

import { Outlet } from 'react-router-dom'
import { Capacitor } from '@capacitor/core'

import ChatWindow from './ChatWindow'
import ChatInput from './ChatInput'
import { useAuth } from './../../components/auth/AuthContext'

import { db } from './../../firebase'
import { doc, getDoc } from 'firebase/firestore'

const Chat = () => {
  // get auth context
  const { role } = useAuth()
  // access chat id
  const params = useParams()
  // get chat context
  const { chats } = useChats()
  // set current chat as state
  const [currentChat, setCurrentChat] = useState()
  const [windowHeight, setWindowHeight] = useState()

  // match the current chat from the chat context
  useEffect(() => {
    const findChat = async () => {
      await chats
      let theChat = chats.find(chat => chat.id === params.id)
      if (!theChat && role === 'admin') {
        const docSnap = await getDoc(doc(db, 'chats', params.id))
        theChat = docSnap.data()
        if (docSnap.exists()) {
          theChat.specialAdminView = true
          setWindowHeight('100vh')
        }
      }
      setCurrentChat(theChat)
    }
    findChat()
  }, [chats, params])

  const handleSetWindowHeight = messageBoxHeight => {
    const deviceType = Capacitor.getPlatform()
    let adjustment = deviceType === 'ios' ? -34 : 34
    currentChat?.specialAdminView || !currentChat || isTimeout ? setWindowHeight(`100%`) : setWindowHeight(`calc(100% - ${messageBoxHeight + adjustment}px)`)
  }

  // state for timeouts in community chats
  const [isTimeout, setIsTimeout] = useState(false)

  // toggle timeout status
  const toggleIsTimeout = value => {
    setIsTimeout(value)
  }

  return (
    <>
      <div className='lhm-body single-chat-container'>
        <ChatWindow id={params.id} chatData={currentChat} height={windowHeight} toggleIsTimeout={toggleIsTimeout} />
        {currentChat && !currentChat.specialAdminView && (
          <ChatInput id={params.id} chatType={currentChat.type} chatName={currentChat.name} setWindowHeight={handleSetWindowHeight} isTimeout={isTimeout} />
        )}
      </div>
      {currentChat && <Outlet context={{ currentChat }} />}
    </>
  )
}

export default Chat
