import ProfilePic from './ProfilePic'

const UserListItem = ({
  profilePic,
  firstName,
  lastName,
  belowName,
  colour,
  online,
  me,
  clickHandler,
  noPadding,
  size,
  selectable,
  selected,
  onSelect,
}) => {
  const conditionalClick = () => {
    if (selectable) {
      onSelect()
    } else {
      clickHandler()
    }
  }
  return (
    <div className='user-list-item' onClick={() => conditionalClick()} style={noPadding && { padding: '10px 0' }}>
      <ProfilePic firstName={firstName} image={profilePic} colour={colour} online={online} size={size ? size : '50'} />
      <div>
        <p>{me ? 'You' : `${firstName ? firstName : ''} ${lastName ? lastName : ''}`}</p>
        <p>{belowName}</p>
      </div>
      {selectable && (
        <div className='select-user'>
          <div className='checkbox-container green'>
            <div className={selected ? 'checkbox checked' : 'checkbox'}></div>
          </div>
        </div>
      )}
    </div>
  )
}

export default UserListItem
