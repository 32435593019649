import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from './../../components/auth/AuthContext'
import { swearjar } from './../../components/utilities'
import Compressor from 'compressorjs'
import { getFunctions } from 'firebase/functions'
import app from './../../firebase'
import { useHttpsCallable } from 'react-firebase-hooks/functions'
import { getAge } from './../../components/utilities'
import { getStorage, ref, getDownloadURL } from 'firebase/storage'
import { db } from './../../firebase'
import { doc, updateDoc } from 'firebase/firestore'

import NoticeOverlay from './../../components/ui/NoticeOverlay'
import HeadingBar from '../../components/ui/HeadingBar'
import UserListItem from './../../components/ui/UserListItem'
import Button from './../../components/ui/Button'

import LeftArrow from './../../components/ui/icons/LeftArrow'
import RightArrow from './../../components/ui/icons/RightArrow'
import CameraIcon from './../../components/ui/icons/CameraIcon'
import LoadingSpinner from './../../components/ui/icons/LoadingSpinner'
import { useEffect } from 'react'

const EditProfile = () => {
  // get current user
  const { user, userData, role, age, manuallyRefreshUserData } = useAuth()

  // setup storage
  const storage = getStorage()

  // pronouns, bio, colour
  const [newSettings, setNewSettings] = useState({
    colour: userData.colour || '',
    pronouns: userData.pronouns || '',
    bio: userData.bio || '',
    profilePic: userData.profilePic || '',
    profilePicBlob: undefined,
  })

  // setup errors
  const [profileErrors, setProfileErrors] = useState([])

  // setup loading
  const [loading, setLoading] = useState(false)

  // close errors function
  const closeNotice = e => {
    let key = Number(e.currentTarget.dataset.key)
    let currentErrors = profileErrors.filter((el, index) => {
      if (index !== key) {
        return el
      }
      return null
    })
    setProfileErrors(currentErrors)
  }

  // set up callable functions
  const [excecuteSaveImage, executing, error] = useHttpsCallable(getFunctions(app, 'europe-west2'), 'saveImage')

  // navigate back
  const navigate = useNavigate()

  const setColour = colour => {
    setNewSettings({ ...newSettings, colour: colour })
    setProfileErrors([])
  }

  const changePronouns = e => {
    setNewSettings({ ...newSettings, pronouns: e.target.value })
    setProfileErrors([])
  }

  const changeBio = e => {
    setNewSettings({ ...newSettings, bio: e.target.value })
    setProfileErrors([])
  }

  // use compressorjs
  const compressImage = file => {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.4,
        convertSize: 3000,
        width: 600,
        height: 600,
        resize: 'cover',
        success: resolve,
        error: reject,
      })
    })
  }

  // create base64 image
  const getBase64 = file => {
    return new Promise(function (resolve, reject) {
      var reader = new FileReader()
      reader.onload = function () {
        resolve(reader.result.split(',')[1])
      }
      reader.onerror = reject
      reader.readAsDataURL(file)
    })
  }

  useEffect(() => {
    if (error) {
      setProfileErrors(existing => [
        ...existing,
        {
          colour: 'yellow',
          message: `Sorry, something has gone wrong.`,
        },
      ])
      console.error(error)
    }
  }, [error])

  const changePic = async e => {
    setProfileErrors([])
    if (!e.target.files[0]) {
      return
    }
    let newPic = e.target.files[0]
    // check file
    if (!['image/jpeg', 'image/png'].includes(newPic.type)) {
      setProfileErrors([
        {
          colour: 'red',
          message: `This file isn't supported, try a jpeg?`,
        },
      ])
      return
    }
    // compress image
    const compressedImage = await compressImage(newPic)
    const imageUrl = URL.createObjectURL(compressedImage)

    // convert to base64
    const imageBase64 = await getBase64(compressedImage)

    // add file and path to state
    setNewSettings({ ...newSettings, profilePicBase64: imageBase64, profilePic: imageUrl, profilePicPath: `${user.uid}/profile.jpg` })
  }

  const saveProfile = async () => {
    setLoading(true)
    // check bio length
    if (newSettings.bio.length > 1800) {
      setProfileErrors([
        {
          colour: 'yellow',
          message: `Your bio is too long, please make it shorter by ${newSettings.bio.length - 1800} characters`,
        },
      ])
      return
    }
    // check profanity in pronouns and bio
    if (swearjar.profane(newSettings.bio) || swearjar.profane(newSettings.pronouns)) {
      setProfileErrors([
        {
          colour: 'red',
          message: `We can't allow anything rude in bios or pronouns`,
        },
      ])
      return
    }
    // save file if there is a new file
    let profilePic = userData.profilePic || ''
    if (newSettings.profilePicBase64) {
      try {
        const saveImage = await excecuteSaveImage({
          imageBase64: newSettings.profilePicBase64,
          imagePath: newSettings.profilePicPath,
          userData: { userFirstName: userData.firstName, userLastName: userData.lastName, userAge: getAge(age), userUid: user.uid },
          fromChat: false,
        })
        if (saveImage.data.message) {
          // get url of new file
          profilePic = await getDownloadURL(ref(storage, newSettings.profilePicPath))
        }
        if (saveImage.data.error === 'image flagged') {
          // put image back to what it was before
          profilePic = userData.profilePic || ''
          setProfileErrors([
            {
              colour: 'red',
              message: `Sorry, your new profile picture isn't allowed. Try a different image.`,
            },
          ])
          setNewSettings({
            ...newSettings,
            profilePicBase64: null,
            profilePic: userData.profilePic || '',
            profilePicPath: null,
          })
        }
      } catch (error) {
        console.error(error)
      }
    }

    // save to database
    let newUserData = {
      profilePic: profilePic,
      colour: newSettings.colour,
      pronouns: newSettings.pronouns,
      bio: newSettings.bio,
    }
    await updateDoc(doc(db, 'users', user.uid), newUserData)

    manuallyRefreshUserData(newUserData)
    setLoading(false)
    setProfileErrors(existing => [
      ...existing,
      {
        colour: 'blue',
        message: `Profile updated!`,
      },
    ])
  }

  return (
    <>
      <div className='lhm-body edit-profile'>
        {profileErrors && <NoticeOverlay notices={profileErrors} belowTopBar={true} closeFunction={closeNotice} />}
        <HeadingBar heading='Edit your profile' iconLeft={<LeftArrow />} iconLeftClick={() => navigate('/profile')} />
        <div className={`${loading ? 'loading' : ''} container-20px`} style={{ marginTop: '20px' }}>
          <UserListItem
            firstName={userData.firstName}
            lastName={userData.lastName}
            belowName={'Profile preview'}
            profilePic={newSettings.profilePic ? newSettings.profilePic : undefined}
            colour={newSettings.colour}
            size={50}
          />
          <h3>{newSettings?.profilePic !== '' ? 'Change' : 'Add'} profile picture</h3>
          <label htmlFor='profile-pic' className='profile-pic-upload'>
            <CameraIcon />
            <input onChange={e => changePic(e)} type='file' name='profile-pic' id='profile-pic' accept='.jpg,.jpeg,.png,.gif' />
          </label>

          {role === 'youth' && (
            <>
              <h3>Change colour</h3>
              <div className='swatch-container'>
                <div
                  className={`swatch sky-blue ${newSettings.colour === 'sky-blue' && 'current'}`}
                  onClick={() => setColour('sky-blue')}
                ></div>
                <div className={`swatch green ${newSettings.colour === 'green' && 'current'}`} onClick={() => setColour('green')}></div>
                <div className={`swatch pink ${newSettings.colour === 'pink' && 'current'}`} onClick={() => setColour('pink')}></div>
                <div className={`swatch orange ${newSettings.colour === 'orange' && 'current'}`} onClick={() => setColour('orange')}></div>
                <div className={`swatch yellow ${newSettings.colour === 'yellow' && 'current'}`} onClick={() => setColour('yellow')}></div>
              </div>
              <div className='swatch-container'>
                <div className={`swatch purple ${newSettings.colour === 'purple' && 'current'}`} onClick={() => setColour('purple')}></div>
                <div className={`swatch kermit ${newSettings.colour === 'kermit' && 'current'}`} onClick={() => setColour('kermit')}></div>
                <div className={`swatch peach ${newSettings.colour === 'peach' && 'current'}`} onClick={() => setColour('peach')}></div>
                <div className={`swatch lilac ${newSettings.colour === 'lilac' && 'current'}`} onClick={() => setColour('lilac')}></div>
                <div
                  className={`swatch pastel-green ${newSettings.colour === 'pastel-green' && 'current'}`}
                  onClick={() => setColour('pastel-green')}
                ></div>
              </div>
            </>
          )}
          <h3>Choose pronouns</h3>
          <input
            placeholder='Enter your gender pronouns'
            className='small-text-input'
            name='pronouns'
            type='text'
            onChange={e => changePronouns(e)}
            value={newSettings.pronouns}
          />
          <h3>Bio</h3>
          <textarea
            className='bio-text-input'
            autoComplete='off'
            autoCorrect='on'
            spellCheck='true'
            aria-multiline='true'
            aria-label='Bio'
            onInput={e => changeBio(e)}
            value={newSettings.bio}
          ></textarea>
          <Button
            action={() => saveProfile()}
            colour={loading || executing ? 'loading blue' : 'blue'}
            text={'Save'}
            fixed={true}
            icon={loading || executing ? <LoadingSpinner /> : <RightArrow colour={'#fff'} />}
          />
        </div>
      </div>
    </>
  )
}

export default EditProfile
