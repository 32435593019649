const ChangePasswordIcon = () => (
  <svg width={28} height={25} viewBox='0 0 28 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.647 16.281c-.513-.093-1.003-.385-1.166-.326v-.548c-.058-.572-.07-1.33-.093-2.158v-1.236c0-.653-.094-1.294-.105-1.877-.012-.583 0-1.866 0-2.437a32.99 32.99 0 0 1 5.83-.175c.84 0 1.691.128 2.472.221a13.35 13.35 0 0 1 2.227.42c.286.83.443 1.7.467 2.577 0 .665.07 1.388.07 2.134a.34.34 0 0 0 .573.272.35.35 0 0 0 .115-.237c.183-1.47.207-2.956.07-4.43a3.032 3.032 0 0 0-.292-1.05.898.898 0 0 0-.466-.409l-.793-.233a7.102 7.102 0 0 0-1.434-4.384A4.28 4.28 0 0 0 6.67.959a4.548 4.548 0 0 0-2.81 1.003 5.014 5.014 0 0 0-1.528 2.495 4.151 4.151 0 0 0-.186.98 6.507 6.507 0 0 0 0 .956c.007.12.007.24 0 .361a9.222 9.222 0 0 0-1.306.268C.746 7 .315 6.79 0 11.85v1.458c.039.653.117 1.303.233 1.947.061.52.24 1.018.525 1.458a.91.91 0 0 0 .548.245c.418.058.838.093 1.26.105a.396.396 0 0 0 .081-.782Zm.257-9.947c.006-.282.038-.563.093-.84.049-.267.127-.53.233-.78a4.186 4.186 0 0 1 1.434-1.831 3.428 3.428 0 0 1 2.1-.618 3.183 3.183 0 0 1 2.938 1.492 6.81 6.81 0 0 1 .991 2.846 18.077 18.077 0 0 0-2.53-.28c-1.75-.05-3.5.04-5.236.268a1.738 1.738 0 0 1-.023-.257Z'
      fill='var(--blue)'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.294 12.468c.043-.336.176-.654.385-.921.138-.171.308-.314.501-.42a.642.642 0 0 1 .432-.163c1.352-.059.874.525 1.352.56a.34.34 0 0 0 .362-.327.886.886 0 0 0-.315-.758 3.207 3.207 0 0 0-1.236-.711 1.854 1.854 0 0 0-1.656.56 2.589 2.589 0 0 0-.606 2.18.397.397 0 1 0 .781 0ZM27.567 15.115a.829.829 0 0 0-.7-.396c-.327 0-1.003-.059-1.924-.059-5.12-.035-17.946.292-18.74.362a.397.397 0 0 0-.196.698.384.384 0 0 0 .29.083c.805-.082 13.55-.082 18.657.07l1.586.07c.125 1.053.183 2.112.175 3.172 0 3.965-.175 3.242-1.166 3.37-2.6.326-8.337.665-12.932.7a29.686 29.686 0 0 1-6.052-.315c-.151 0-.245-.187-.338-.385a6.436 6.436 0 0 1-.443-1.878 24.114 24.114 0 0 1 0-4.372.362.362 0 0 0-.303-.397.338.338 0 0 0-.467.28 24.802 24.802 0 0 0 0 5.306c.175 1.166.63 2.099 1.236 2.332 1.726.536 7.498.665 12.536.501a73.829 73.829 0 0 0 7.51-.524c.312-.037.618-.115.909-.234a.747.747 0 0 0 .362-.35c.33-1.059.471-2.168.42-3.276.06-1.597-.081-3.196-.42-4.758Z'
      fill='var(--blue)'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.86 18.135a.442.442 0 0 0 .104-.175s-.303-.676-.77-.291c-.163.151-.524.443-.932.851a5.959 5.959 0 0 0-1.4-.956.35.35 0 0 0-.478.093.327.327 0 0 0 .093.478c.31.402.594.823.852 1.26l.081.093c-.356.55-.66 1.131-.91 1.738a.326.326 0 0 0 .094.478.35.35 0 0 0 .478-.094c.257-.28.723-.653 1.166-1.166.443.432.84.793 1.073 1.038a.385.385 0 0 0 .537.093.408.408 0 0 0 .104-.548c-.174-.326-.443-.863-.816-1.434a1.724 1.724 0 0 1-.116-.152c.245-.384.443-.746.594-.99l.245-.316ZM17.27 18.054c.05-.043.087-.1.105-.164 0 0-.338-.664-.782-.28-.163.152-.524.444-.92.852a5.96 5.96 0 0 0-1.4-.956.351.351 0 0 0-.533.056.35.35 0 0 0 .148.515c.233.233.502.723.875 1.236l.07.105a11.077 11.077 0 0 0-.91 1.726.338.338 0 0 0 .094.478.326.326 0 0 0 .478-.093c.256-.269.723-.642 1.166-1.108.431.431.828.793 1.06 1.026a.396.396 0 0 0 .549.105.408.408 0 0 0 .093-.548 14.51 14.51 0 0 0-.816-1.434l-.117-.152c.257-.385.443-.746.595-.991l.245-.373ZM21.351 21.097c.355.008.71-.016 1.061-.07.476-.098.96-.152 1.446-.163a.397.397 0 1 0 .268-.735 4.666 4.666 0 0 0-1.702-.338 6.634 6.634 0 0 0-1.353.07c-.536.082-1.05.245-1.539.338a.352.352 0 0 0-.38.196.35.35 0 0 0 .38.492c.6.115 1.208.185 1.82.21Z'
      fill='var(--red)'
    />
  </svg>
)

export default ChangePasswordIcon
