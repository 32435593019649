import { useState, useEffect } from 'react'
import { useChats } from './../../components/auth/ChatContext'

import { useNavigate, useLocation, Outlet } from 'react-router-dom'
import { getAge } from './../../components/utilities'
import { useAuth } from './../../components/auth/AuthContext'

import HeadingBar from './../../components/ui/HeadingBar'
import SearchBar from './../../components/ui/SearchBar'
import EditIcon from './../../components/ui/icons/EditIcon'
import ChatListItem from './ChatListItem'
import LoadingSpinnerBlue from './../../components/ui/icons/LoadingSpinnerBlue'

const Chats = () => {
  // bring in the chats
  const { chats, chatsLoading } = useChats()

  // use auth
  const { age } = useAuth()

  // setup navigation for chat
  const navigate = useNavigate()
  let location = useLocation()

  // set open chat based on url
  useEffect(() => {
    if (location.pathname !== '/chats') {
      const chatId = location.pathname.split('/').pop()
      setOpenChat(chatId)
    } else {
      setOpenChat()
    }
  }, [location.pathname])

  // search for chats by name (user for 1-1 chats, group name or community name)
  const [searchResults, setSearchResults] = useState([])
  const [isSearch, setIsSearch] = useState(false)
  const searchChats = searchTerm => {
    if (searchTerm && searchTerm !== '') {
      let newSearchResults = chats.filter(chat => {
        if (chat.name.includes(searchTerm)) {
          return true
        }
        return false
      })
      setSearchResults(newSearchResults)
      setIsSearch(true)
    } else {
      setSearchResults([])
      setIsSearch(false)
    }
  }

  const [openChat, setOpenChat] = useState()

  return (
    <>
      <div className='lhm-body chats-main'>
        <HeadingBar heading='Chats' iconRight={age && getAge(age) > 11 ? <EditIcon /> : undefined} iconRightClick={age && getAge(age) > 11 ? () => navigate('new') : undefined} />
        <div className='container-20px'>
          <SearchBar searchFunction={searchChats} />
        </div>
        {chatsLoading && (
          <div className='loading-spinner-blue-container'>
            <LoadingSpinnerBlue />
          </div>
        )}
        {searchResults.length > 0 && isSearch && (
          <div className='chats-list'>
            {searchResults.map(chat => (
              <ChatListItem
                key={chat.id}
                chat={chat}
                current={openChat === chat.id ? true : false}
                clickHandler={() => {
                  navigate(`/chats`)
                  setTimeout(() => navigate(`${chat.id}`), 10)
                }}
              />
            ))}
          </div>
        )}
        {searchResults.length === 0 && isSearch && (
          <div className='container-20px'>
            <p>No results – try searching for a name, group or community.</p>
          </div>
        )}
        {chats.length > 0 && !isSearch && (
          <div className='chats-list'>
            {chats.map(chat => (
              <ChatListItem
                key={chat.id}
                chat={chat}
                current={openChat === chat.id ? true : false}
                clickHandler={() => {
                  navigate(`/chats`)
                  setTimeout(() => navigate(`${chat.id}`), 10)
                }}
              />
            ))}
          </div>
        )}
      </div>
      <Outlet />
    </>
  )
}

export default Chats
