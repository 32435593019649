import { db } from './../../firebase'
import { doc, getDoc, collection, serverTimestamp, addDoc } from 'firebase/firestore'
import { useDocumentOnce } from 'react-firebase-hooks/firestore'

import { useAuth } from './../../components/auth/AuthContext'
import { useChats } from './../../components/auth/ChatContext'
import { useNavigate } from 'react-router-dom'

import { getAge } from './../../components/utilities'

import HeadingBar from './../../components/ui/HeadingBar'
import UserListItem from './../../components/ui/UserListItem'
import LoadingSpinnerBlue from './../../components/ui/icons/LoadingSpinnerBlue'
import RightArrow from './../../components/ui/icons/RightArrow'

import newsImage from './../../images/support-tiles/news.jpg'
import aboutImage from './../../images/support-tiles/about-your-heart.jpg'
import downloadsImage from './../../images/support-tiles/downloads.jpg'
import lifestyleImage from './../../images/support-tiles/lifestyle-info.jpg'
import kidzImage from './../../images/support-tiles/kidz-zone.jpg'

const Support = () => {
  // use auth
  const { user, userData, role, age } = useAuth()

  // use chats
  const { chats } = useChats()

  // nagiate
  const navigate = useNavigate()

  // get Lexie
  // dev id: 4qRaCLPrb7cU3qBjNkL3cpU6QQt1
  // prod id: d9pcdqLh3we048rErvBGgCklqif2
  // LAUNCH CHANGE
  const [lexie, loading] = useDocumentOnce(doc(db, 'users', 'd9pcdqLh3we048rErvBGgCklqif2'))

  // chat with
  // go to chat with user, or start one
  const chatWith = async () => {
    // this is a single, non-group chat
    // first, check for an existing chat between these two users
    if (chats && chats.length > 0) {
      let existingChat = chats.find(chat => {
        return chat.type === 'single' && chat.members[lexie.id] && chat.members[user.uid]
      })
      if (existingChat) {
        return navigate(`/chats/${existingChat.id}`)
      }
    }
    // get user
    const getContact = await getDoc(doc(db, 'users', lexie.id))
    let contact = { ...getContact.data(), id: getContact.id }
    // create single chat members object
    let members = {
      [user.uid]: {
        name: `${userData.firstName} ${userData.lastName}`,
        profilePic: userData?.profilePic || '',
        colour: userData.colour,
        memberSince: serverTimestamp(),
      },
      [contact.id]: {
        name: `${contact.firstName} ${contact.lastName}`,
        profilePic: contact?.profilePic || '',
        colour: contact.colour,
        memberSince: serverTimestamp(),
      },
    }
    if (contact.role === 'admin' || contact.role === 'mentor') {
      members[contact.id].role = contact.role
    }
    if (role === 'admin' || role === 'mentor') {
      members[user.uid].role = role
    }
    const chatDocRef = await addDoc(collection(db, 'chats'), {
      type: 'single',
      members: members,
      lastMessage: {
        message: `New chat started by ${userData.firstName} ${userData.lastName}`,
        timestamp: serverTimestamp(),
        readBy: [user.uid],
      },
      createdOn: serverTimestamp(),
    })
    await addDoc(collection(db, 'chats', chatDocRef.id, 'messages'), {
      type: 'system',
      from: '',
      sentOn: serverTimestamp(),
      content: `New chat started by ${userData.firstName} ${userData.lastName}`,
    })
    navigate(`/chats/${chatDocRef.id}`)
  }

  return (
    <div className='lhm-body support-container'>
      <HeadingBar heading='Support' />
      <div className='container-20px' style={{ marginTop: '30px' }}>
        <h3>Are you having a problem on the app, in a chat, or with another member?</h3>
        <p>Talk with our Youth Leader, Lexie, or submit a report from any chat.</p>
        {loading ? (
          <div className='loading-spinner-blue-container' style={{ height: '76.5px' }}>
            <LoadingSpinnerBlue />
          </div>
        ) : (
          <UserListItem
            key={lexie.id}
            firstName={lexie.data().firstName}
            lastName={lexie.data().lastName}
            belowName={
              <>
                Open Chat&nbsp;&nbsp;
                <RightArrow colour={'var(--body-black)'} />
              </>
            }
            colour={lexie.data().colour}
            profilePic={lexie.data().profilePic}
            clickHandler={() => chatWith()}
            size={50}
          />
        )}
        <div className='support-tiles'>
          {(role === 'admin' || role === 'mentor' || (getAge(age) >= 7 && getAge(age) <= 11)) && (
            <a href='https://www.lhm.org.uk/kidz/' rel='noreferrer' target='_blank'>
              <div className='glow-element-logo'>
                <img src={kidzImage} width='900' height='900' alt='About your heart' />
                <img src={kidzImage} width='900' height='900' alt='About your heart' />
              </div>
            </a>
          )}
          {(role === 'admin' || role === 'mentor' || getAge(age) > 11) && (
            <>
              <a href='https://www.lhm.org.uk/about-your-heart/' rel='noreferrer' target='_blank'>
                <div className='glow-element-logo'>
                  <img src={aboutImage} width='900' height='900' alt='About your heart' />
                  <img src={aboutImage} width='900' height='900' alt='About your heart' />
                </div>
              </a>
              <a href='https://www.lhm.org.uk/youth-lifestyle-information/' rel='noreferrer' target='_blank'>
                <div className='glow-element-logo'>
                  <img src={lifestyleImage} width='900' height='900' alt='Lifestyle info' />
                  <img src={lifestyleImage} width='900' height='900' alt='Lifestyle info' />
                </div>
              </a>
              <a href='https://www.lhm.org.uk/youth-zone-downloads/' rel='noreferrer' target='_blank'>
                <div className='glow-element-logo'>
                  <img src={downloadsImage} width='900' height='900' alt='Downloads' />
                  <img src={downloadsImage} width='900' height='900' alt='Downloads' />
                </div>
              </a>
              <a href='https://www.lhm.org.uk/youth-zone-news/' rel='noreferrer' target='_blank'>
                <div className='glow-element-logo'>
                  <img src={newsImage} width='900' height='900' alt='News' />
                  <img src={newsImage} width='900' height='900' alt='News' />
                </div>
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Support
