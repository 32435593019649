import { useState, useEffect } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import HeadingBar from './../../components/ui/HeadingBar'
import { db } from '../../firebase'
import { collection, addDoc, serverTimestamp } from 'firebase/firestore'
import { useAuth } from './../../components/auth/AuthContext'
import { getAge } from './../../components/utilities'

import LeftArrow from './../../components/ui/icons/LeftArrow'
import Button from './../../components/ui/Button'
import LoadingSpinner from './../../components/ui/icons/LoadingSpinner'
import AlertPopup from './../../components/ui/AlertPopup'

const Report = () => {
  // get user
  const { user, userData, age } = useAuth()
  // get chatData from parent
  const { currentChat } = useOutletContext()
  // navigate
  const navigate = useNavigate()
  // naming convention
  const [namingConvention, setNamingConvention] = useState()
  useEffect(() => {
    if (currentChat) {
      setNamingConvention(`${currentChat.type === 'community' ? 'Community' : currentChat.type === 'group' ? 'Group' : 'Chat'}`)
    }
  }, [currentChat])
  // report input
  const backToChats = () => {
    navigate('/chats')
  }
  // handle report value
  const [report, setReport] = useState('')
  const [sending, setSending] = useState(false)
  const [alert, setAlert] = useState()
  const handleInput = e => {
    setReport(e.target.value)
  }
  // save to database
  const submitReport = async () => {
    setSending(true)
    let newReport = {
      byUser: user.uid,
      byUserName: `${userData.firstName} ${userData.lastName}`,
      byUserAge: getAge(age),
      aboutChat: currentChat.id,
      chatName: currentChat.name,
      chatType: currentChat.type,
      message: report,
      resolved: false,
      timestamp: serverTimestamp(),
    }
    await addDoc(collection(db, 'adminReports'), newReport)
    setAlert({
      heading: 'Thank you',
      message: `We'll message you when we've looked into your report.`,
      buttonText: 'OK',
      closeAction: backToChats,
    })
    setSending(false)
  }

  return (
    <>
      <div className='lhm-body single-chat-container'>
        <HeadingBar
          heading={`Report this ${namingConvention && namingConvention.toLowerCase()}`}
          iconLeft={<LeftArrow />}
          iconLeftClick={() => navigate(`/chats/${currentChat.id}/info`)}
          colour={currentChat && currentChat.colour}
        />
        <div className='container-20px' style={{ marginTop: '30px' }}>
          <h1 className='welcome-message'>We're here to help, {userData.firstName}</h1>
          <h3>Tell us what's wrong</h3>
          <form>
            <textarea
              className={`report-text-input ${sending ? 'sending' : ''}`}
              autoComplete='off'
              autoCorrect='on'
              spellCheck='true'
              readOnly={sending}
              aria-multiline='true'
              aria-label='Message'
              onInput={handleInput}
              value={report}
              tabIndex='-1'
            ></textarea>
          </form>
        </div>
        <div className='container-20px'>
          <div>
            <Button
              action={() => submitReport()}
              colour={sending ? 'loading red' : 'red'}
              text={`Report ${namingConvention && namingConvention.toLowerCase()}`}
              icon={sending && <LoadingSpinner />}
            />
          </div>
        </div>
      </div>
      {alert && (
        <AlertPopup heading={alert.heading} message={alert.message} buttonText={alert.buttonText} closeAction={alert.closeAction} />
      )}
    </>
  )
}

export default Report
