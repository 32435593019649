import { calculateMessageTime } from './../../components/utilities'
import ProfilePic from './../../components/ui/ProfilePic'

const ChatListItem = ({ chat, current, clickHandler }) => {
  return (
    <div onClick={clickHandler} className={`chat-list-item ${current ? 'open-chat' : ''}`} data-chat={`${chat.id}`}>
      {!chat.lastMessage.read && <div className={`unread-dot ${chat.colour}`}></div>}
      <ProfilePic image={chat.image} colour={chat.colour} online={chat.online} size={52} firstName={chat.name} />
      <div className='chat-list-text-container'>
        <p>
          <span className='chat-list-name'>{chat.name}</span>
          <span className='chat-subtitle'>{chat.subtitle && `${chat.subtitle}`}</span> <span className='message-timestamp'>{calculateMessageTime(chat.lastMessage.timestamp)}</span>
        </p>
        <p>
          {chat.type !== 'single' && chat?.lastMessage?.from && <i>{chat?.lastMessage?.from}: </i>}
          {chat?.lastMessage?.message?.length > 63 ? `${chat?.lastMessage?.message.substring(0, 63)}...` : chat?.lastMessage?.message}
        </p>
      </div>
    </div>
  )
}

export default ChatListItem
