import LinkButton from '../../components/ui/LinkButton'
import RightArrow from '../../components/ui/icons/RightArrow'
import { useLocation } from 'react-router-dom'

const Start = () => {
  // get location state and pass as a prop
  const location = useLocation()

  return (
    <>
      <p className='start-p'>
        <strong>Hello, and welcome to our community.</strong> Have you used this app before?
      </p>
      <LinkButton url='/create-account' colour='blue' text='Get started' icon={<RightArrow colour='#fff' />} />
      <LinkButton url='/login' colour='blue-outline' text='Log in' locationState={location.state} />
    </>
  )
}

export default Start
