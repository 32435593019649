// calculate age from DOB in DD-MM-YYYY
const getAge = dateString => {
  var today = new Date()
  var birthDate = new Date(dateString)
  if (birthDate) {
    var age = today.getFullYear() - birthDate.getFullYear()
    var m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--
    }
    return age
  }
}

const secondsSinceBirthday = dateString => {
  const today = new Date()
  const birthDate = new Date(dateString)
  // calculate last birthday
  birthDate.setFullYear(today.getFullYear())
  if (today < birthDate) {
    birthDate.setFullYear(today.getFullYear() - 1)
  }
  return Number(today - birthDate) / 1000
}

// get birthday in a nice format
const getBirthday = epochAge => {
  let dob = new Date(epochAge)
  let date = dob.getDate()
  let month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][dob.getMonth()]
  return `${date} ${month}`
}

// get date in short format
const getShortDate = dateTime => {
  let jsDate = new Date(dateTime)
  let date = jsDate.getDate()
  let month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][jsDate.getMonth()]
  return `${date} ${month}`
}

// get birthday in a nice format
const getTime = dateTime => {
  let jsDate = new Date(dateTime)
  let hours = jsDate.getHours()
  let minutes = jsDate.getMinutes()
  let suffix = hours > 11 ? 'pm' : 'am'
  hours = hours > 13 ? hours - 12 : hours
  minutes = minutes.toString().length < 2 ? ('0' + minutes).slice(-2) : minutes
  return `${hours}:${minutes}${suffix}`
}

// put array into alphabetical order
const sortBy = (array, key) => {
  // sort by name
  return array.sort(function (a, b) {
    const nameA = a[key].toUpperCase() // ignore upper and lowercase
    const nameB = b[key].toUpperCase() // ignore upper and lowercase
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }

    // names must be equal
    return 0
  })
}

// capitalise first letter
const capitaliseFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

// return a date for recent messages
const calculateMessageTime = timestamp => {
  timestamp = timestamp * 1000
  let timestampDate = new Date(timestamp)
  let now = new Date()
  // calculate difference in milliseconds
  let timeAgo = Number(now) - Number(timestamp)
  // if within the last 5 minutes (Now)
  if (timeAgo < 300000) {
    return 'Now'
  }
  // if today (14:35)
  if (timestampDate.getDate() === now.getDate() && timestampDate.getMonth() === now.getMonth() && timestampDate.getFullYear() === now.getFullYear()) {
    return `${timestampDate.getHours().toString().padStart(2, '0')}:${timestampDate.getMinutes().toString().padStart(2, '0')}`
  }
  // if yesterday (yesterday)
  let yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)
  if (timestampDate.getDate() === yesterday.getDate() && timestampDate.getMonth() === yesterday.getMonth() && timestampDate.getFullYear() === yesterday.getFullYear()) {
    return `Yesterday`
  }
  // if one of the previous 6 days (Tuesday)
  let aWeekAgo = new Date()
  aWeekAgo.setHours(0, 0, 0, 0)
  aWeekAgo.setDate(aWeekAgo.getDate() - 6)
  if (Number(aWeekAgo) < timestamp) {
    return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][timestampDate.getDay()]
  }
  // if older than a week (10/04/2022)
  if (Number(aWeekAgo) > timestamp) {
    return `${timestampDate.getDate()}/${timestampDate.getMonth().toString().padStart(2, '0')}/${timestampDate.getFullYear()}`
  }
}

// return a time from a timestamp
const calculateExactTime = timestamp => {
  timestamp = timestamp * 1000
  let timestampDate = new Date(timestamp)
  let now = new Date()

  // if today (14:35)
  if (timestampDate.getDate() === now.getDate() && timestampDate.getMonth() === now.getMonth() && timestampDate.getFullYear() === now.getFullYear()) {
    return `${timestampDate.getHours().toString().padStart(2, '0')}:${timestampDate.getMinutes().toString().padStart(2, '0')}`
  }
  // if yesterday (yesterday)
  let yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)
  if (timestampDate.getDate() === yesterday.getDate() && timestampDate.getMonth() === yesterday.getMonth() && timestampDate.getFullYear() === yesterday.getFullYear()) {
    return `Yesterday, ${timestampDate.getHours().toString().padStart(2, '0')}:${timestampDate.getMinutes().toString().padStart(2, '0')}`
  }
  // if one of the previous 6 days (Tuesday)
  let aWeekAgo = new Date()
  aWeekAgo.setHours(0, 0, 0, 0)
  aWeekAgo.setDate(aWeekAgo.getDate() - 6)
  if (Number(aWeekAgo) < timestamp) {
    return `${['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][timestampDate.getDay()]}, ${timestampDate.getHours().toString().padStart(2, '0')}:${timestampDate
      .getMinutes()
      .toString()
      .padStart(2, '0')}`
  }
  // if older than a week (10/04/2022)
  if (Number(aWeekAgo) > timestamp) {
    return `${timestampDate.getDate()}/${timestampDate.getMonth().toString().padStart(2, '0')}/${timestampDate.getFullYear()}, ${timestampDate.getHours().toString().padStart(2, '0')}:${timestampDate
      .getMinutes()
      .toString()
      .padStart(2, '0')}`
  }
}

const shouldShowTimestamp = (current, previous) => {
  let currentTimestamp = new Date(Number(current * 1000))
  let previousTimestamp = new Date(Number(previous * 1000))
  // calculate difference in milliseconds
  let timeAgo = Number(currentTimestamp) - Number(previousTimestamp)
  return timeAgo > 7200000
}

// milisecond converter to 00:00

const millisToMinutesAndSeconds = millis => {
  var minutes = Math.floor(millis / 60000)
  var seconds = ((millis % 60000) / 1000).toFixed(0)
  return minutes + ':' + (seconds < 10 ? '0' : '') + seconds
}

// swearjar-node
var swearjar = {
  _badWords: {},

  scan: function (text, callback) {
    var word, key, match
    var regex = /\w+/g

    while ((match = regex.exec(text))) {
      word = match[0]
      key = word.toLowerCase()

      if (key in this._badWords && Array.isArray(this._badWords[key])) {
        if (callback(word, match.index, this._badWords[key]) === false) {
          break
        }
      }
    }
  },

  profane: function (text) {
    var profane = false

    this.scan(text, function (word, index, categories) {
      profane = true
      return false // Stop on first match
    })

    return profane
  },

  scorecard: function (text) {
    var scorecard = {}

    this.scan(text, function (word, index, categories) {
      for (var i = 0; i < categories.length; i += 1) {
        var cat = categories[i]

        if (cat in scorecard) {
          scorecard[cat] += 1
        } else {
          scorecard[cat] = 1
        }
      }
    })

    return scorecard
  },

  censor: function (text) {
    var censored = text

    this.scan(text, function (word, index, categories) {
      censored = censored.substr(0, index) + word.replace(/\S/g, '*') + censored.substr(index + word.length)
    })

    return censored
  },

  setBadWords: function (badWords) {
    this._badWords = badWords || {}
  },
}

swearjar._badWords = require('./en_US.json')

export {
  getAge,
  secondsSinceBirthday,
  getBirthday,
  getShortDate,
  getTime,
  sortBy,
  calculateMessageTime,
  capitaliseFirstLetter,
  calculateExactTime,
  shouldShowTimestamp,
  millisToMinutesAndSeconds,
  swearjar,
}
