import { useAuth } from './../../components/auth/AuthContext'
import { getAge, getBirthday } from './../../components/utilities'

import { Outlet, useNavigate } from 'react-router-dom'

import ProfilePic from './../../components/ui/ProfilePic'
import Button from '../../components/ui/Button'
import EditIcon from './../../components/ui/icons/EditIcon'
import AdminIcon from './../../components/ui/icons/AdminIcon'

const Profile = () => {
  // get current user
  const { userData, age } = useAuth()

  // use navigate
  const navigate = useNavigate()

  return (
    <>
      <div className='lhm-body'>
        <div className='profile-top'>
          <div className={`profile-background ${userData.colour}`}></div>
          <div className='jigsaw-overlay'></div>
          <div className='profile-content'>
            <button className='svg-button' onClick={() => navigate('settings')}>
              <AdminIcon white={true} />
            </button>
            <h1>
              {userData.firstName} {userData.lastName}
            </h1>
            {userData.role === 'youth' && <h2>{`${getAge(age)} years old`}</h2>}
            {userData?.pronouns && <p>{userData.pronouns}</p>}
            <p>
              <strong>Birthday: </strong>
              {getBirthday(age)}
            </p>
            <ProfilePic firstName={userData.firstName} image={userData?.profilePic} colour={userData.colour} size={160} />
          </div>
        </div>
        <div className='container-20px profile-bottom'>
          {userData?.bio ? (
            <p>{userData.bio}</p>
          ) : (
            <p>You haven't written a bio yet, tell everyone about yourself by adding one with the edit button below! ✍ 🙌</p>
          )}
          <Button action={() => navigate('edit')} colour={'blue'} text={'Edit profile'} fixed={true} icon={<EditIcon white={true} />} />
        </div>
      </div>
      <Outlet />
    </>
  )
}

export default Profile
