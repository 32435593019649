import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { db } from './../../firebase'
import { collection, doc, setDoc, getDoc, updateDoc } from 'firebase/firestore'

import Button from './../../components/ui/Button'
import NoticeOverlay from './../../components/ui/NoticeOverlay'
import RightArrow from './../../components/ui/icons/RightArrow'
import LoadingSpinner from './../../components/ui/icons/LoadingSpinner'

const AdminEventsCreate = ({ existingEvent }) => {
  // setup navigation
  const navigate = useNavigate()

  // gain access to existing event id
  let params = useParams()

  // loading state
  const [loading, setLoading] = useState(false)

  // form errors
  const [formErrors, setFormErrors] = useState([])

  // close notice function, removes from formErrors array
  const closeNotice = e => {
    let key = Number(e.currentTarget.dataset.key)
    let currentErrors = formErrors.filter((el, index) => {
      if (index !== key) {
        return el
      }
      return null
    })
    setFormErrors(currentErrors)
  }

  // event info state
  const [eventData, setEventData] = useState({
    name: '',
    dateTime: new Date(Math.round(new Date().getTime() / 60000) * 60000),
    description: '',
    community: 'lhmKidz',
    colour: 'green-blue',
    existingId: params.id || null,
  })

  useEffect(() => {
    const getData = async () => {
      if (!params.id) {
        return
      }
      const eventRef = doc(db, 'events', params.id)
      const eventData = await getDoc(eventRef)
      if (eventData.exists()) {
        setEventData({
          name: eventData.data().name,
          dateTime: new Date(eventData.data().dateTime.seconds * 1000),
          description: eventData.data().description,
          community: eventData.data().community,
          colour: eventData.data().colour,
          existingId: params.id,
        })
      }
    }

    getData()
  }, [])

  // get existing data if editing an event
  useEffect(() => {
    const getEventData = async () => {
      // get existing event data
      setLoading(true)
    }
    if (existingEvent) {
      getEventData()
    }
  }, [existingEvent])

  // add or update event in database
  const saveEvent = async () => {
    // set loading
    setLoading(true)
    // setup errors
    let errors = []

    // validate data
    for (const data in eventData) {
      if (data !== 'existingId') {
        if (!eventData[data]) {
          errors.push({
            colour: 'red',
            message: `Please enter a ${data} for this event`,
          })
        }
        if (errors.length > 0) {
          setFormErrors([...errors])
          setLoading(false)
          return
        }
      }
    }
    if (eventData.existingId) {
      // update event
      try {
        // save to database using existing id
        const existingEvent = doc(db, 'events', params.id)
        await updateDoc(existingEvent, {
          name: eventData.name,
          dateTime: eventData.dateTime,
          description: eventData.description,
          community: eventData.community,
          colour: eventData.colour,
        })
        // return
        return navigate('/admin/events')
      } catch (error) {
        setLoading(false)
        console.error(error)
        setFormErrors([
          {
            colour: 'red',
            message: `Sorry, something went wrong`,
          },
        ])
      }
    } else {
      // new event, so create database entry and save
      try {
        // save new event to database
        const newEvent = doc(collection(db, 'events'))
        await setDoc(newEvent, {
          name: eventData.name,
          dateTime: eventData.dateTime,
          description: eventData.description,
          community: eventData.community,
          colour: eventData.colour,
        })
        // return
        return navigate('/admin/events')
      } catch (error) {
        setLoading(false)
        console.error(error)
        setFormErrors([
          {
            colour: 'red',
            message: `Sorry, something went wrong`,
          },
        ])
      }
    }
  }

  return (
    <>
      <div className='admin-page-container create-event'>
        {formErrors && <NoticeOverlay notices={formErrors} closeFunction={closeNotice} />}
        <h2>{eventData.existingId ? 'Edit' : 'Create'} event</h2>
        <input placeholder='Event name' name='name' type='text' className='small-text-input' onChange={e => setEventData({ ...eventData, name: e.target.value })} value={eventData.name} />
        <textarea
          className='event-text-input'
          autoComplete='off'
          autoCorrect='on'
          spellCheck='true'
          aria-multiline='true'
          placeholder='Event description'
          aria-label='Event description'
          onInput={e => setEventData({ ...eventData, description: e.target.value })}
          value={eventData.description}
        ></textarea>
        <h3>Set a date and time for the event</h3>
        <input
          type='datetime-local'
          onInput={e => setEventData({ ...eventData, dateTime: new Date(e.target.value) })}
          value={new Date(eventData.dateTime.getTime() - eventData.dateTime.getTimezoneOffset() * 60000).toISOString().substring(0, 19)}
        ></input>
        <h3>Select community</h3>
        <div className='event-community-select'>
          <div className='checkbox-container blue' onClick={() => setEventData({ ...eventData, community: 'lhmKidz' })}>
            <div className={eventData.community === 'lhmKidz' ? 'checkbox checked' : 'checkbox'}></div>
            <p>LHM Kidz</p>
          </div>
          <div className='checkbox-container blue' onClick={() => setEventData({ ...eventData, community: 'juniors' })}>
            <div className={eventData.community === 'juniors' ? 'checkbox checked' : 'checkbox'}></div>
            <p>Juniors</p>
          </div>
          <div className='checkbox-container blue' onClick={() => setEventData({ ...eventData, community: 'seniors' })}>
            <div className={eventData.community === 'seniors' ? 'checkbox checked' : 'checkbox'}></div>
            <p>Seniors</p>
          </div>
        </div>
        <h3>Event colour</h3>
        <div className='swatch-container'>
          <div className={`swatch green-blue ${eventData.colour === 'green-blue' && 'current'}`} onClick={() => setEventData({ ...eventData, colour: 'green-blue' })}></div>
          <div className={`swatch blue-pink ${eventData.colour === 'blue-pink' && 'current'}`} onClick={() => setEventData({ ...eventData, colour: 'blue-pink' })}></div>
          <div className={`swatch orange-pink ${eventData.colour === 'orange-pink' && 'current'}`} onClick={() => setEventData({ ...eventData, colour: 'orange-pink' })}></div>
          <div className={`swatch pink-green ${eventData.colour === 'pink-green' && 'current'}`} onClick={() => setEventData({ ...eventData, colour: 'pink-green' })}></div>
          <div className={`swatch night-blue ${eventData.colour === 'night-blue' && 'current'}`} onClick={() => setEventData({ ...eventData, colour: 'night-blue' })}></div>
        </div>
        <Button action={() => saveEvent()} colour={loading ? 'loading blue' : 'blue'} text={'Save'} fixed={true} icon={loading ? <LoadingSpinner /> : <RightArrow colour={'#fff'} />} />
      </div>
    </>
  )
}

export default AdminEventsCreate
