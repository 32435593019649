import { createContext, useContext, useState, useEffect } from 'react'
import { db } from './../../firebase'
import { realtimeDatabase } from './../../firebase'
import { collection, query, doc, where, setDoc, serverTimestamp as fServerTimestamp } from 'firebase/firestore'
import { ref, onValue, onDisconnect, serverTimestamp, set } from 'firebase/database'
import { useCollection } from 'react-firebase-hooks/firestore'
import { useAuth } from './../../components/auth/AuthContext'

import { getAge } from './../utilities'

import { Outlet } from 'react-router-dom'

import Loading from '../ui/Loading'
import communityPic from './../../images/community-chat-profile.jpg'

const ChatContext = createContext()

export function ChatProvider() {
  // get auth context
  const { user, userData, userDataLoading, addToCommunity, role, authLoading, age } = useAuth()

  // online state
  const [online, setOnline] = useState(true)

  // Use realtime database to detect presence
  // this is synced with Firestore /onlineStatus
  // TODO maybe this triggers an unwanted load because the server updated the firestore online collection?
  useEffect(() => {
    if (user && !authLoading) {
      const connectedRef = ref(realtimeDatabase, '.info/connected')
      const databaseOnlineStatus = ref(realtimeDatabase, `/status/${user.uid}`)
      const firestoreOnlineStatus = doc(db, 'onlineStatus', user.uid)

      const handleValueChange = async snapshot => {
        if (snapshot.val() === false) {
          setOnline(false)
          try {
            await setDoc(firestoreOnlineStatus, {
              state: 'offline',
              lastChanged: fServerTimestamp(),
            })
          } catch (error) {
            console.error('Error setting Firestore offline status:', error)
          }
          return
        }

        onDisconnect(databaseOnlineStatus)
          .set({
            state: 'offline',
            lastChanged: serverTimestamp(),
          })
          .then(() => {
            set(databaseOnlineStatus, {
              state: 'online',
              lastChanged: serverTimestamp(),
            })

            setDoc(firestoreOnlineStatus, {
              lastChanged: fServerTimestamp(),
              state: 'online',
            })

            setOnline(true)
          })
          .catch(error => {
            console.error('Error setting Firestore online status:', error)
          })
      }

      const unsubscribe = onValue(connectedRef, handleValueChange)

      return () => {
        unsubscribe()
      }
    }
  }, [user, authLoading])

  // listen only to community and chats the current user is in
  const [chats, setChats] = useState([])
  const [allChats, chatsLoading, chatsError] = useCollection(query(collection(db, 'chats'), where(`members.${user.uid}`, '!=', false)))
  // get community chats
  const [communtiyChats, communityLoading, communityError] = useCollection(query(collection(db, 'communityChats')))

  // listen to online status of users
  const [onlineStatus, onlineStatusLoading, onlineStatusError] = useCollection(collection(db, 'onlineStatus'))
  // all loading status
  const [allLoading, setAllLoading] = useState(true)

  // log errors
  useEffect(() => {
    if (chatsError) console.error(chatsError)
    if (onlineStatusError) console.error(onlineStatusError)
    if (communityError) console.error(communityError)
  }, [chatsError, onlineStatusError, communityError])

  useEffect(() => {
    const sortMessageData = async () => {
      if (!chatsLoading && !onlineStatusLoading && !communityLoading && !userDataLoading && userData) {
        await user
        await allChats
        await onlineStatus
        await communtiyChats
        // check if needs to be added to a new community
        if (role === 'youth') {
          let correctCommunity = ''
          let userAge = getAge(age)
          // make sure user is in correct community
          // new code November 2023 to adjust ages for users older than 8/11/23
          let lhmKidzUpperAge = userData.createdOn.seconds < 1699488664 ? 10 : 11
          let juniorsLowerAge = userData.createdOn.seconds < 1699488664 ? 11 : 12
          // for LHM Kids
          if (userAge >= 7 && userAge <= lhmKidzUpperAge) {
            correctCommunity = 'lhmKidz'
          }
          // for Juniors
          if (userAge >= juniorsLowerAge && userAge <= 14) {
            correctCommunity = 'juniors'
          }
          // for Seniors
          if (userAge >= 15 && userAge <= 17) {
            correctCommunity = 'seniors'
          }
          // for too old
          if (userAge > 17) {
            correctCommunity = 'too old'
          }

          // if not in this community, add them
          if (!userData?.communities[correctCommunity] && userData.overEighteen !== true) {
            return addToCommunity(correctCommunity)
          }
        }
        // filter community chats
        const allowedComminties = communtiyChats.docs.filter(communityChat => {
          if (userData?.communities) {
            return userData.communities[communityChat.id]
          } else {
            return null
          }
        })

        // combine community chats with others
        let combinedChats = [...allChats.docs, ...allowedComminties]
        // sort the chats
        combinedChats.sort((a, b) => {
          const timetampA = a.data()?.lastMessage?.timestamp?.seconds || Math.floor(Number(new Date() / 1000))
          const timetampB = b.data()?.lastMessage?.timestamp?.seconds || Math.floor(Number(new Date() / 1000))
          return timetampB - timetampA
        })
        // organise the chat data
        let processChatsData = combinedChats.map(chat => {
          // take a copy of the actual database data
          let chatData = chat.data()
          // merge id into the object
          chatData = { ...chatData, id: chat.id }
          let read = chatData?.lastMessage?.readBy?.includes(user.uid)
          // sometimes serverTimestamp is null
          let timestampSolver = chatData?.lastMessage?.timestamp?.seconds || Math.floor(Number(new Date() / 1000))

          // a switch scenario for each chat type
          switch (chatData.type) {
            case 'single':
              // get contact from 'members' object by removing current user
              let contact = {}
              let contactUid = ''
              for (const uid in chatData.members) {
                if (user.uid !== uid) {
                  contact[uid] = chatData.members[uid]
                  contactUid = uid
                }
              }
              // check if the other contact is online
              let contactOnline = false
              if (contact) {
                // makes an array of ids (one in this case) because contact is an object still
                let contactId = Object.keys(contact)
                // loop through all users online status and match with contact
                onlineStatus.docs.forEach(userStatus => {
                  if (contactId.includes(userStatus.id)) {
                    // set online variable to match status
                    contactOnline = userStatus.data().state === 'online'
                  }
                })
              }
              return {
                id: chatData.id,
                name: contact[contactUid].name,
                subtitle: contact[contactUid].role === 'mentor' ? '(Mentor)' : contact[contactUid].role === 'admin' ? '(Youth Leader)' : null,
                image: contact[contactUid].profilePic || null,
                colour: contact[contactUid].colour || null,
                members: chatData.members,
                online: contactOnline,
                type: 'single',
                lastMessage: {
                  message: chatData.lastMessage.message,
                  timestamp: timestampSolver,
                  read: read,
                },
              }
            case 'group':
              // check if any group members are online
              let groupOnline = false
              // loop through all users online status and match with contact
              onlineStatus.docs.some(userStatus => {
                // check for match to a member of the group so long as it's not the current user
                if (userStatus.id !== user.uid && chatData.members[userStatus.id]) {
                  // set online variable if the user is online
                  if (userStatus.data().state === 'online') {
                    groupOnline = true
                    return true
                  }
                }
                return false
              })
              return {
                id: chatData.id,
                name: chatData.name,
                subtitle: null,
                image: chatData?.profilePic || null,
                colour: chatData?.colour || 'orange-pink',
                members: chatData.members,
                ownedBy: chatData.ownedBy,
                online: groupOnline,
                type: 'group',
                lastMessage: {
                  message: chatData.lastMessage.message,
                  from: chatData.lastMessage?.from,
                  timestamp: timestampSolver,
                  read: read,
                },
              }
            case 'community':
              let isTooOld = false
              if (role === 'youth') {
                // check if the user is too old
                let currentAge = getAge(age)
                isTooOld = (chatData.name === 'LHM Kidz' && currentAge > 11) || (chatData.name === 'Juniors' && currentAge > 14) || (chatData.name === 'Seniors' && currentAge > 17)
              }

              return {
                id: chatData.id,
                name: chatData.name,
                subtitle: chatData.subtitle || null,
                image: communityPic,
                colour: 'blue-red',
                type: 'community',
                timeout: chatData?.timeout,
                online: online,
                isTooOld: isTooOld,
                lastMessage: {
                  message: chatData?.lastMessage?.message,
                  from: chatData?.lastMessage?.from,
                  timestamp: timestampSolver,
                  read: read,
                },
              }
            default:
              return chatData
          }
        })
        setChats(processChatsData)
        setAllLoading(false)
      }
    }

    sortMessageData()
  }, [user, userData, userDataLoading, allChats, chatsLoading, onlineStatus, onlineStatusLoading, communtiyChats, communityLoading])

  const value = {
    chats,
    online,
    chatsLoading,
    onlineStatus,
    onlineStatusLoading,
  }

  return <ChatContext.Provider value={value}>{chatsLoading || onlineStatusLoading || allLoading ? <Loading /> : chats && !allLoading ? <Outlet /> : <Loading />}</ChatContext.Provider>
}

export function useChats() {
  return useContext(ChatContext)
}
