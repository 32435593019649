const Button = ({ action, fixed = false, colour, text, icon }) => {
  return (
    <div className={`glow-element-button ${colour} ${fixed && 'fixed-button'}`}>
      <button className='button' onClick={action}>
        {text}
        {icon}
      </button>
      <div className='button-glow'></div>
    </div>
  )
}

export default Button
