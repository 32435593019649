import { Link } from 'react-router-dom'

const LinkButton = ({ url, colour, text, icon, locationState }) => {
  return (
    <div className={`glow-element-button ${colour}`}>
      <Link to={url} className='button' state={locationState}>
        {text}
        {icon}
      </Link>
      <div className='button-glow'></div>
    </div>
  )
}

export default LinkButton
