import CloseIcon from './icons/CloseIcon'
import { Link } from 'react-router-dom'

const NoticeOverlay = ({ notices, closeFunction, belowTopBar }) => {
  return (
    <div className={`notices-container ${belowTopBar ? 'below' : ''}`}>
      {notices.map((notice, index) => (
        <div key={index} className={`notice-overlay ${notice.colour}`}>
          {!notice.noClose && closeFunction && (
            <button className='svg-button' onClick={e => closeFunction(e)} data-key={index}>
              <CloseIcon />
            </button>
          )}
          {notice.message}
          {notice.link && <Link to={notice.link.path}>{notice.link.text}</Link>}
          {notice.action && <span onClick={() => notice.action.function()}>{notice.action.text}</span>}
        </div>
      ))}
    </div>
  )
}

export default NoticeOverlay
