const SearchIcon = () => (
  <svg width={20} height={21} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.473 16.138a7.647 7.647 0 0 0-1.453-1.736 14.514 14.514 0 0 0-1.302-1.06c-.66-.485-1.353-.944-2.02-1.412h-.076a7.037 7.037 0 0 0 1.461-3.28 7.447 7.447 0 0 0-2.246-6.254A7.59 7.59 0 0 0 7.684.026c-.452.059-.897.165-1.327.317-.209.066-.418.158-.618.242a6.763 6.763 0 0 0-2.98 2.103 8.866 8.866 0 0 0-1.72 4.125 7.255 7.255 0 0 0 2.554 6.72 6.937 6.937 0 0 0 7.122 1.286 10.59 10.59 0 0 0 1.428-.751 16.483 16.483 0 0 0 1.385 3.122 7.339 7.339 0 0 0 1.829 2.12 3.881 3.881 0 0 0 3.381.518 2.037 2.037 0 0 0 1.236-2.128 3.34 3.34 0 0 0-.501-1.562Zm-9.125-2.246a6.003 6.003 0 0 1-6.162-1.06A6.504 6.504 0 0 1 1.765 6.93a8.157 8.157 0 0 1 1.486-3.857 5.619 5.619 0 0 1 1.436-1.336.284.284 0 0 0 .159-.042c.548-.302 1.12-.56 1.711-.768a5.928 5.928 0 0 1 1.194-.25 6.82 6.82 0 0 1 5.527 2.229 6.586 6.586 0 0 1 1.895 5.56 6.345 6.345 0 0 1-1.67 3.28 8.842 8.842 0 0 1-3.155 2.146Zm7.622 5.127a3.089 3.089 0 0 1-2.096-.476 6.428 6.428 0 0 1-1.711-1.77 16.093 16.093 0 0 1-1.553-3.006 9.66 9.66 0 0 0 1.561-1.352l.092-.109a.234.234 0 0 0 .075.084c.643.5 1.303.985 1.937 1.502.419.35.817.724 1.194 1.12.48.473.891 1.012 1.219 1.602.195.354.307.748.326 1.152a1.151 1.151 0 0 1-1.044 1.253Z'
      fill='#B7B7B7'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.456 3.932a2.087 2.087 0 0 0-.968.226c-.429.24-.834.52-1.211.835l-.326-.3a3.44 3.44 0 0 0-.926-.535 2.78 2.78 0 0 0-1.044-.184 2.38 2.38 0 0 0-1.978 1.102 3.006 3.006 0 0 0-.426 2.13 4.25 4.25 0 0 0 .718 1.669c.326.497.73.94 1.194 1.31.427.344.889.644 1.377.894 2.046 1.002 1.611 1.227 3.448-.142.396-.29.776-.601 1.136-.935a5.368 5.368 0 0 0 1.51-4.174c-.175-1.06-.901-1.87-2.504-1.896Zm.476 5.56a9.33 9.33 0 0 1-1.027.91c-1.486 1.178-1.135.769-1.394.719a5.843 5.843 0 0 1-1.394-.56 7.234 7.234 0 0 1-1.22-.835 5.343 5.343 0 0 1-1.001-1.21 3.615 3.615 0 0 1-.501-1.436 2.137 2.137 0 0 1 .35-1.494 1.478 1.478 0 0 1 1.244-.593c.238-.002.474.043.693.133.228.093.441.217.635.368.058.05.359.359.484.484a.634.634 0 0 0 .643.133c.518-.283.835-.692 1.286-.976a1.32 1.32 0 0 1 .726-.242c1.002 0 1.503.434 1.67 1.085a4.459 4.459 0 0 1-1.186 3.515h-.008Z'
      fill='#B7B7B7'
    />
  </svg>
)

export default SearchIcon
