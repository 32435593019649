const EditIcon = ({ white }) => (
  <svg width={26} height={26} viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#a)' fillRule='evenodd' clipRule='evenodd'>
      <path
        d='M21.84 11.19a.368.368 0 0 0-.368-.357.369.369 0 0 0-.358.38l.152 11.667c.043.451.003.907-.12 1.343a.748.748 0 0 1-.736.499H2.134a.758.758 0 0 1-.422-.26 1.083 1.083 0 0 1-.293-.52 64.23 64.23 0 0 1-.206-7.01c0-4.625 0-9.912.195-10.67.062-.357.207-.695.423-.986a.986.986 0 0 1 .769-.423h11.754a.325.325 0 0 0 0-.65L2.567 3.911a1.83 1.83 0 0 0-1.245.498 3.25 3.25 0 0 0-.867 1.614c-.173.77-.4 6.154-.444 10.834a57.276 57.276 0 0 0 .206 7.377c.099.408.305.781.595 1.084a2.08 2.08 0 0 0 1.084.628h2.069l6.576-.065c4.572-.065 9.36-.152 9.89-.184a1.603 1.603 0 0 0 1.452-.78c.31-.647.422-1.37.325-2.08L21.84 11.19Z'
        fill={white ? '#FFFFFF' : 'var(--blue)'}
      />
      <path
        d='M24.917.726A3.023 3.023 0 0 0 22.75.054a2.437 2.437 0 0 0-.79.239 3.91 3.91 0 0 0-.91.682.325.325 0 0 0 0 .455.314.314 0 0 0 .443.065c.221-.16.457-.298.705-.412.215-.076.444-.106.671-.086.495.069.953.302 1.3.66a1.572 1.572 0 0 1 .694 1.084c.015.372-.056.743-.206 1.083a4.01 4.01 0 0 1-.542.867c-.78-.704-2.708-2.253-3.25-2.72a.368.368 0 0 0-.52.52c.488.499 2.34 2.167 3.098 2.85-.099.16-.211.313-.335.455-.954 1.083-2.817 2.957-4.226 4.409l-.422.433a8.673 8.673 0 0 0-.693-1.083 3.876 3.876 0 0 0-.964-.932 5.874 5.874 0 0 0-1.084-.563c1.083-1.332 2.676-3.163 3.5-4.106l.53-.617a.325.325 0 0 0-.054-.423.325.325 0 0 0-.466 0l-.552.574c-1.084 1.149-3.554 3.684-4.529 4.865a2.46 2.46 0 0 0-.53.877c0 .325-.304 2.567-.325 3.488-.023.285.046.57.195.813a2.038 2.038 0 0 0 1.592.27A9.392 9.392 0 0 0 17.8 13c.13-.076 1.082-1.083 2.285-2.34s2.232-2.492 3.11-3.5c.54-.627.963-1.082 1.148-1.364.409-.463.789-.952 1.137-1.463.283-.46.46-.979.52-1.516A2.569 2.569 0 0 0 24.917.726Zm-7.8 11.245c-.387.174-.79.308-1.203.4-.459.148-.925.271-1.397.369 0-1.224.14-3.25.14-3.25.097-.157.202-.309.315-.455l.238-.325c.292.303.574.65.92 1.008.348.357.478.444.727.66.249.217.704.585.996.889-.368.379-.65.65-.704.704h-.032Z'
        fill={white ? '#FFFFFF' : 'var(--red)'}
      />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M0 0h26v26H0z' />
      </clipPath>
    </defs>
  </svg>
)

export default EditIcon
