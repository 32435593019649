const RightArrow = ({ colour }) => (
  <svg width={19} height={15} viewBox='0 0 19 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M18.446 7.42a20.379 20.379 0 0 0-2.141-2.392c-1.838-1.83-4.112-3.756-4.646-3.912a2.134 2.134 0 0 0-.837-.111c-.209.02-.41.091-.585.207-.197.13-.37.293-.511.482-.16.187-.28.403-.356.637-.087.363-.059.744.082 1.09.125.324.317.62.563.866.533.519.904.97 1.407 1.482l.26.252c-.741 0-3.942-.045-6.446 0-.96.005-1.918.08-2.867.222a1.652 1.652 0 0 0-1.326 2 1.845 1.845 0 0 0 1.726 1.615H4.79c2.297-.066 5.66-.214 7.18-.2h.037c-.519.504-1.334 1.245-1.6 1.512a3.207 3.207 0 0 0-1.149 2.178 1.749 1.749 0 0 0 1.037 1.393 1.482 1.482 0 0 0 1.282 0 8.241 8.241 0 0 0 2.075-1.482c1.481-1.311 3.178-2.763 4.2-3.756a3.4 3.4 0 0 0 .97-1.2 1.578 1.578 0 0 0-.377-.882Zm-1.43 2.224c-1.045.933-2.49 2.111-3.704 3.2a8.312 8.312 0 0 1-1.63 1.178 1.142 1.142 0 0 1-1.037.163c-.608-.356-.741-.74-.667-1.163a3.2 3.2 0 0 1 .918-1.378c.311-.304 1.541-1.363 2-1.852.099-.096.181-.206.245-.326a.37.37 0 0 0-.096-.437.54.54 0 0 0-.282-.134 7.29 7.29 0 0 0-.963-.059c-2.222-.037-7.572.193-8.98.14a.993.993 0 0 1-.91-.896.83.83 0 0 1 .614-.978 19.262 19.262 0 0 1 2.712-.215c2.652-.103 6.105-.103 6.512-.11h.615c.139.006.277.006.415 0a.378.378 0 0 0 .26-.253.356.356 0 0 0-.134-.43c-.407-.318-.74-.6-1-.866-.504-.497-.89-.941-1.43-1.482a1.689 1.689 0 0 1-.407-.607 1.096 1.096 0 0 1-.075-.667c.052-.143.127-.276.223-.393.1-.128.217-.243.348-.34a.696.696 0 0 1 .385-.12c.169.003.336.03.497.082.503.148 2.674 1.978 4.445 3.705.762.71 1.468 1.478 2.111 2.296.178.245.304.415.29.526.029.252-.527.749-1.275 1.423v-.007Z'
      fill={colour}
      stroke={colour}
      strokeWidth={0.2}
    />
  </svg>
)

export default RightArrow
