import { useState, useRef } from 'react'

import CloseIcon from './icons/CloseIcon'
import SearchIcon from './icons/SearchIcon'

const SearchBar = ({ searchFunction }) => {
  const [searchTerm, setSearchTerm] = useState('')

  const clearSearch = () => {
    setSearchTerm('')
    searchFunction('')
  }

  const triggerSearch = () => {
    searchFunction(searchTerm)
  }

  const runSearchFunction = useRef()

  const handleChange = e => {
    clearTimeout(runSearchFunction.current)
    setSearchTerm(e.target.value)
    if (e.target.value) {
      runSearchFunction.current = setTimeout(() => {
        triggerSearch()
      }, 400)
    } else {
      searchFunction('')
    }
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      triggerSearch()
    }
  }

  return (
    <div className='search-bar glow-element'>
      <button className='svg-button' onClick={triggerSearch}>
        <SearchIcon />
      </button>
      <input
        className=''
        type='search'
        tabIndex='-1'
        placeholder='Search'
        spellCheck='false'
        value={searchTerm}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
      <button className={`svg-button clear-text ${searchTerm === '' && 'hidden'}`} onClick={clearSearch}>
        <CloseIcon />
      </button>
    </div>
  )
}

export default SearchBar
