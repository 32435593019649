import { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from './../../components/auth/AuthContext'

import RightArrow from './../../components/ui/icons/RightArrow'
import LoadingSpinner from './../../components/ui/icons/LoadingSpinner'
import Button from './../../components/ui/Button'
import NoticeOverlay from './../../components/ui/NoticeOverlay'

const Login = () => {
  const { signIn, signInLoading, signInError } = useAuth()

  // login form state
  const [loginDetails, setLoginDetails] = useState({
    email: '',
    password: '',
  })

  useEffect(() => {
    if (signInError) {
      let errorMessage = ''
      switch (signInError) {
        default:
          if (signInError.code) {
            switch (signInError.code) {
              case 'auth/wrong-password':
              case 'wrong-password':
                errorMessage = 'Wrong password, please try again or reset your password on the link below'
                break
              case 'auth/user-not-found':
              case 'user-not-found':
                errorMessage = 'Email not recognised, please try again or email us for support'
                break
              default:
                errorMessage = 'Something went wrong'
            }
          } else if (signInError) {
            switch (signInError) {
              case 'A network error (such as timeout, interrupted connection or unreachable host) has occurred.':
                errorMessage = 'Oops, it seems you are offline. Please check your connection and try again'
                break
              case 'There is no user record corresponding to this identifier. The user may have been deleted.':
              case 'Firebase: Error (auth/user-not-found).':
                errorMessage = 'Email not recognised, please try again or email us for support'
                break
              case 'The password is invalid or the user does not have a password.':
              case 'Firebase: Error (auth/wrong-password).':
                errorMessage = 'Wrong password, please try again or reset your password on the link below'
                break
              case 'We have blocked all requests from this device due to unusual activity. Try again later. [ Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. ]':
                errorMessage = `Sorry, you've had too many failed login attempts. Please reset your password or try again later`
                break
              default:
                errorMessage = 'Something went wrong'
            }
          } else {
            errorMessage = 'Something went wrong'
          }
      }

      setFormErrors([
        {
          colour: 'red',
          message: errorMessage,
        },
      ])
    }
  }, [signInError])

  // form errors
  const [formErrors, setFormErrors] = useState([])

  const handleInputChange = e => {
    if (formErrors.length > 0) {
      setFormErrors([])
    }
    // maintain an object for our form
    let name = e.target.name
    let value = e.target.value
    setLoginDetails({ ...loginDetails, [name]: value })
  }

  // validation
  const emailField = useRef()
  const passwordField = useRef()

  const handleSubmit = async e => {
    e.preventDefault()
    let errors = []
    if (!emailField.current.validity.valid) {
      errors.push({
        colour: 'red',
        message: 'Oops, please check your email address',
      })
    }
    if (!passwordField.current.validity.valid) {
      errors.push({
        colour: 'red',
        message: 'Please enter a password',
      })
    }
    if (errors.length > 0) {
      setFormErrors([...errors])
    } else {
      // sign in
      signIn(loginDetails.email, loginDetails.password)
    }
  }

  // close notice function, removes from formErrors array
  const closeNotice = e => {
    let key = Number(e.currentTarget.dataset.key)
    let currentErrors = formErrors.filter((el, index) => {
      if (index !== key) {
        return el
      }
      return null
    })
    setFormErrors(currentErrors)
  }

  return (
    <>
      {formErrors && <NoticeOverlay notices={formErrors} closeFunction={closeNotice} />}

      <h1 className='text-center'>Log in</h1>
      <form>
        <input placeholder='Email' ref={emailField} name='email' type='email' autoComplete='email' onChange={handleInputChange} value={loginDetails.email} required />
        <input placeholder='Password' ref={passwordField} name='password' type='password' autoComplete='current-password' onChange={handleInputChange} value={loginDetails.password} required />
        <Button action={handleSubmit} colour={signInLoading ? 'loading blue' : 'blue'} text={signInLoading ? '' : 'Log in'} icon={signInLoading ? <LoadingSpinner /> : <RightArrow colour='#fff' />} />
        <Link to='/reset-password' className='reset-password-link'>
          Forgot password?
        </Link>
      </form>
    </>
  )
}

export default Login
