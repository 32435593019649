import { useState, useEffect, useRef } from 'react'

import { db } from './../../firebase'
import { doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore'

const PauseNotifications = ({ currentChat, user }) => {
  // default current notification options to an object
  const defaultCurrentNotificationOptions = {
    [currentChat.id]: {
      updatedAt: serverTimestamp(),
      stopUntil: 0,
    },
  }

  // state for current notification options
  const [currentNotificationOptions, setCurrentNotificationOptions] = useState(null)
  const [notificationOption, setNotificationOption] = useState(null) // Add this state

  // create an object for associated numbers 1-4 with time in seconds
  const timeOptions = {
    1: 0,
    2: 3600,
    3: 86400,
    4: 604800,
  }

  // Ref to track initial render
  const isInitialRender = useRef(true)

  // get notification option from database on load
  useEffect(() => {
    const fetchNotificationOptions = async () => {
      if (currentChat) {
        let pause = await getDoc(doc(db, 'notificationsTokens', user.uid))
        if (pause.exists() && pause.data().pause?.[currentChat.id]) {
          let stopUntil = pause.data().pause[currentChat.id].stopUntil
          const matchingKey = Object.keys(timeOptions).find(key => timeOptions[key] === stopUntil)
          setCurrentNotificationOptions(pause.data().pause[currentChat.id])
          setNotificationOption(Number(matchingKey)) // Convert matchingKey to number
        } else {
          setNotificationOption(1)
          setCurrentNotificationOptions(defaultCurrentNotificationOptions)
        }
      }
    }

    fetchNotificationOptions()
  }, [currentChat, user]) // Add dependencies

  // update database to reflect notification option
  useEffect(() => {
    const updateNotificationOptions = async () => {
      if (currentChat && user && notificationOption !== null) {
        if (isInitialRender.current) {
          isInitialRender.current = false
          return
        }
        await updateDoc(doc(db, 'notificationsTokens', user.uid), {
          [`pause.${currentChat.id}`]: {
            updatedAt: serverTimestamp(),
            stopUntil: timeOptions[notificationOption],
          },
        })
      }
    }

    updateNotificationOptions()
  }, [notificationOption, currentChat, user]) // Add dependencies

  return (
    currentNotificationOptions && (
      <div className='container-20px chat-notification-options'>
        <h3>Notifications for this chat:</h3>
        <div className='checkbox-group'>
          <div className='checkbox-container blue' onClick={() => setNotificationOption(1)}>
            <div className={notificationOption === 1 ? 'checkbox checked' : 'checkbox'}></div>
            <p>On</p>
          </div>
          <div className='checkbox-container blue' onClick={() => setNotificationOption(2)}>
            <div className={notificationOption === 2 ? 'checkbox checked' : 'checkbox'}></div>
            <p>Mute for 1 hour</p>
          </div>
          <div className='checkbox-container blue' onClick={() => setNotificationOption(3)}>
            <div className={notificationOption === 3 ? 'checkbox checked' : 'checkbox'}></div>
            <p>Mute until tomorrow</p>
          </div>
          <div className='checkbox-container blue' onClick={() => setNotificationOption(4)}>
            <div className={notificationOption === 4 ? 'checkbox checked' : 'checkbox'}></div>
            <p>Mute for 1 week</p>
          </div>
        </div>
      </div>
    )
  )
}

export default PauseNotifications
