import React from 'react'
import ReactDOM from 'react-dom/client'
import './normalize.css'
import './index.css'
import App from './App'
import { Capacitor } from '@capacitor/core'

const root = ReactDOM.createRoot(document.getElementById('root'))
document.getElementById('root').classList.add(Capacitor.getPlatform())

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
