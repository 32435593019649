import RightArrow from './icons/RightArrow'

const MenuItem = ({ icon, name, action }) => {
  return (
    <div className='menu-list-item' onClick={action}>
      <div className='list-item-icon'>{icon}</div>
      <p>{name}</p>
      <div className='list-item-arrow'>
        <RightArrow colour='var(--body-black)' />
      </div>
    </div>
  )
}

export default MenuItem
