import { signOut } from 'firebase/auth'
import { auth } from './../../firebase'
import { useState } from 'react'
import { FirebaseAuthentication } from '@capacitor-firebase/authentication'
import app from './../../firebase'
import { getFunctions } from 'firebase/functions'
import { useHttpsCallable } from 'react-firebase-hooks/functions'

import { Outlet, useNavigate } from 'react-router-dom'

import { useAuth } from './../../components/auth/AuthContext'
import { getAge } from './../../components/utilities'

import HeadingBar from './../../components/ui/HeadingBar'
import ConfirmationPopup from './../../components/ui/ConfirmationPopup'
import MenuItem from './../../components/ui/MenuItem'
import LeftArrow from './../../components/ui/icons/LeftArrow'
import RightArrow from './../../components/ui/icons/RightArrow'
import LoadingSpinner from '../../components/ui/icons/LoadingSpinner'
import BlockIcon from './../../components/ui/icons/BlockIcon'
import NotificationsIcon from '../../components/ui/icons/NotificationsIcon'
import ChangePasswordIcon from './../../components/ui/icons/ChangePasswordIcon'
import CloseIcon from './../../components/ui/icons/CloseIcon'

import Button from './../../components/ui/Button'

const Settings = () => {
  // signout loading
  const [signOutLoading, setSignOutLoading] = useState(false)
  // auth context
  const { user, userData, age } = useAuth()
  // setup nav
  const navigate = useNavigate()
  // state for confirmation popup
  const [confirm, setConfirm] = useState(false)

  // close confirmation popup
  const closeAction = () => {
    setConfirm(false)
  }

  console.log(user)

  // set up callable functions
  const [executeDeleteAccount, exceutingDeleteAccount, errorDeleteAccount] = useHttpsCallable(getFunctions(app, 'europe-west2'), 'deleteAccount')

  // function to block contact
  const deleteAccount = async () => {
    // show loading in notification box
    setConfirm(current => {
      return { ...current, loading: true }
    })

    // use cloud function to delete account
    try {
      const result = await executeDeleteAccount({
        uid: user.uid,
        userAge: getAge(age),
      })
      if (result.data.error) throw new Error(result.data.error)
      if (result.data.message === 'done') {
        console.log('account deleted')
        // sign out
        await clickSignOut()
      }
    } catch (error) {
      console.error(error)
      setConfirm({
        heading: `Delete Account`,
        message: `Delete failed, ${error}.`,
        actionText: 'Delete',
        action: deleteAccount,
        loading: false,
      })
    }
  }

  // sign out function
  const clickSignOut = async () => {
    setSignOutLoading(true)
    // force removal of notification token
    // await handleNotifications(user, 'forceDisable')
    // 1. Sign out on the native layer
    await FirebaseAuthentication.signOut()
    // 1. Sign out on the web layer
    await signOut(auth)
  }

  return (
    <>
      <div className='lhm-body settings-container'>
        <HeadingBar heading={`Settings`} iconLeft={<LeftArrow />} iconLeftClick={() => navigate(`/profile`)} colour={userData.colour} />
        <div className='container-20px'>
          <div className='menu-container' style={{ marginTop: '30px' }}>
            <MenuItem action={() => navigate('blocked-members')} icon={<BlockIcon />} name={`Blocked members`} />
            <MenuItem action={() => navigate('notifications')} icon={<NotificationsIcon />} name={`Notifications`} />
            <MenuItem action={() => navigate('change-password')} icon={<ChangePasswordIcon />} name={`Change password`} />
            <MenuItem
              action={() =>
                setConfirm({
                  heading: `Delete Account`,
                  message: `Are you sure you wish to delete your account?`,
                  actionText: 'Delete',
                  action: deleteAccount,
                  loading: false,
                })
              }
              icon={<CloseIcon />}
              name={`Delete account`}
            />
          </div>

          <Button
            action={clickSignOut}
            fixed={true}
            colour={signOutLoading ? 'loading blue' : 'blue'}
            text={signOutLoading ? '' : 'Sign out'}
            icon={signOutLoading ? <LoadingSpinner /> : <RightArrow colour='#fff' />}
          />
        </div>
      </div>
      {confirm && <ConfirmationPopup heading={confirm.heading} message={confirm.message} actionText={confirm.actionText} action={confirm.action} loading={confirm.loading} closeAction={closeAction} />}
      <Outlet />
    </>
  )
}

export default Settings
