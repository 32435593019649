import HeadingBar from './../../components/ui/HeadingBar'
import LeftArrow from './../../components/ui/icons/LeftArrow'

const Terms = ({ goBack }) => {
  return (
    <div className='lhm-body terms-page'>
      <HeadingBar heading='Governance and policies' iconLeft={<LeftArrow />} iconLeftClick={() => goBack()} />
      <div className='container-20px'>
        <h2>Little Hearts Matter</h2>
        <h3>Youth Hub Governance</h3>
        <p>
          <b>Aim</b>
        </p>

        <p>The purpose of the Youth Hub is to offer our youth members a safe online space to participate in chats with:</p>
        <p>a) their LHM youth leader,</p>
        <p>b) LHM youth mentors</p>
        <p>c) youth member peers</p>
        <p>d) other LHM admin staff.</p>

        <p>
          This will enable the young people to feel a part of the community, offer social activities, education and allow them to talk with each other about life with half a heart in a less formal
          setting.
        </p>

        <p>
          Within the LHM Youth Hub youth members will be able to participate in 1:2:1 chats and be included in age-appropriate group chat and virtual youth group sessions. They will have easily
          accessible links to information about their heart conditions and lifestyle topics.
        </p>

        <p>This purpose-built online space has been created for both inclusion of all youth member age groups and for a greater level of safeguarding ability.</p>

        <p>
          Combining the existing youth chat forum, text support service and Facebook virtual youth group in one accessible safe place makes this service more inclusive, safer and easier to manage.
        </p>

        <p>
          <b>Audience</b>
        </p>

        <p>This service is for youth members aged 7-17 years of age. These age groups have been broken down into three subsets:</p>

        <ul>
          <li>LHM Kidz aged 7-11 years</li>
          <li>LHM Juniors aged 12-14 years</li>
          <li>LHM Seniors aged 15-17 years</li>
        </ul>

        <p>Youth members will only have access to chat with those in their age group for safeguarding reasons.</p>

        <p>
          <b>Service</b>
        </p>

        <p>The Youth Hub will offer opportunities for fun, support, information and possible signposting on social and lifestyle topics. Lifestyle topics can include but are not limited to:</p>

        <ul>
          <li>Living with SVHD in an educational setting.</li>
          <li>Talking to people about living with SVHD.</li>
          <li>Being a sibling and talking to your parents about SVHD.</li>
          <li>Social, emotional and mental health topics</li>
          <li>Managing your energy.</li>
          <li>Career options.</li>
          <li>Any uploaded articles on the LHM zipper zone.</li>
        </ul>

        <p>
          Any medical/heart condition-based questions or queries asked over chat will be logged and presented to the appropriate person and answered at a later time. LHM staff will not be answering
          personalised medical questions. The young person asking the question will be informed of this.
        </p>

        <p>
          <b>Safeguarding</b>
        </p>

        <p>This service is for youth members of LHM specifically so it will not be an anonymous service.</p>
        <p>
          To access the Youth Hub, members will need to log on securely via the link on the website/app installation and create an account that will be approved by LHM. Only registered LHM youth
          members will receive approval and only after this approval will the youth member have access to the Youth Hub.
        </p>

        <p>
          Upon accessing the Hub the youth member will automatically be joined into a 1:2:1 chat with the youth leader and the age appropriate group chat. They will not any point be granted access to
          the group chats for different age subsets.
        </p>

        <p>
          The youth leader/LHM Admin will have the ability to monitor all conversations both in group chats and 1:2:1. All messages will be archived and stored even when deleted for safeguarding
          purposes. All youth members will be able to report to LHM leader/LHM admin if they feel a chat is inappropriate. If upon review a chat is deemed as inappropriate/bullying the youth
          leader/LHM admin will ask for a change of subject and message the young person 1:2:1 to see if they would like to talk about what they are feeling. The safeguarding procedure of this will be
          the same as stated in the 1:2:1 chat governance. If the young person does not comply and continues with a concerning conversation they will be removed from the Youth Hub and the youth leader
          will speak to their parent/guardian.
        </p>

        <p>Examples of topics of conversation that may be a cause for concern include but are not limited to:</p>
        <ul>
          <li>Talk of self-harm/suicide.</li>
          <li>Inappropriate use of, or refusal to take, medication.</li>
          <li>Any activity that can cause you serious harm or death.</li>
          <li>Mental health issues that cause vulnerability and/or put you at risk.</li>
          <li>Sexualised content</li>
          <li>Harassment of any other person</li>
        </ul>

        <p>
          <b>Data Collection &amp; Privacy Policy</b>
        </p>

        <p>
          Youth Members will be able to access the youth hub web-based app through the website via desktop/phone or via an app downloaded from the Apple Store or Play Store. Personal data inputted
          while registering will be stored and analytical data collected.
        </p>

        <p>Little Hearts Matter will not be taking any payments for this service or running any advertisements.</p>

        <p>
          As stated under safeguarding LHM will be storing personal data from all chats that will be archived. This information may be used internally for reporting and safeguarding purposes to fall
          in line with the Little Hearts Matter Safeguarding Policy but not shared with third party organisations.
        </p>

        <p>LHM will be using Google Firebase for hosting, database, authentication and backend services. For more information re: privacy and security with Firebase click the link below</p>
        <p>
          <a href='https://firebase.google.com/support/privacy' target='_blank' rel='noreferrer'>
            https://firebase.google.com/support/privacy
          </a>
        </p>
      </div>
    </div>
  )
}

export default Terms
