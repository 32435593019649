import { db } from './../../firebase'
import { collection, query, where, orderBy, doc, updateDoc } from 'firebase/firestore'
import { useCollection } from 'react-firebase-hooks/firestore'

import { Link } from 'react-router-dom'
import { calculateExactTime } from './../../components/utilities'

import LoadingSpinnerBlue from './../../components/ui/icons/LoadingSpinnerBlue'
import CloseIcon from './../../components/ui/icons/CloseIcon'
import { useEffect } from 'react'

const AdminNotifications = () => {
  const [notifications, loading, error] = useCollection(query(collection(db, 'adminNotifications'), where('dismissed', '==', false), orderBy('timestamp', 'desc')))

  // keep track of errors
  useEffect(() => {
    if (error) {
      console.error(error)
    }
  }, [error])

  const dismissNotification = async notificationId => {
    // set notification as dismissed
    await updateDoc(doc(db, 'adminNotifications', notificationId), {
      dismissed: true,
    })
  }

  return (
    <div className='admin-page-container'>
      {error && (
        <div className='loading-spinner-blue-container'>
          <CloseIcon />
        </div>
      )}
      {loading ? (
        <div className='loading-spinner-blue-container'>
          <LoadingSpinnerBlue />
        </div>
      ) : notifications && notifications.docs.length > 0 ? (
        <div className='notifications-container'>
          {notifications.docs.map(notification => (
            <div
              className={`notification box-component ${notification.data().type === 'Profanity' ? 'yellow' : notification.data().type === 'Member too old' ? 'red' : 'green'}`}
              key={notification.id}
            >
              <h4>
                {notification.data().type}
                <span className='notification-timestamp'>{calculateExactTime(notification.data()?.timestamp?.seconds)}</span>
              </h4>

              <p className='notification-message'>
                <i>{`${notification.data().byUser} (${notification.data().byUserAge} years old): `}</i>
                {notification.data().message}
              </p>
              <div className='actions-container'>
                {notification.data().chatId && (
                  <Link to={`/chats/${notification.data().chatId}`} state={{ fromAdmin: true }}>
                    View chat
                  </Link>
                )}
                {notification.data().userId && (
                  <Link to={`/admin/manage-users/${notification.data().userId}`} state={{ fromAdmin: true }}>
                    Manage user
                  </Link>
                )}
                <p onClick={() => dismissNotification(notification.id)}>Dismiss</p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>No new notifications</p>
      )}
    </div>
  )
}

export default AdminNotifications
