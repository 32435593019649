const HeadingBar = ({ iconLeft, iconLeftClick, heading, smallHeading, iconRight, iconRightClick, colour }) => {
  return (
    <div className='heading-bar'>
      <div className='heading-bar-sides'>
        {iconLeft && (
          <button className='heading-bar-button svg-button' onClick={iconLeftClick}>
            {iconLeft}
          </button>
        )}
      </div>
      {heading && <h1 className='page-heading'>{heading}</h1>}
      {smallHeading && <>{smallHeading}</>}

      <div className='heading-bar-sides'>
        {iconRight && (
          <button className='heading-bar-button svg-button' onClick={iconRightClick}>
            {iconRight}
          </button>
        )}
      </div>
      <div className='heading-bar-background-1'></div>
      <div className={`heading-bar-background-2 ${colour && `${colour}`}`}></div>
    </div>
  )
}

export default HeadingBar
