import { db } from './../../firebase'
import { collection, doc, addDoc, getDoc, getDocs, updateDoc, arrayUnion, query, orderBy, where, limit, startAfter, serverTimestamp } from 'firebase/firestore'
import { useCollection } from 'react-firebase-hooks/firestore'

import { getAge, secondsSinceBirthday, capitaliseFirstLetter } from './../../components/utilities'

import { useAuth } from './../../components/auth/AuthContext'
import { useEffect, useState, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { FirebaseMessaging } from '@capacitor-firebase/messaging'
import { Capacitor } from '@capacitor/core'

import ChatMessage from './ChatMessage'
import ProfilePic from './../../components/ui/ProfilePic'
import HeadingBar from '../../components/ui/HeadingBar'
import OptionsPopup from './../../components/ui/OptionsPopup'
import NoticeOverlay from './../../components/ui/NoticeOverlay'
import ConfirmationPopup from './../../components/ui/ConfirmationPopup'

import CloseIcon from './../../components/ui/icons/CloseIcon'
import LeftArrow from '../../components/ui/icons/LeftArrow'
import LoadingSpinnerBlue from './../../components/ui/icons/LoadingSpinnerBlue'
import EditIcon from './../../components/ui/icons/EditIcon'
import EditFinish from './../../components/ui/icons/EditFinish'

import { shouldShowTimestamp, millisToMinutesAndSeconds } from './../../components/utilities'

const ChatWindow = ({ id, chatData, height, toggleIsTimeout }) => {
  // navigate back
  const navigate = useNavigate()
  let location = useLocation()

  // get user
  const { user, role, userData, refreshUserData, age } = useAuth()

  // all messages state
  const [allMessages, setAllMessages] = useState()

  // work out if it's a community or a single/group chat
  const collectionRef = ['lhmKidz', 'juniors', 'seniors'].includes(id) ? 'communityChats' : 'chats'

  // see how long the current user has been a member
  let memberSince = chatData?.specialAdminView
    ? { seconds: 1652455351 }
    : ['lhmKidz', 'juniors', 'seniors'].includes(id)
    ? userData?.communities[id]?.memberSince || { seconds: Number(new Date() / 1000) }
    : chatData?.members?.[user.uid]?.memberSince || { seconds: Number(new Date() / 1000) }

  // admins have been a member since account creation
  if (role === 'admin') {
    memberSince = userData.createdOn
  }

  // listen for messages
  const chatMessagesRef = collection(db, collectionRef, id, 'messages')
  const [messages, loading, error] = useCollection(query(chatMessagesRef, where('sentOn', '>=', memberSince), orderBy('sentOn', 'desc'), limit(25)))
  const [processLoading, setProcessLoading] = useState(true)

  // scroll on new message
  const scroller = useRef()
  const chatWindowDiv = useRef()

  // lazy loading
  const lazyLoadTrigger = useRef()
  const [additionalChats, setAdditionalChats] = useState([])
  const [lazyLoading, setLazyLoading] = useState(false)
  const [moreToLoad, setMoreToLoad] = useState(true)
  const [hasScrolledFirstTime, setHasScrolledFirstTime] = useState(false)

  const handleLazyLoad = async () => {
    if (!lazyLoading) {
      setLazyLoading(true)
      let lastMessage = additionalChats.length > 0 ? additionalChats[additionalChats.length - 1] : messages?.docs[messages.docs.length - 1]
      let moreMessages = await getDocs(query(chatMessagesRef, where('sentOn', '>=', memberSince), orderBy('sentOn', 'desc'), startAfter(lastMessage), limit(25)))
      if (moreMessages.docs.length === 0) {
        setMoreToLoad(false)
      }
      setAdditionalChats(current => [...current, ...moreMessages.docs])
    }
  }

  // lazy loading
  useEffect(() => {
    if (!chatData || error) {
      return
    }

    if (moreToLoad && messages && !loading && hasScrolledFirstTime) {
      let lazyLoadTriggerElem = lazyLoadTrigger.current
      // observer function
      const fetchMoreChats = (entries, observer) => {
        if (entries[0].isIntersecting) {
          handleLazyLoad()
        }
      }

      // setup observer
      let observer = new IntersectionObserver(fetchMoreChats, {
        rootMargin: '5px',
        threshold: 1,
      })

      // setup lazy loading once we have the first batch of messages
      if (lazyLoadTriggerElem) observer.observe(lazyLoadTriggerElem)

      return () => {
        if (lazyLoadTriggerElem) observer.unobserve(lazyLoadTriggerElem)
      }
    }
  })

  // needed for community only: get users so you can match them to profiles
  const chatMembers = useRef({
    [user.uid]: {
      uid: user.uid,
      name: `${userData.firstName} ${userData.lastName}`,
      profilePic: userData.profilePic || null,
      colour: userData.colour,
    },
  })

  const fetchUser = async uid => {
    const chatUser = doc(db, 'users', uid)
    const userDoc = await getDoc(chatUser)
    if (userDoc.exists()) {
      let userData = userDoc.data()
      return {
        uid: userDoc.id,
        name: `${userData.firstName} ${userData.lastName}`,
        profilePic: userData?.profilePic || null,
        colour: userData.colour,
      }
    }
  }

  // handle new messages, and merge any lazy loaded ones too
  useEffect(() => {
    if (error) {
      navigate('/chats')
    }

    // set read on top array
    const setRead = async () => {
      if (chatData && !chatData?.specialAdminView) {
        const chatDoc = doc(db, collectionRef, chatData.id)
        await updateDoc(chatDoc, {
          'lastMessage.readBy': arrayUnion(user.uid),
        })

        const deviceType = Capacitor.getPlatform()

        // handle removing notifications that have been read from this chat
        if (deviceType === 'android' || deviceType === 'ios') {
          const result = await FirebaseMessaging.removeAllDeliveredNotifications()
        }
      }
    }
    if (!error && messages?.docs && messages?.docs?.length > 0 && chatData) {
      // set read on chat in database
      setRead()

      // merge user data with messages
      const mergeMessageWithMembers = async () => {
        if (messages) {
          let combinedMessages = [...messages.docs]
          if (additionalChats.length > 0) {
            combinedMessages = [...messages.docs, ...additionalChats]
          }

          const processMessages = combinedMessages.map(message => {
            let fromUid = message.data().from
            // return message as is if there's no from field
            if (!fromUid) {
              return { ...message.data(), id: message.id }
            }
            if (chatData?.members?.[fromUid]) {
              // check if the members can be found in the chat document
              chatMembers.current = { ...chatMembers.current, ...chatData.members.fromUid }
              return { ...message.data(), id: message.id, from: { uid: fromUid, ...chatData.members[fromUid] } }
            } else {
              return { ...message.data(), id: message.id, from: { toFetch: true, uid: fromUid } }
            }
          })

          for (const index in processMessages) {
            if (processMessages[index]?.from?.toFetch) {
              let fromUid = processMessages[index].from.uid
              // see if we've fetched previously
              if (chatMembers.current[fromUid]) {
                processMessages[index].from = chatMembers.current[fromUid]
              } else {
                let memberData = await fetchUser(fromUid)
                processMessages[index].from = memberData
                // add to the dictionary
                chatMembers.current = { ...chatMembers.current, [fromUid]: memberData }
              }
            }
          }

          // set all messages state
          setAllMessages(processMessages)

          setProcessLoading(false)
          setLazyLoading(false)
          if (chatWindowDiv.current && chatWindowDiv?.current?.style) {
            chatWindowDiv.current.style.width = '99.9%'
            setTimeout(() => {
              chatWindowDiv.current.style.width = '100%'
            }, 20)
          }
        }
      }

      mergeMessageWithMembers()
    }

    if (!error && messages?.docs && messages?.docs?.length === 0 && chatData) {
      setProcessLoading(false)
    }
  }, [chatData, messages, additionalChats, loading, error, collectionRef, user.uid])

  // scroll down when new messages arrive
  useEffect(() => {
    const waitAMoment = setTimeout(() => {
      if (scroller.current && !loading) {
        scroller.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }
    }, 200)
    return () => clearTimeout(waitAMoment)
  }, [messages])

  // scroll down on first load
  useEffect(() => {
    const waitAMoment = setTimeout(() => {
      if (scroller.current && !loading) {
        scroller.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }
      setHasScrolledFirstTime(true)
    }, 150)
    return () => clearTimeout(waitAMoment)
  }, [loading, scroller.current])

  // admin editmode
  const [adminEditMode, setAdminEditMode] = useState(false)
  const [optionsPopup, setOptionsPopup] = useState(false)
  const [optionsPopupLoading, setOptionsPopupLoading] = useState(false)

  const showMessageOptions = messageId => {
    // get the message that's been clicked on
    let message = allMessages.find(message => message.id === messageId)

    // don't do anything if the user isn't an admin, or if it's a system message
    if (role !== 'admin' || message.type === 'system') {
      return
    }

    let options = {
      heading: 'Choose an action',
      message: 'Removing messages will hide the message for all users besides admins',
      options: [
        {
          label: message?.removed ? 'Restore message' : 'Remove message',
          colour: 'orange',
          action: async () => {
            // display loading spinner
            setOptionsPopupLoading(true)
            // toggle based on current state of message
            let updateContent = message?.removed ? { removed: false } : { removed: true }
            // update message doc .removed property
            const updateDate = await updateDoc(doc(db, collectionRef, id, 'messages', messageId), updateContent)
            // if matches last message, blat it from the chat doc
            if (chatData.lastMessage.message === message.content) {
              const updateLastMessage = await updateDoc(doc(db, collectionRef, id), {
                'lastMessage.message': '',
              })
            }
            setOptionsPopup(false)
            setOptionsPopupLoading(false)
          },
        },
      ],
    }

    if (chatData.type === 'community' && message.from.uid !== user.uid) {
      options.options.push({
        label: `Timeout ${message.from.name}`,
        colour: 'blue',
        action: () => {
          setOptionsPopup({
            heading: 'How long for?',
            message: `Whilst on timeout, a user can read the chat, but can't send messages`,
            options: [
              {
                label: '1 minute',
                colour: 'green',
                action: () => timeoutUser(60000, message.from.uid, message.from.name),
              },
              {
                label: '5 minutes',
                colour: 'blue',
                action: () => timeoutUser(300000, message.from.uid, message.from.name),
              },
              {
                label: '30 minutes',
                colour: 'orange',
                action: () => timeoutUser(1800000, message.from.uid, message.from.name),
              },
              {
                label: '1 hour',
                colour: 'red',
                action: () => timeoutUser(3600000, message.from.uid, message.from.name),
              },
            ],
          })
        },
      })
    }

    if (chatData.type === 'group') {
      if (chatData.members[message.from.uid]) {
        options.options.push({
          label: `Remove ${message.from.name} from group`,
          colour: 'red',
          action: () => removeUser(message.from.uid),
        })
      }
    }

    // this triggers the options popup
    setOptionsPopup(options)
  }

  // timeout user, takes time in ms and a user uid
  const timeoutUser = async (time, user, userName) => {
    // time in ms
    if (chatData.type === 'community') {
      // display loading spinner
      setOptionsPopupLoading(true)
      // check for existing timeout on this user
      const existingTimeout = chatData?.timeout?.find(timedOut => timedOut.user === user) || null
      // add timeout to the chat data
      let updateTimeout = {
        timeout: arrayUnion({
          user: user,
          expires: Date.now() + time,
        }),
      }
      if (existingTimeout) {
        let removeExisting = chatData.timeout.filter(timeouts => {
          return timeouts.user !== user
        })
        let newTimeout = {
          user: user,
          expires: Date.now() + time,
        }
        updateTimeout = {
          timeout: [...removeExisting, newTimeout],
        }
      }
      const updateLastMessage = await updateDoc(doc(db, collectionRef, id), updateTimeout)
      if (!existingTimeout) {
        // put a messagein the chat
        let timeoutMessage = `${userName} is on a timeout`
        // send depature message
        await addDoc(collection(db, collectionRef, id, 'messages'), {
          type: 'system',
          from: '',
          sentOn: serverTimestamp(),
          content: timeoutMessage,
        })
      }
      // cleanup popup
      setOptionsPopupLoading(false)
      setOptionsPopup(false)
    }
  }

  // user side timeout handling

  const endTimeout = async () => {
    // time in ms
    if (chatData.type === 'community') {
      // put a messagein the chat
      let timeoutMessage = `Timeout ended for ${userData.firstName} ${userData.lastName}`
      // send depature message
      await addDoc(collection(db, collectionRef, id, 'messages'), {
        type: 'system',
        from: '',
        sentOn: serverTimestamp(),
        content: timeoutMessage,
      })
      // add timeout to the chat data
      let updatedTimeoutArray = chatData.timeout.filter(timeouts => {
        return timeouts.user !== user.uid
      })
      await updateDoc(doc(db, collectionRef, id), {
        timeout: updatedTimeoutArray,
      })
    }
  }

  // setup errors
  const [chatErrors, setChatErrors] = useState([])

  // close notice function, removes from formErrors array
  const closeNotice = e => {
    let key = Number(e.currentTarget.dataset.key)
    let currentErrors = chatErrors.filter((el, index) => {
      if (index !== key) {
        return el
      }
      return null
    })
    setChatErrors(currentErrors)
  }

  // state for confirmation popup
  const [confirm, setConfirm] = useState(false)

  // close confirmation popup
  const closeAction = () => {
    setConfirm(false)
  }

  const leaveCommunity = async () => {
    if (chatData && chatData?.type === 'community' && chatData?.isTooOld) {
      let communityChats = { ...userData.communities }
      delete communityChats[chatData.id]
      // update user's community chat list
      await updateDoc(doc(db, 'users', user.uid), {
        communities: communityChats,
      })
      // send depature message
      let departureMessage = `${userData.firstName} ${userData.lastName} has left the chat`
      await addDoc(collection(db, collectionRef, id, 'messages'), {
        type: 'system',
        from: '',
        sentOn: serverTimestamp(),
        content: departureMessage,
      })
      // send admin notification
      await addDoc(collection(db, 'adminNotifications'), {
        dismissed: false,
        type: 'Community Move',
        chatId: id,
        message: `${userData.firstName} has left the ${capitaliseFirstLetter(id)} community chat voluntarily`,
        byUser: `${userData.firstName} ${userData.lastName}`,
        byUserAge: getAge(age),
        timestamp: serverTimestamp(),
      })
      refreshUserData()
      navigate('/chats')
    }
  }

  // runs when the chatData updates
  useEffect(() => {
    // check if on timeout in community
    let interval = null
    if (chatData && chatData?.type === 'community' && chatData?.timeout?.length > 0) {
      // check if current user is on the timeout list
      const currentUserTimeout = chatData.timeout.find(timedOut => timedOut.user === user.uid)
      if (currentUserTimeout) {
        if (currentUserTimeout?.expires - Date.now() < 0) {
          // update database
          setChatErrors([])
          toggleIsTimeout(false)
          endTimeout()
        } else {
          toggleIsTimeout(true)
          interval = setInterval(() => {
            let timeRemaining = currentUserTimeout.expires - Date.now()
            if (timeRemaining > 0) {
              setChatErrors([
                {
                  colour: 'red',
                  message: `You are on a timeout: ${millisToMinutesAndSeconds(timeRemaining)} left`,
                  noClose: true,
                },
              ])
            } else {
              // update database
              setChatErrors([])
              toggleIsTimeout(false)
              endTimeout()
              clearInterval(interval)
            }
          }, 1000)
        }
      }
    }
    // check for being too old and show a notification
    const checkAge = async () => {
      if (chatData && chatData?.type === 'community' && chatData?.isTooOld) {
        setChatErrors([
          {
            colour: 'blue',
            message: `You're not a ${chatData.name.slice(0, -1)} any more. When you are ready, `,
            action: {
              function: () => {
                setConfirm({
                  heading: `Leave this chat?`,
                  message: `Make sure you've said goodbye!`,
                  actionText: 'Leave',
                  action: () => leaveCommunity(),
                  loading: false,
                })
              },
              text: 'leave this chat',
            },
          },
        ])

        if (secondsSinceBirthday(age) > 604800 && !userData.communities[id]?.tooOld) {
          await updateDoc(doc(db, 'users', user.uid), {
            [`communities.${id}.tooOld`]: true,
          })
          let newReport = {
            byUser: user.uid,
            byUserName: `${userData.firstName} ${userData.lastName}`,
            byUserAge: getAge(age),
            aboutChat: chatData.id,
            chatName: chatData.name,
            chatType: chatData.type,
            message: `${userData.firstName} has not yet voluntarily left ${capitaliseFirstLetter(id)}, but is 1 week older than the maximum for this community.`,
            resolved: false,
            timestamp: serverTimestamp(),
          }
          await addDoc(collection(db, 'adminReports'), newReport)
          return refreshUserData()
        }
      }
    }

    checkAge()

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [chatData])

  // admin remove user from group

  const removeUser = async user => {
    // display loading spinner
    setOptionsPopupLoading(true)
    // create a message announcing departure from group
    let departureMessage = `${chatData.members[user].name} has left the chat`
    // store full user data
    let allMembers = { ...chatData.members }
    let departingUser = allMembers[user]
    // create new members object with current user removed
    delete allMembers[user]
    // send depature message
    await addDoc(collection(db, collectionRef, id, 'messages'), {
      type: 'system',
      from: '',
      sentOn: serverTimestamp(),
      content: departureMessage,
    })
    // remove from chat
    await updateDoc(doc(db, collectionRef, id), {
      members: allMembers,
      [`pastMembers.${user}`]: departingUser,
      lastMessage: {
        message: departureMessage,
        timestamp: serverTimestamp(),
        readBy: [],
      },
    })
    // cleanup popup
    setOptionsPopupLoading(false)
    setOptionsPopup(false)
  }

  const closeOptionsPopup = () => {
    setOptionsPopup(false)
  }

  return (
    <>
      {chatErrors && <NoticeOverlay notices={chatErrors} closeFunction={closeNotice} />}
      {confirm && <ConfirmationPopup heading={confirm.heading} message={confirm.message} actionText={confirm.actionText} action={confirm.action} loading={confirm.loading} closeAction={closeAction} />}
      <HeadingBar
        heading={
          chatData && !chatData?.specialAdminView && !adminEditMode ? (
            <div className='single-chat-header' onClick={() => navigate('info')}>
              <ProfilePic firstName={chatData.name} image={chatData.image} colour={chatData.colour} size={33} />
              {chatData.name}
            </div>
          ) : (
            chatData && chatData?.specialAdminView && ''
          )
        }
        smallHeading={
          chatData && chatData?.specialAdminView && !adminEditMode ? (
            <div className='single-chat-header'>
              <p className='chat-header-p'>You aren't in this chat, but you can read and remove messages.</p>
            </div>
          ) : (
            role === 'admin' &&
            adminEditMode && (
              <div className='single-chat-header' onClick={() => navigate('info')}>
                <p className='chat-header-p'>
                  <strong>Edit mode: </strong>Tap a message to hide it for all users
                </p>
              </div>
            )
          )
        }
        iconLeft={<LeftArrow />}
        iconLeftClick={() => (location.state?.fromAdmin ? navigate(-1) : navigate('/chats'))}
        colour={chatData && chatData.colour}
        iconRight={role === 'admin' && !adminEditMode ? <EditIcon /> : role === 'admin' && adminEditMode && <EditFinish />}
        iconRightClick={() => setAdminEditMode(!adminEditMode)}
      />
      {error || (!chatData && role !== 'admin') ? (
        <div className='loading-spinner-blue-container error-icon' style={{ height: height }}>
          <CloseIcon />
          <h3>Something went wrong :(</h3>
        </div>
      ) : loading || processLoading || (!chatData && role === 'admin') ? (
        <div className='loading-spinner-blue-container'>
          <LoadingSpinnerBlue />
        </div>
      ) : (
        <div className={`chat-window ${chatData.type} ${adminEditMode ? 'edit-mode' : ''}`} ref={chatWindowDiv} style={{ height: height }}>
          <div className='scroller' ref={scroller}></div>
          {allMessages &&
            allMessages.map((message, index) => {
              return (
                <ChatMessage
                  key={message.id}
                  message={message}
                  id={message.id}
                  showName={['group', 'community'].includes(chatData.type) || chatData?.specialAdminView}
                  lastMessageTimestamp={allMessages[index + 1]?.sentOn?.seconds || Number(new Date() / 1000)}
                  lastMessageFromSame={allMessages?.[index + 1]?.from?.uid === message?.from?.uid}
                  nextMessageFromSame={allMessages?.[index - 1]?.from?.uid === message?.from?.uid}
                  nextMessageShowTimestamp={shouldShowTimestamp(allMessages?.[index - 1]?.sentOn?.seconds || Number(new Date() / 1000), message?.sentOn?.seconds || Number(new Date() / 1000))}
                  adminEditMode={adminEditMode}
                  showMessageOptions={showMessageOptions}
                />
              )
            })}
          <div className='messages-lazy-loader' ref={lazyLoadTrigger}>
            {moreToLoad && <LoadingSpinnerBlue />}
          </div>
        </div>
      )}
      {adminEditMode && optionsPopup && (
        <OptionsPopup heading={optionsPopup.heading} message={optionsPopup.message} loading={optionsPopupLoading} options={optionsPopup.options} closeOptionsPopup={closeOptionsPopup} />
      )}
    </>
  )
}

export default ChatWindow
