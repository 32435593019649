import LoadingSpinnerBlue from './icons/LoadingSpinnerBlue'
import CloseIcon from './icons/CloseIcon'

const OptionsPopup = ({ heading, message, loading, options, closeOptionsPopup }) => {
  return (
    <div className='lhm-body confirmation-popup-background'>
      <div className='popup-container box-component red-blue'>
        {loading && (
          <div className='popup-loading-container'>
            <LoadingSpinnerBlue />
          </div>
        )}
        <>
          {!loading && (
            <button onClick={() => closeOptionsPopup()} className='svg-button close-icon'>
              <CloseIcon />
            </button>
          )}
          <div className='container-20px'>
            <h3>{heading}</h3>
            <p>{message}</p>
          </div>
          <div className='popup-options'>
            {options.map((option, index) => (
              <button key={index} onClick={() => option.action()} className={option.colour}>
                {option.label}
              </button>
            ))}
          </div>
        </>
      </div>
    </div>
  )
}

export default OptionsPopup
