const ProfilePic = ({ firstName, image, colour, online, size, clickAction }) => {
  if (colour === 'blue-red' || colour === 'orange-pink') {
    size = size - 4.5
  }
  return (
    <div className={`profile-pic ${colour ? colour : ''} ${online ? 'online' : ''}`} style={{ width: size, height: size }} onClick={clickAction ? () => clickAction() : undefined}>
      {image ? <img src={image} alt={firstName} /> : firstName && firstName[0]}
      <div className='online-dot'></div>
    </div>
  )
}

export default ProfilePic
