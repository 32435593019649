import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { db } from '../../firebase'
import { collection } from 'firebase/firestore'
import { useCollectionOnce } from 'react-firebase-hooks/firestore'

import ChatListItem from '../chats/ChatListItem'
import LoadingSpinnerBlue from './../../components/ui/icons/LoadingSpinnerBlue'
import CloseIcon from '../../components/ui/icons/CloseIcon'

const AllChats = () => {
  // states
  const [openChat, setOpenChat] = useState()
  const [allChats, chatsLoading, chatsError] = useCollectionOnce(collection(db, 'chats'))
  const [allChatsFilter, setAllChatsFilter] = useState([])

  // setup navigation for chat
  const navigate = useNavigate()

  // filter the chats to include id
  useEffect(() => {
    if (allChats?.docs) {
      let filteredChats = allChats.docs.map(chat => {
        // take a copy of the actual database data
        let chatData = chat.data()
        // rename single chats to display useful info to admins
        if (chatData.type === 'single' && Object.keys(chatData.members).length > 0) {
          chatData.lastMessage.message = '1 to 1 chat'
          chatData.colour = ''
          let name1 = chatData.members[Object.keys(chatData.members)[0]].name
          let name2 = chatData.members[Object.keys(chatData.members)[1]].name
          chatData.name = `${name1} & ${name2}`
        }
        // rename group chats to display useful info to admins
        if (chatData.type === 'group' && Object.keys(chatData.members).length > 0) {
          chatData.name = `Group: '${chatData.name}'`
          let members = 'Members: '
          let index = 0
          // add members into a string list
          for (const chatMember in chatData.members) {
            let joiner = index === 0 ? ' ' : ', '
            let addName = chatData.members[chatMember].name
            members = members.concat(joiner, addName)
            index++
          }

          chatData.lastMessage.message = members
          chatData.lastMessage.from = ''
          chatData.image = chatData.profilePic
        }
        // handle single chats where someone was blocked and groups where everyone left
        if (Object.keys(chatData.members).length === 0) {
          chatData.name = chatData.type === 'single' ? 'Inactive 1 to 1 chat' : 'Empty group'
          chatData.colour = 'red'
          if (chatData?.pastMembers) {
            let members = 'Past members: '
            let index = 0
            // add members into a string list
            for (const chatMember in chatData.pastMembers) {
              let joiner = index === 0 ? ' ' : ', '
              let addName = chatData.pastMembers[chatMember].name
              members = members.concat(joiner, addName)
              index++
            }
            chatData.lastMessage.message = members
            chatData.lastMessage.from = ''
          }
        }
        // merge id into object
        chatData = { ...chatData, id: chat.id }
        // fix timestamp just like ChatContext does (only uses seconds)
        let timestampSolver = chatData?.lastMessage?.timestamp?.seconds || Math.floor(Number(new Date() / 1000))
        chatData.lastMessage.timestamp = timestampSolver
        return chatData
      })
      filteredChats.sort((a, b) => {
        const timetampA = a?.lastMessage?.timestamp
        const timetampB = b?.lastMessage?.timestamp
        return timetampB - timetampA
      })
      setAllChatsFilter(filteredChats)
    }
  }, [allChats])

  return (
    <div className='admin-page-container'>
      {chatsLoading && (
        <div className='loading-spinner-blue-container'>
          <LoadingSpinnerBlue />
        </div>
      )}
      {chatsError && (
        <div className='loading-spinner-blue-container'>
          <CloseIcon />
        </div>
      )}
      {allChatsFilter.length > 0 && (
        <div className='chats-list'>
          {allChatsFilter.map(chat => (
            <ChatListItem
              key={chat.id}
              chat={chat}
              current={openChat === chat.id ? true : false}
              clickHandler={() => {
                setOpenChat(chat.id)
                navigate(`./../../chats/${chat.id}`, { state: { fromAdmin: true } })
              }}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default AllChats
