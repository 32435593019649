import { useState, useEffect } from 'react'

import { db } from './../../firebase'
import { collection, query, where, addDoc, serverTimestamp } from 'firebase/firestore'
import { useCollection } from 'react-firebase-hooks/firestore'
import { Link } from 'react-router-dom'

import Button from './../../components/ui/Button'
import { useAuth } from './../../components/auth/AuthContext'

import LoadingSpinner from './../../components/ui/icons/LoadingSpinner'
import RightArrow from './../../components/ui/icons/RightArrow'
import LoadingSpinnerBlue from './../../components/ui/icons/LoadingSpinnerBlue'
import CloseIcon from './../../components/ui/icons/CloseIcon'
import NoticeOverlay from './../../components/ui/NoticeOverlay'

const Admin = () => {
  // use auth
  const { userData } = useAuth()
  // get notifications for counting
  const [notifications, notificationsLoading, noticiationsError] = useCollection(query(collection(db, 'adminNotifications'), where('dismissed', '==', false)))

  // admin notices
  const [adminNotices, setAdminNotices] = useState([])

  // close notice function, removes from adminNotices array
  const closeNotice = e => {
    let key = Number(e.currentTarget.dataset.key)
    let currentErrors = adminNotices.filter((el, index) => {
      if (index !== key) {
        return el
      }
      return null
    })
    setAdminNotices(currentErrors)
  }

  // get reports for counting
  const [reports, reportsLoading, reportsError] = useCollection(query(collection(db, 'adminReports'), where('resolved', '==', false)))

  // get unverified users for counting
  const [unverifiedUsers, usersLoading, usersError] = useCollection(query(collection(db, 'users'), where('verified', '==', false)))

  // keep track of errors
  useEffect(() => {
    if (noticiationsError) {
      console.error(noticiationsError)
    }
    if (usersError) {
      console.error(usersError)
    }
  }, [noticiationsError, usersError])

  // broadcasts

  const [loading, setLoading] = useState(false)
  const [broadcastMessage, setBroadcastMessage] = useState('')

  const saveBroadcast = async () => {
    if (!broadcastMessage) {
      return
    }
    setLoading(true)
    let newBroadcast = {
      message: broadcastMessage,
      byUserFirstName: userData.firstName,
      byUserLastName: userData.lastName,
      byUserColour: userData.colour,
      byUserProfilePic: userData.profilePic,
      timestamp: serverTimestamp(),
    }

    try {
      await addDoc(collection(db, 'broadcasts'), newBroadcast)
      setLoading(false)
      setAdminNotices([
        {
          colour: 'blue',
          message: `New broadcast has been sent`,
        },
      ])
      setBroadcastMessage('')
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  return (
    <div className='admin-page-container'>
      {adminNotices && <NoticeOverlay notices={adminNotices} belowTopBar={true} closeFunction={closeNotice} />}
      <h3>Latest</h3>
      {noticiationsError ||
        usersError ||
        (reportsError && (
          <div className='loading-spinner-blue-container'>
            <CloseIcon />
          </div>
        ))}
      {notificationsLoading || usersLoading || reportsLoading ? (
        <div className='loading-spinner-blue-container'>
          <LoadingSpinnerBlue />
        </div>
      ) : (
        <div className='box-component blue-red'>
          {notifications && notifications.docs.length > 0 && (
            <div className='admin-quickview'>
              <p>
                <Link to='/admin/notifications'>
                  {notifications.docs.length} current notification{`${notifications.docs.length === 1 ? '' : 's'}`}
                </Link>
              </p>
            </div>
          )}
          {reports && reports.docs.length > 0 && (
            <div className='admin-quickview red'>
              <p>
                <Link to='/admin/reports'>
                  {reports.docs.length} active report{`${reports.docs.length === 1 ? '' : 's'}`}
                </Link>
              </p>
            </div>
          )}
          {unverifiedUsers && unverifiedUsers.docs.length > 0 && (
            <div className='admin-quickview yellow'>
              <p>
                <Link to='/admin/manage-users'>
                  {unverifiedUsers.docs.length} new user{`${unverifiedUsers.docs.length === 1 ? '' : 's'}`}
                </Link>{' '}
                awaiting verification
              </p>
            </div>
          )}
        </div>
      )}
      <h3>New broadcast</h3>
      <div className='broadcast-text-input-container'>
        <textarea
          className='broadcast-text-input'
          autoComplete='off'
          autoCorrect='on'
          spellCheck='true'
          aria-multiline='true'
          placeholder='Broadcast message'
          aria-label='Broadcast message'
          onInput={e => setBroadcastMessage(e.target.value)}
          value={broadcastMessage}
        ></textarea>
      </div>
      <Button action={() => saveBroadcast()} colour={loading ? 'loading blue' : 'blue'} text={'Send broadcast'} fixed={true} icon={loading ? <LoadingSpinner /> : <RightArrow colour={'#fff'} />} />
    </div>
  )
}

export default Admin
