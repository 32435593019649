import CustomLink from '../../components/ui/CustomLink'
import { useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const AdminNavigation = () => {
  const adminNav = useRef()
  const location = useLocation()

  useEffect(() => {
    Array.from(adminNav?.current?.children).forEach(link => {
      if (link.classList.contains('active')) {
        link.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'center' })
      }
    })
  }, [location])
  return (
    <>
      <div className='glow-top heading-bar-background-2'></div>
      <div className='admin-nav' ref={adminNav}>
        <CustomLink to='admin'>Overview</CustomLink>
        <CustomLink to='admin/events'>Events</CustomLink>
        <CustomLink to='admin/manage-users'>Manage Users</CustomLink>
        <CustomLink to='admin/notifications'>Notifications</CustomLink>
        <CustomLink to='admin/reports'>Reports</CustomLink>
        <CustomLink to='admin/all-chats'>All Chats</CustomLink>
      </div>
    </>
  )
}

export default AdminNavigation
