import NavigationLink from './NavigationLink'
import { useAuth } from '../auth/AuthContext'
import logo from './../../images/logo-square-icon.png'
import { useNavigate } from 'react-router-dom'

import { useEffect } from 'react'

const Navigation = () => {
  const { role, toast, setToastMessage } = useAuth()
  // use navigate
  const navigate = useNavigate()
  // go to chat
  const navigateToast = chatId => {
    setToastMessage(null)
    if (chatId === 'home') return navigate(`./`)
    navigate(`./chats`)
    setTimeout(() => navigate(`./chats/${chatId}`), 10)
  }

  return (
    <>
      {toast && (
        <div className='toast' onClick={() => navigateToast(toast.link)}>
          <p>
            <strong>{toast.title}</strong>
          </p>
          <p>{toast.message}</p>
        </div>
      )}
      <nav>
        <h2 className='desktop-app-title'>
          <div className='logo-container'>
            <div className='glow-element-logo'>
              <img src={logo} alt='Little Hearts Matter' />
              <img src={logo} alt='Little Hearts Matter' />
            </div>
            <h1>Little Hearts Matter Youth Hub</h1>
          </div>
        </h2>
        <NavigationLink to='/' iconType='home' text='Home' />
        <NavigationLink to='/chats' iconType='chat' text='Chats' />
        <NavigationLink to='/profile' iconType='profile' text='Profile' />
        <NavigationLink to='/support' iconType='support' text='Support' />
        {role === 'admin' && <NavigationLink to='/admin' iconType='admin' text='Admin' />}
      </nav>
    </>
  )
}

export default Navigation
