import { useLocation, Outlet, Navigate } from 'react-router-dom'
import { useAuth } from './AuthContext'
import Loading from '../ui/Loading'

const RequireAdmin = () => {
  const { user, role, authLoading } = useAuth()
  let location = useLocation()

  return authLoading ? (
    <Loading />
  ) : user && role ? (
    role === 'admin' ? (
      <Outlet />
    ) : (
      <Navigate to='/' replace />
    )
  ) : (
    <Navigate to='/start' state={{ from: location }} replace />
  )
}

export default RequireAdmin
