import LoadingSpinnerBlue from './icons/LoadingSpinnerBlue'

const ConfirmationPopup = ({ heading, message, actionText, action, closeAction, loading }) => {
  return (
    <div className='lhm-body confirmation-popup-background'>
      <div className='popup-container box-component red-blue'>
        {loading && (
          <div className='popup-loading-container'>
            <LoadingSpinnerBlue />
          </div>
        )}
        <>
          <div className='container-20px'>
            <h3>{heading}</h3>
            <p>{message}</p>
          </div>
          <div className='popup-actions'>
            <button onClick={() => closeAction()}>Cancel</button>
            <button onClick={() => action()}>{actionText}</button>
          </div>
        </>
      </div>
    </div>
  )
}

export default ConfirmationPopup
