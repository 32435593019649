const ChatIcon = ({ active, colour }) => (
  <svg width={30} height={29} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M19.096 18.759a.423.423 0 0 0-.424.411c0 .933-.06 1.792-.109 2.495-.028.406-.076.81-.145 1.21-.332.06-.668.089-1.005.085-1.55.049-4.093-.12-5.304-.157h-.775a.678.678 0 0 0-.436.206c-.339.375-.702 1.21-1.332 2.228-.269.437-.586.844-.944 1.21a4.214 4.214 0 0 1-1.998.994c.318-.632.535-1.31.642-2.01a3.947 3.947 0 0 0-.267-2.07 1.211 1.211 0 0 0-.666-.619 4.19 4.19 0 0 0-1.21-.169H1.78a2.43 2.43 0 0 1-.315-.085 3.742 3.742 0 0 0 0-.642c-.254-2.312-.4-7.313-.254-9.481a2.264 2.264 0 0 1 .121-.775 9.174 9.174 0 0 1 2.095-.278 74.043 74.043 0 0 1 3.839-.158.363.363 0 1 0 0-.726c-1.575 0-3.27 0-4.541.06a5.148 5.148 0 0 0-2.18.412c-.163.218-.26.479-.279.75A41.813 41.813 0 0 0 0 15.961v7.072c.02.16.092.31.206.424.424.301.933.459 1.453.448 1.271.085 3.972.073 3.984.121.008.097.008.194 0 .29.09.637.04 1.286-.145 1.902a4.202 4.202 0 0 1-.654 1.635.642.642 0 0 0-.146.557.654.654 0 0 0 .557.569c.193.028.389.028.582 0a6.805 6.805 0 0 0 3.766-1.647 8.675 8.675 0 0 0 1.077-1.429c.473-.787.751-1.514 1.017-1.938.093-.006.186-.006.279 0 1.138 0 3.633.073 5.316 0 .488.012.976-.037 1.453-.145a.678.678 0 0 0 .339-.194c.198-.32.307-.688.315-1.065.085-.787.121-2.022.121-3.403a.424.424 0 0 0-.424-.4Z'
      fill={colour ? colour : active ? 'var(--red)' : 'var(--grey)'}
    />
    <path
      d='M29.062 8.127a8.706 8.706 0 0 0-3.33-5.97 10.317 10.317 0 0 0-6.49-2.155c-2.19.006-4.316.73-6.055 2.058a8.126 8.126 0 0 0-3.403 5.45 7.423 7.423 0 0 0 1.114 4.6 6.684 6.684 0 0 0 3.9 2.677 8.37 8.37 0 0 0 1.392.218c.763.072 1.49.097 1.853.097.266.35.726 1.114 1.21 1.731.226.284.49.532.788.739.99.551 2.09.878 3.22.957h.425a.531.531 0 0 0 .375-.255.46.46 0 0 0-.06-.581 2.797 2.797 0 0 1-.582-1.15 2.955 2.955 0 0 1-.085-1.15c0-.146.17-.28.122-.352a10.231 10.231 0 0 0 4.395-2.095c.896-.969 1.332-2.361 1.211-4.82Zm-2.12 3.984a9.433 9.433 0 0 1-3.814 1.913 1.877 1.877 0 0 0-.774 1.937c.054.524.193 1.035.411 1.514a5.606 5.606 0 0 1-2.18-.654 3.898 3.898 0 0 1-.823-.8c-.496-.617-.932-1.319-1.21-1.561a.484.484 0 0 0-.316-.121s-.956 0-1.998-.133a10.495 10.495 0 0 1-1.283-.243 5.789 5.789 0 0 1-3.294-2.421 6.406 6.406 0 0 1-.763-3.972 6.986 6.986 0 0 1 3.088-4.432 8.78 8.78 0 0 1 5.268-1.695 8.876 8.876 0 0 1 5.594 1.816 7.363 7.363 0 0 1 2.894 4.965c.11 1.961-.073 3.112-.8 3.887Z'
      fill={colour ? colour : active ? 'var(--blue)' : 'var(--grey)'}
    />
  </svg>
)

export default ChatIcon
