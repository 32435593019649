import { useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { db } from './../../firebase'
import { query, collection, orderBy, where, deleteDoc, doc } from 'firebase/firestore'
import { useCollection } from 'react-firebase-hooks/firestore'

import EventTile from './../../components/ui/EventTile'
import Button from './../../components/ui/Button'
import CalendarIcon from './../../components/ui/icons/CalendarIcon'
import LoadingSpinnerBlue from './../../components/ui/icons/LoadingSpinnerBlue'
import CloseIcon from './../../components/ui/icons/CloseIcon'
import ConfirmationPopup from './../../components/ui/ConfirmationPopup'

const AdminEvents = () => {
  // use navigate
  const navigate = useNavigate()
  // memoize current date time
  const currentDate = useMemo(() => {
    return new Date()
  }, [])
  // get events
  const [events, loading, error] = useCollection(query(collection(db, 'events'), where('dateTime', '>=', currentDate), orderBy('dateTime', 'asc')))

  // open edit event page
  const editEvent = id => {
    navigate(`create/${id}`)
  }

  // state for confirmation popup
  const [confirm, setConfirm] = useState(false)

  // close confirmation popup
  const closeAction = () => {
    setConfirm(false)
  }

  const deleteEventConfirm = id => {
    setConfirm({
      heading: `Delete this event?`,
      message: `This will remove it for users and cannot be undone.`,
      actionText: 'Delete event',
      action: () => deleteEvent(id),
      loading: false,
    })
  }

  // delete event from database
  const deleteEvent = async id => {
    try {
      await deleteDoc(doc(db, 'events', id))
      setConfirm(false)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className='admin-page-container'>
      {error && (
        <div className='loading-spinner-blue-container'>
          <CloseIcon />
        </div>
      )}
      {loading ? (
        <div className='loading-spinner-blue-container'>
          <LoadingSpinnerBlue />
        </div>
      ) : (
        events && (
          <>
            <h1>Upcoming events</h1>
            <h3>LHM Kidz</h3>
            <div className='event-tile-container'>
              <div className='event-tile-spacer'></div>
              {events.docs.map(
                event =>
                  event.data().community === 'lhmKidz' && <EventTile event={event.data()} key={event.id} clickEvent={() => editEvent(event.id)} deleteEvent={() => deleteEventConfirm(event.id)} />
              )}
              <div className='event-tile-spacer'></div>
            </div>
            <h3>Juniors</h3>
            <div className='event-tile-container'>
              <div className='event-tile-spacer'></div>
              {events.docs.map(
                event =>
                  event.data().community === 'juniors' && <EventTile event={event.data()} key={event.id} clickEvent={() => editEvent(event.id)} deleteEvent={() => deleteEventConfirm(event.id)} />
              )}
              <div className='event-tile-spacer'></div>
            </div>
            <h3>Seniors</h3>
            <div className='event-tile-container last-tile-container'>
              <div className='event-tile-spacer'></div>
              {events.docs.map(
                event =>
                  event.data().community === 'seniors' && <EventTile event={event.data()} key={event.id} clickEvent={() => editEvent(event.id)} deleteEvent={() => deleteEventConfirm(event.id)} />
              )}
              <div className='event-tile-spacer'></div>
            </div>
            <Button action={() => navigate('create')} colour={'blue'} text={'Add new event'} fixed={true} icon={<CalendarIcon colour={'#fff'} />} />
          </>
        )
      )}
      {confirm && <ConfirmationPopup heading={confirm.heading} message={confirm.message} actionText={confirm.actionText} action={confirm.action} loading={confirm.loading} closeAction={closeAction} />}
    </div>
  )
}

export default AdminEvents
