const SupportIcon = ({ active }) => (
  <svg width={31} height={28} viewBox='0 0 31 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.716 20.547a1.047 1.047 0 0 0-1.686.578c-.542 1.231 1.501 2.007 2.018 1.022.394-.775.345-1.268-.332-1.6Z'
      fill={active ? 'var(--blue)' : 'var(--grey)'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M26.78 4.917C22.312-.78 15.654-.375 12.11.536a14.203 14.203 0 0 0-5.108 2.461A19.199 19.199 0 0 0 3.95 5.84a14.153 14.153 0 0 0-2.24 3.618 12.492 12.492 0 0 0 3.692 15.04 15.998 15.998 0 0 0 15.667 2.461 14.769 14.769 0 0 0 6.24-4.824 13.218 13.218 0 0 0 2.806-7.385 16.27 16.27 0 0 0-3.335-9.833Zm-.566 16.344a13.439 13.439 0 0 1-5.662 4.27 14.621 14.621 0 0 1-14.177-2.215 11.224 11.224 0 0 1-3.52-13.402 12.689 12.689 0 0 1 1.969-3.31 18.19 18.19 0 0 1 2.818-2.807c1.397-1.098 3-1.906 4.714-2.375 2.055-.578 9.033-1.87 13.538 4.16a14.94 14.94 0 0 1 3.015 9.132 12.036 12.036 0 0 1-2.695 6.547Z'
      fill={active ? 'var(--red)' : 'var(--grey)'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.233 5.803a4.307 4.307 0 0 0-3.976.886 3.902 3.902 0 0 0-1.095 1.883 5.944 5.944 0 0 0 .062 3.016.396.396 0 0 0 .615.239.397.397 0 0 0 .16-.412 5.528 5.528 0 0 1 0-2.153 2.77 2.77 0 0 1 1.01-1.65 3.052 3.052 0 0 1 2.793-.381 3.211 3.211 0 0 1 2.141 1.834 2.08 2.08 0 0 1-.11 2.055c-.763 1.12-2.166 2.006-2.93 3.212a3.421 3.421 0 0 0-.553 1.944c-.008.64.109 1.276.344 1.871a.455.455 0 0 0 .838.07.467.467 0 0 0 .036-.353 4.494 4.494 0 0 1-.197-1.55c.037-.48.208-.941.492-1.33a14.295 14.295 0 0 1 2.142-1.981 5.107 5.107 0 0 0 1.464-1.822 3.495 3.495 0 0 0 0-2.682 4.603 4.603 0 0 0-3.236-2.696Z'
      fill={active ? 'var(--blue)' : 'var(--grey)'}
    />
  </svg>
)

export default SupportIcon
